import { notification } from 'antd';
import { forgotPasswordAPI } from 'apis/auth';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

export const useForgotPassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);

    const onFinish = (values) => {
        setLoading(true);
        const { email } = values;

        const isEmailValidation = (email) => {
            const res =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
                    email
                );
            const valid = !!res;
            return valid;
        };

        if (!isEmailValidation(email)) {
            setLoading(false);
            notification.error({
                message: t('Error'),
                description: t('ForgotPassword.MessageFormatEmail'),
            });
            return;
        }

        forgotPasswordAPI(email).then((message) => {
            setLoading(false);
            switch (message) {
                case 'ERROR':
                    notification.error({
                        message: t('Error'),
                        description: t('ErrorDescription'),
                    });
                    break;
                default:
                    setEmail(email);
                    break;
            }
        });
    };

    return {
        loading,
        email,
        onFinish
    }
}