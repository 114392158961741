import { useEffect, useState } from 'react';

import {
	Select,
	Input,
	Button,
	Col,
	Row,
	Image,
	Space,
	DatePicker,
	ConfigProvider,
	notification,
	Spin,
	Empty,
} from 'antd';
import jaJP from 'antd/es/locale/ja_JP';
import { colors, tags, lights, types, tagModes, forbiddens, rejects } from 'constants/options';
import { useHistory } from 'react-router-dom';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MapModule from 'components/MapModule';
import { isOkStatus } from 'utils/transform';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import GroupSelection from 'components/GroupSelection';
import moment from 'moment';
import { editDetailItemAPI } from 'apis/item';
import './index.css';
import Title from 'components/Title';

const EditItemScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [id, setId] = useState(null);
	const [title, setTitle] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [areaId, setAreaId] = useState(null);
	const [location, setLocation] = useState(null);
	const [type, setType] = useState(null);
	const [flag, setFlag] = useState(null);
	const [color, setColor] = useState(null);
	const [light, setLight] = useState(null);
	const [tag, setTag] = useState(null);
	const [tagMode, setTagMode] = useState(null);
	const [image, setImage] = useState(null);
	const [taggedAt, setTaggedAt] = useState(null);
	const [taggedBy, setTaggedBy] = useState(null);
	const [version, setVersion] = useState(null);
	const [deviceId, setDeviceId] = useState(null);
	const [checkedAt, setCheckedAt] = useState(null);
	const [editedAt, setEditedAt] = useState(null);
	const [editedBy, setEditedBy] = useState(null);
	const [note, setNote] = useState(null);
	const [visible, setVisible] = useState(false);
	const [forbidden, setForbidden] = useState(null);
	const [reject, setReject] = useState(null);
	const [disableForbidden, setDisableForbidden] = useState(false);
	const [disableReject, setDisableReject] = useState(false);
	const [spinning, setSpinning] = useState(false);

	const [item, setItem] = useState(null);

	useEffect(() => {
		const { item } = history.location.state;
		__setData(item);
	}, [history.location.state]);

	const __setData = (item) => {
		setItem(item);
		setId(item.id);
		setTitle(item.title);
		setAreaId(item.areaId);
		setCompanyId(item.companyId);
		setLocation(item.location);
		setFlag(item.flag);
		setType(item.type);
		setColor(item.color);
		setLight(item.light);
		setTag(item.tag);
		setImage(item.image);
		setTaggedBy(item.taggedBy);
		setVersion(item.version);
		setDeviceId(item.deviceId);
		setEditedBy(item.editedBy);
		setNote(item.note);
		setTagMode(item.tagMode);
		setTaggedAt(item.taggedAt ? moment(item.taggedAt, 'YYYY-MM-DD HH:mm:ss') : null);
		setCheckedAt(item.checkedAt ? moment(item.checkedAt, 'YYYY-MM-DD HH:mm:ss') : null);
		setEditedAt(item.editedAt ? moment(item.editedAt, 'YYYY-MM-DD HH:mm:ss') : null);

		switch (item.flag) {
			case 'AUTOMATIC':
				setForbidden(false);
				setReject(false);
				setDisableReject(false);
				setDisableForbidden(false);
				break;
			case 'REJECT':
				setForbidden(false);
				setReject(true);
				setDisableReject(false);
				setDisableForbidden(true);
				break;
			case 'FORBIDDEN':
				setForbidden(true);
				setReject(false);
				setDisableReject(true);
				setDisableForbidden(false);
				break;
			default:
				break;
		}
	};

	const onChangeType = (type) => setType(type);

	const onChangeColor = (color) => {
		switch (color) {
			case 'Color':
				setColor('Color');
				setTag('Rusty');
				break;
			case 'Discolor':
				setColor('Discolor');
				setTag('Unset');
				break;
			default:
				break;
		}
	};

	const onChangeTag = (tag) => {
		switch (tag) {
			case 'Dirty':
				setColor('Color');
				setTag('Dirty');
				break;
			case 'Rusty':
				setColor('Color');
				setTag('Rusty');
				break;
			case 'Graffiti':
				setColor('Color');
				setTag('Graffiti');
				break;
			case 'Unset':
				setColor('Discolor');
				setTag('Unset');
				break;
			default:
				break;
		}
	};

	const onChangeLight = (light) => setLight(light);

	const onChangeTagMode = (tagMode) => setTagMode(tagMode);

	const onChangeTaggedAt = (taggedAt) => setTaggedAt(taggedAt);

	const onChangeTaggedBy = (taggedBy) => setTaggedBy(taggedBy);

	const onChangeVersion = (version) => setVersion(version);

	const onChangeDeviceId = (deviceId) => setDeviceId(deviceId);

	const onChangeEditedBy = (editedBy) => setEditedBy(editedBy);

	const onChangeEditedAt = (editedAt) => setEditedAt(editedAt);

	const onChangeNote = (note) => setNote(note);

	const onChangeCheckedAt = (checkedAt) => setCheckedAt(checkedAt);

	const editDetailItem = () => {
		setSpinning(true);
		const args = {
			id,
			title,
			companyId,
			areaId,
			location,
			type,
			flag,
			color,
			light,
			tag,
			image,
			taggedBy,
			version,
			deviceId,
			editedBy,
			note,
			editedAt,
			tagMode,
			taggedAt,
			checkedAt,
		};

		editDetailItemAPI(args).then(({ message, item }) => {
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'NOT_FOUND_ITEM':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'SAME_LOCATION_OTHER_ITEM':
					notification.error({
						message: t('Error'),
						description: t('EditDetailItem.MessageSameLocation'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('EditDetailItem.MessageSuccess'),
					});
					__setData(item);
					break;
			}

			setSpinning(false);
		});
	};

	const onChangeForbidden = (isTrue) => {
		if (isTrue) {
			setForbidden(isTrue);
			setDisableForbidden(false);
			setDisableReject(true);
			setFlag('FORBIDDEN');
		} else {
			setForbidden(isTrue);
			setDisableReject(false);
			setDisableForbidden(false);
			setFlag('AUTOMATIC');
		}
	};

	const onChangeReject = (isTrue) => {
		if (isTrue) {
			setReject(isTrue);
			setDisableReject(false);
			setDisableForbidden(true);
			setFlag('REJECT');
		} else {
			setReject(isTrue);
			setDisableReject(false);
			setDisableForbidden(false);
			setFlag('AUTOMATIC');
		}
	};

	const getLocation = (location) => {
		location && setLocation(location);
		setVisible(!visible);
	};

	return (
		<>
			<Title level={3} title={t('EditDetailItem.Title')} />
			<Spin spinning={spinning}>
				<Row>
					<Col span={12} offset={0}>
						<div className="title">{t('Id')}:</div>
						<Input
							readOnly={true}
							size="large"
							addonAfter={<EnvironmentOutlined onClick={() => setVisible(!visible)} />}
							value={title}
						/>

						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col span={12}>
								<div className="title">{t('Longitude')}:</div>
								<Input readOnly={true} size="large" value={location && location.coordinates[0]} />
							</Col>
							<Col span={12}>
								<div className="title">{t('Latitude')}:</div>
								<Input value={location && location.coordinates[1]} readOnly={true} size="large" />
							</Col>
						</Row>

						<div className="title">{t('Status')}:</div>
						<Input size="large" value={isOkStatus(color, light) ? t('Normal') : t('No Normal')} readOnly={true} />

						<div className="title">{t('Type')}:</div>
						<Select
							value={type}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							defaultValue="Others"
							onChange={(e) => onChangeType(e)}
						>
							{types.map((type) => (
								<Select.Option value={type.value} key={type.value}>{type.label}</Select.Option>
							))}
						</Select>

						<div className="title">検査禁止フラグ:</div>
						<Select
							value={forbidden}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeForbidden(e)}
							disabled={disableForbidden}
						>
							{forbiddens.map((forbidden) => (
								<Select.Option value={forbidden.value} key={forbidden.value}>{forbidden.label}</Select.Option>
							))}
						</Select>

						<div className="title">認識結果不採用フラグ:</div>
						<Select
							value={reject}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeReject(e)}
							disabled={disableReject}
						>
							{rejects.map((reject) => (
								<Select.Option value={reject.value} key={reject.value}>{reject.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Light')}:</div>
						<Select
							value={light}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeLight(e)}
						>
							{lights.map((light) => (
								<Select.Option value={light.value} key={light.value}>{light.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Color')}:</div>
						<Select
							value={color}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeColor(e)}
						>
							{colors.map((color) => (
								<Select.Option value={color.value} key={color.value}>{color.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('TagMode')}:</div>
						<Select
							value={tagMode}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeTagMode(e)}
						>
							{tagModes.map((mode) => (
								<Select.Option value={mode.value} key={mode.value}>{mode.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Tag')}:</div>
						<Select
							value={tag}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeTag(e)}
						>
							{tags.map((tag) => (
								<Select.Option value={tag.value} key={tag.value}>{tag.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('TaggedBy')}:</div>
						<GroupSelection
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							type={'user'}
							value={taggedBy}
							onChange={(e) => onChangeTaggedBy(e)}
						/>

						<div className="title">{t('TaggedAt')}:</div>
						<ConfigProvider locale={jaJP}>
							<DatePicker showTime size="large" value={taggedAt} onChange={(e) => onChangeTaggedAt(e)} />
						</ConfigProvider>

						<div className="title">{t('AiVersion')}:</div>
						<Input size="large" value={version} onChange={(e) => onChangeVersion(e.target.value)} />

						<div className="title">{t('DeviceId')}:</div>
						<GroupSelection
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							type={'device'}
							value={deviceId}
							onChange={(e) => onChangeDeviceId(e)}
						/>

						<div className="title">{t('CheckedAt')}:</div>
						<ConfigProvider locale={jaJP}>
							<DatePicker showTime size="large" value={checkedAt} onChange={(e) => onChangeCheckedAt(e)} />
						</ConfigProvider>

						<div className="title">{t('EditedBy')}:</div>
						<GroupSelection
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							type={'user'}
							value={editedBy}
							onChange={(e) => onChangeEditedBy(e)}
						/>

						<div className="title">{t('EditedAt')}:</div>
						<ConfigProvider locale={jaJP}>
							<DatePicker showTime size="large" value={editedAt} onChange={(e) => onChangeEditedAt(e)} />
						</ConfigProvider>
					</Col>

					<Col span={11} offset={1}>
						<div className="title">{t('Image')}:</div>
						{image ? (
							<Image src={REACT_APP_API_ENDPOINT + image} style={{ width: '100% !important' }} />
						) : (
							<Empty description={false} />
						)}
					</Col>

					<Col span={24}>
						<div className="title">{t('Note')}:</div>
						<Input.TextArea
							className="ant-input ant-input-lg"
							rows={6}
							value={note}
							onChange={(e) => onChangeNote(e.target.value)}
						/>
					</Col>
				</Row>

				<Row style={{ marginTop: '20px' }} type="flex" justify="center" align="middle">
					<Col span={24}>
						<Row justify="center">
							<Space>
								<Button
									shape="round"
									className="button-optional"
									type="danger"
									size="large"
									onClick={() => history.goBack()}
								>
									{t('EditDetailItem.Cancel')}
								</Button>
								<Button
									shape="round"
									className="button-optional"
									type="primary"
									size="large"
									onClick={() => editDetailItem()}
								>
									{t('EditDetailItem.Edit')}
								</Button>
							</Space>
						</Row>
					</Col>
				</Row>
			</Spin>

			<MapModule
				visible={visible}
				setVisible={setVisible}
				center={location}
				getLocation={getLocation}
				mode={'location'}
				item={item}
			/>
		</>
	);
};

export default EditItemScreen;
