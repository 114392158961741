import { Col, Input, Row, Button, Select, notification, Space, Spin } from 'antd';
import { getAllCompaniesAPI, getCompanyAPI } from 'apis/company';
import { checkValidMacAddressAPI, registerDeviceAPI } from 'apis/device';
import GroupSelection from 'components/GroupSelection';
import Title from 'components/Title';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getItems } from 'utils/localStorage';
import { isAdmin } from 'utils/role';

const RegisterDeviceScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [mac, setMac] = useState();
	const [car, setCar] = useState(null);
	const [company, setCompany] = useState(null);
	const [area, setArea] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [spinning, setSpinning] = useState(false);

	const onChangeMac = (mac) => setMac(mac);

	const onChangeArea = (area) => setArea(area);

	const onChangeCar = (car) => setCar(car);

	const onChangeCompany = (company) => {
		setCompany(company);
	};

	const register = async () => {
		setLoading(true);

		if (!mac) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('EmptyField', { Field: t('RegisterDevice.MacLabel') }),
			});
			return;
		}

		if (!area) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('EmptyField', {
					Field: t('RegisterDevice.ProvinceLabel'),
				}),
			});
			return;
		}

		if (!company) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('EmptyField', {
					Field: t('RegisterDevice.CompanyLabel'),
				}),
			});
			return;
		}

		if (!car) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('EmptyField', { Field: t('RegisterDevice.CarLabel') }),
			});
			return;
		}

		const expression = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
		const regex = new RegExp(expression);
		const isFormatMac = mac.match(regex);
		if (!isFormatMac) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('RegisterDevice.MessageFormatMac'),
			});
			return;
		}

		const isValidMac = await checkValidMacAddressAPI(mac);

		if (isValidMac) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('RegisterDevice.MessageInvalidMac'),
			});
			return;
		}

		const carParse = JSON.parse(car);
		const areaParse = JSON.parse(area);
		const companyParse = JSON.parse(company);

		const args = {
			mac,
			companyId: companyParse.id,
			areaId: areaParse.id,
			carId: carParse.id,
			car: carParse.key,
			company: companyParse.key,
			province: areaParse.province,
		};

		registerDeviceAPI(args).then(({ message, device }) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'MAC_ADDRESS_EXISTED':
					notification.error({
						message: t('Error'),
						description: t('RegisterDevice.MessageInvalidMac'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('RegisterDevice.MessageSuccess'),
					});
					history.push({
						pathname: 'register-device-confirm',
						state: {
							device: device,
						},
					});
					break;
			}
		});
	};

	useEffect(() => {
		const { session } = getItems(['session']);
		setSpinning(true);
		getCompanyAPI(session.userInfo.companyId).then((company) => {
			if (company) {
				const { id, key, title } = company;
				setCompany(
					JSON.stringify({
						id,
						key,
						title,
					})
				);
			}
			getAllCompaniesAPI().then((companies) => {
				setCompanies(companies);
				setSpinning(false);
			});
		});
	}, []);

	return (
		<>
			<Title level={3} title={t('RegisterDevice.Title')} />
			<Spin spinning={spinning}>
				<Row type="flex" justify="center" align="middle">
					<Col sm={{ span: 10, offset: 0 }} lg={{ span: 9, offset: 0 }}>
						<div className="title --required">{t('RegisterDevice.MacLabel')}</div>
						<Input
							value={mac}
							size={'large'}
							onChange={(e) => onChangeMac(e.target.value)}
							placeholder={'3D:F2:C9:A6:B3:4F'}
							required
						/>

						<div className="title --required">{t('RegisterDevice.CompanyLabel')}</div>
						<Select
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							disabled={isAdmin()}
							value={company}
							size={'large'}
							onChange={(e) => onChangeCompany(e)}
						>
							{companies.map((company) => {
								const { id, key, title } = company;
								const value = JSON.stringify({
									id,
									key,
									title,
								});

								return <Select.Option value={value} key={value}>{company.title}</Select.Option>;
							})}
						</Select>

						<div className="title --required">{t('RegisterDevice.ProvinceLabel')}</div>
						<GroupSelection
							style={{ width: '100%' }}
							type={'area'}
							size={'large'}
							mode={'province-county-id'}
							value={area}
							onChange={(e) => onChangeArea(e)}
							required
						/>

						<div className="title --required">{t('RegisterDevice.CarLabel')}</div>
						<GroupSelection
							style={{ width: '100%' }}
							type={'car'}
							size={'large'}
							value={car}
							onChange={(e) => onChangeCar(e)}
							required
						/>

						<Space style={{ float: 'right', marginTop: '5px' }}>
							<Button shape="round" className="button-optional" type="danger" onClick={() => history.goBack()}>
								{t('RegisterDevice.Cancel')}
							</Button>
							<Button
								loading={loading}
								shape="round"
								className="button-optional"
								type="primary"
								onClick={() => register()}
							>
								{t('RegisterDevice.Register')}
							</Button>
						</Space>
					</Col>
				</Row>
			</Spin>
		</>
	);
};

export default RegisterDeviceScreen;
