import { Row, Col, Typography } from 'antd';

const Title = ({ title, level, justify = 'center' }) => {
	return (
		<Row type="flex" justify={justify} align="middle">
			<Row justify="start">
				<Col span={24}>
					<Typography.Title>
						<Typography.Title level={level}>{title}</Typography.Title>
					</Typography.Title>
				</Col>
			</Row>
		</Row>
	);
};

export default Title;
