import api from './api';

export const getAllCarsAPI = () => {
	return api
		.get('car/all')
		.then((response) => {
			return response.data.cars;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};

export const getCarsManagementAPI = (offset, limit, keyword) => {
	const params = {
		offset,
		limit,
		keyword,
	};
	return api
		.get('car/manage', { params })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};

export const checkValidCarAPI = (title, key) => {
	const params = {
		title,
		key,
	};
	return api
		.get('car/existed', { params })
		.then((response) => {
			return response.data.isExisted;
		})
		.catch((response) => {
			console.log(response);
			return false;
		});
};


export const removeCarAPI = (id) => {
	const data = {
		id,
	};
	return api
		.delete('car', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const editCarAPI = (id, title, key) => {
	const data = {
		id,
		title,
		key,
	};
	return api
		.put('car', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const createCarAPI = (title, key) => {
	const data = {
		title,
		key,
	};
	return api
		.post('car', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};