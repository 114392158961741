import api from './api';

export const getAllAreasAPI = () => {
	return api
		.get('area/all')
		.then((response) => {
			return response.data.areas;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};
