import { getItems } from './localStorage';

export const getRole = () => {
	const session = JSON.parse(localStorage.getItem('session'));
	let role = '';
	if (session && session.userInfo && session.userInfo.role) {
		if (session.userInfo.role === 'Admin') {
			role = 'admin';
		} else role = 'operator';
	}
	return role;
};

export const isAdmin = () => {
	const { session } = getItems(['session']);
	return !(session.userInfo.role === 'Admin');
};

export const isArrayWithLength = (arr) => {
	return Array.isArray(arr) && arr.length;
};

export const getAllowedRoutes = (routes) => {
	const roles = JSON.parse(localStorage.getItem('session')).userInfo.role;
	return routes.filter(({ permission }) => {
		if (!permission) return true;
		else if (!isArrayWithLength(permission)) return true;
		else if (permission.includes(roles)) return true;
		return false;
	});
};
