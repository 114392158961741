/* eslint-disable no-constant-condition */
import { useEffect, useState } from 'react';

import { Select, Input, Button, Col, Row, Image, Space, notification, Spin, Empty } from 'antd';

import Title from 'components/Title';
import { colors, tags, lights, types, tagModes, forbiddens, rejects } from 'constants/options';
import { useHistory } from 'react-router-dom';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import GroupSelection from 'components/GroupSelection';
import moment from 'moment';
import 'moment/locale/ja';
import MapModule from 'components/MapModule';
import { editItemAPI } from 'apis/item';
import { getItems } from 'utils/localStorage';

const EditItem = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [oldId, setOldId] = useState(null);
	const [id, setId] = useState(null);
	const [title, setTitle] = useState(null);
	const [areaId, setAreaId] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [flag, setFlag] = useState(null);
	const [tagMode, setTagMode] = useState(null);
	const [tag, setTag] = useState(null);
	const [taggedAt, setTaggedAt] = useState(null);
	const [taggedBy, setTaggedBy] = useState(null);
	const [type, setType] = useState(null);
	const [location, setLocation] = useState(null);
	const [color, setColor] = useState(null);
	const [light, setLight] = useState(null);
	const [image, setImage] = useState(null);
	const [version, setVersion] = useState(null);
	const [note, setNote] = useState(null);
	const [deviceId, setDeviceId] = useState(null);
	const [checkedAt, setCheckedAt] = useState(null);
	const [editedAt, setEditedAt] = useState(null);
	const [editedBy, setEditedBy] = useState(null);

	const [visible, setVisible] = useState(false);
	const [forbidden, setForbidden] = useState(null);
	const [reject, setReject] = useState(null);
	const [disableForbidden, setDisableForbidden] = useState(false);
	const [disableReject, setDisableReject] = useState(false);

	const [aiType, setAiType] = useState(null);
	const [aiColor, setAiColor] = useState(null);
	const [aiLight, setAiLight] = useState(null);
	const [aiImage, setAiImage] = useState(null);
	const [aiVersion, setAiVersion] = useState(null);
	const [aiCheckedAt, setAiCheckedAt] = useState(null);
	const [aiDevice, setAiDevice] = useState(null);
	const [aiTag, setAiTag] = useState(null);

	const [item, setItem] = useState(null);

	useEffect(() => {
		const { item } = history.location.state;
		__setData(item);
	}, [history.location.state]);

	const __setData = (item) => {
		setItem(item);

		setOldId(item.id);
		setId(item.id);
		setTitle(item.title);
		setCompanyId(item.companyId);
		setAreaId(item.areaId);
		setType(item.type);
		setLocation(item.location);
		setColor(item.color);
		setLight(item.light);
		setFlag(item.flag);
		setTagMode(item.tagMode);
		setTag(item.tag);
		setTaggedAt(item.taggedAt);
		setTaggedBy(item.taggedBy);
		setImage(item.image);
		setNote(item.note);
		setVersion(item.version);
		setDeviceId(item.deviceId);
		setCheckedAt(item.checkedAt);
		setEditedBy(item.editedBy);
		setEditedAt(item.editedAt);

		switch (item.flag) {
			case 'AUTOMATIC':
				setForbidden(false);
				setReject(false);
				setDisableReject(false);
				setDisableForbidden(false);
				break;
			case 'REJECT':
				setForbidden(false);
				setReject(true);
				setDisableReject(false);
				setDisableForbidden(true);
				break;
			case 'FORBIDDEN':
				setForbidden(true);
				setReject(false);
				setDisableReject(true);
				setDisableForbidden(false);
				break;
			default:
				break;
		}

		setAiType(item.type);
		setAiColor(item.color);
		setAiLight(item.light);
		setAiImage(item.image);
		setAiCheckedAt(item.checkedAt);
		setAiDevice(item.deviceId);
		setAiVersion(item.version);
		setAiTag(item.tag);
	};

	const editItem = async () => {
		setLoading(true);

		const { session } = getItems(['session']);

		const args = {
			isNew: false,
			oldId,
			id,
			title,
			companyId,
			areaId,
			location,
			type,
			color,
			light,
			flag,
			image,
			tagMode,
			tag,
			version,
			deviceId,
			checkedAt,
			note,
			taggedAt: Date(),
			taggedBy: session.userInfo.userId,
			editedAt: Date(),
			editedBy: session.userInfo.userId,
		};

		editItemAPI(args).then(({ message, item }) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('EditItem.MessageEditItemError'),
					});
					break;
				case 'SAME_LOCATION_OTHER_ITEM':
					notification.error({
						message: t('Error'),
						description: t('CreateItemConfuseOne.MessageSameLocation'),
					});
					break;
				case 'NOT_FOUND_OLD_ITEM':
					notification.error({
						message: t('Error'),
						description: t('EditItem.MessageNotFoundItemId'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('EditItem.MessageEditItemSuccess'),
					});
					__setData(item);
					break;
			}
		});
	};

	const onChangeType = (type) => setType(type);

	const onChangeLight = (light) => setLight(light);

	const onChangeTagMode = (tagMode) => setTagMode(tagMode);

	const onChangeNote = (note) => setNote(note);

	const onChangeColor = (color) => {
		switch (color) {
			case 'Color':
				setColor('Color');
				setTag('Rusty');
				break;
			case 'Discolor':
				setColor('Discolor');
				setTag('Unset');
				break;
			default:
				break;
		}
	};

	const onChangeTag = (tag) => {
		switch (tag) {
			case 'Dirty':
				setColor('Color');
				setTag('Dirty');
				break;
			case 'Rusty':
				setColor('Color');
				setTag('Rusty');
				break;
			case 'Graffiti':
				setColor('Color');
				setTag('Graffiti');
				break;
			case 'Unset':
				setColor('Discolor');
				setTag('Unset');
				break;
			default:
				break;
		}
	};

	const getLocation = (location, item) => {
		setLoading(true);
		if (item) {
			setItem(item);

			switch (item.flag) {
				case 'FORBIDDEN':
					setId(item.id);
					setTitle(item.title);
					setCompanyId(item.companyId);
					setAreaId(item.areaId);
					setType(item.type);
					setLocation(item.location);
					setColor(item.color);
					setLight(item.light);
					setFlag(item.flag);
					setTagMode(item.tagMode);
					setTag(item.tag);
					setTaggedAt(item.taggedAt);
					setTaggedBy(item.taggedBy);
					setImage(item.image);
					setNote(item.note);
					setVersion(item.version);
					setDeviceId(item.deviceId);
					setCheckedAt(item.checkedAt);
					setEditedBy(item.editedBy);
					setEditedAt(item.editedAt);

					setForbidden(true);
					setReject(false);
					setDisableReject(true);
					setDisableForbidden(false);
					break;
				default:
					setId(item.id);
					setTitle(item.title);
					setCompanyId(item.companyId);
					setAreaId(item.areaId);
					setType(aiType);
					setLocation(item.location);
					setColor(aiColor);
					setLight(aiLight);
					setFlag(item.flag);
					setTagMode(item.tagMode);
					setTag(aiTag);
					setTaggedAt(item.taggedAt);
					setTaggedBy(item.taggedBy);
					setImage(aiImage);
					setNote(item.note);
					setVersion(aiVersion);
					setDeviceId(aiDevice);
					setCheckedAt(aiCheckedAt);
					setEditedBy(item.editedBy);
					setEditedAt(item.editedAt);

					switch (item.flag) {
						case 'AUTOMATIC':
							setForbidden(false);
							setReject(false);
							setDisableReject(false);
							setDisableForbidden(false);
							break;
						case 'REJECT':
							setForbidden(false);
							setReject(true);
							setDisableReject(false);
							setDisableForbidden(true);
							break;
						default:
							break;
					}
					break;
			}
		}

		setLoading(false);
		setVisible(!visible);
	};

	const onChangeForbidden = (isTrue) => {
		if (isTrue) {
			setForbidden(isTrue);
			setDisableForbidden(false);
			setDisableReject(true);
			setFlag('FORBIDDEN');
		} else {
			setForbidden(isTrue);
			setDisableReject(false);
			setDisableForbidden(false);
			setFlag('AUTOMATIC');
		}
	};

	const onChangeReject = (isTrue) => {
		if (isTrue) {
			setReject(isTrue);
			setDisableReject(false);
			setDisableForbidden(true);
			setFlag('REJECT');
		} else {
			setReject(isTrue);
			setDisableReject(false);
			setDisableForbidden(false);
			setFlag('AUTOMATIC');
		}
	};

	return (
		<>
			<Title level={3} title={t('EditItem.Title')} />
			<Spin spinning={loading}>
				<Row>
					<Col span={12} offset={0}>
						<div className="title">{t('Id')}:</div>
						<Input
							readOnly={true}
							size="large"
							value={title}
							addonAfter={<EnvironmentOutlined onClick={() => setVisible(!visible)} />}
						/>

						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col span={12}>
								<div className="title">{t('Longitude')}:</div>
								<Input
									readOnly={true}
									size="large"
									// addonAfter={<AimOutlined onClick={() => setVisible(!visible)} />}
									value={location && location.coordinates[0]}
								/>
							</Col>
							<Col span={12}>
								<div className="title">{t('Latitude')}:</div>
								<Input
									readOnly={true}
									size="large"
									// addonAfter={<AimOutlined onClick={() => setVisible(!visible)} />}
									value={location && location.coordinates[1]}
								/>
							</Col>
						</Row>

						<div className="title">{t('Status')}:</div>
						<Input size="large" value={true ? t('Normal') : t('No Normal')} readOnly={true} />

						<div className="title">{t('Type')}:</div>
						<Select
							value={type}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeType(e)}
						>
							{types.map((type) => (
								<Select.Option value={type.value} key={type.value}>{type.label}</Select.Option>
							))}
						</Select>

						<div className="title">検査禁止フラグ:</div>
						<Select
							value={forbidden}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeForbidden(e)}
							disabled={disableForbidden}
						>
							{forbiddens.map((forbidden) => (
								<Select.Option value={forbidden.value} key={forbidden.value}>{forbidden.label}</Select.Option>
							))}
						</Select>

						<div className="title">認識結果不採用フラグ:</div>
						<Select
							value={reject}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeReject(e)}
							disabled={disableReject}
						>
							{rejects.map((reject) => (
								<Select.Option value={reject.value} key={reject.value}>{reject.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Light')}:</div>
						<Select
							value={light}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeLight(e)}
						>
							{lights.map((light) => (
								<Select.Option value={light.value} key={light.value}>{light.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('TagMode')}:</div>
						<Select
							value={tagMode}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeTagMode(e)}
						>
							{tagModes.map((mode) => (
								<Select.Option value={mode.value} key={mode.value}>{mode.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Color')}:</div>
						<Select
							value={color}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeColor(e)}
						>
							{colors.map((color) => (
								<Select.Option value={color.value} key={color.value}>{color.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Tag')}:</div>
						<Select
							value={tag}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeTag(e)}
						>
							{tags.map((tag) => (
								<Select.Option value={tag.value} key={tag.value}>{tag.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('TaggedBy')}:</div>
						<GroupSelection
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							type={'user'}
							value={taggedBy}
							disabled
						/>

						<div className="title">{t('TaggedAt')}:</div>
						<Input value={taggedAt && moment(taggedAt).format('LLLL')} size="large" disabled />

						<div className="title">{t('AiVersion')}:</div>
						<Input size="large" disabled value={version} />

						<div className="title">{t('DeviceId')}:</div>
						<GroupSelection
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							type={'device'}
							value={deviceId}
							disabled
						/>

						<div className="title">{t('CheckedAt')}:</div>
						<Input size="large" value={moment(checkedAt).format('LLLL')} disabled />

						<div className="title">{t('EditedAt')}:</div>
						<Input size="large" value={moment(editedAt).format('LLLL')} disabled />

						<div className="title">{t('EditedBy')}:</div>
						<GroupSelection
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							type={'user'}
							value={editedBy}
							disabled
						/>

						<div className="title">{t('Note')}:</div>
						<Input.TextArea
							className="ant-input ant-input-lg"
							rows={6}
							value={note}
							onChange={(e) => onChangeNote(e.target.value)}
						/>
					</Col>

					<Col span={11} offset={1}>
						<div className="title">{t('Image')}:</div>
						{image ? (
							<Image src={REACT_APP_API_ENDPOINT + image} style={{ width: '100% !important' }} />
						) : (
							<Empty description={false} />
						)}
					</Col>
				</Row>

				<Row style={{ marginTop: '20px' }} type="flex" justify="center" align="middle">
					<Col span={24}>
						<Row justify="center">
							<Space>
								<Button
									shape="round"
									className="button-optional"
									type="danger"
									size="large"
									onClick={() => history.goBack()}
								>
									{t('EditItem.Cancel')}
								</Button>
								<Button
									loading={loading}
									shape="round"
									className="button-optional"
									type="primary"
									size="large"
									onClick={() => editItem()}
								>
									{t('EditItem.Edit')}
								</Button>
							</Space>
						</Row>
					</Col>
				</Row>
			</Spin>

			<MapModule
				visible={visible}
				setVisible={setVisible}
				center={location}
				getLocation={getLocation}
				item={item}
				mode={'item'}
			/>
		</>
	);
};

export default EditItem;
