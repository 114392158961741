import { usePagination } from '@umijs/hooks';
import { List } from 'antd';
import { getDevicesOfflineAPI } from 'apis/device';
import InitialSetup from '../../Popup/InitialSetup';
import Setup from '../../Popup/Setup';

const OfflineTabPane = ({ currentTab }) => {
	const { data, loading, pagination } = usePagination(getDevicesOfflineAPI);
	return (
		<>
			<List
				loading={loading}
				grid={{
					gutter: 16,
					xs: 1,
					sm: 1,
					md: 2,
					lg: 3,
					xl: 4,
					xxl: 5,
				}}
				locale={{
					emptyText: 'データなし'
				}}
				pagination={pagination}
				dataSource={data}
				renderItem={(device) => {
					if (!device.status && !device.fps && !device.resolution)
						return <InitialSetup device={device} currentTab={currentTab} />;

					return <Setup device={device} currentTab={currentTab} />;
				}}
			/>
		</>
	);
};

export default OfflineTabPane;
