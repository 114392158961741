import { Col, Row, Typography, Input, Table, Form, Popconfirm, Select, Button, Pagination, notification } from 'antd';
import { useEffect, useState } from 'react';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { editDeviceAPI, getDevicesManagementAPI } from 'apis/device';
import { getItems } from 'utils/localStorage';
import { getAllCarsAPI } from 'apis/car';
import { getAllCompaniesAPI } from 'apis/company';
import { transformCars, transformCompany, transformAreas } from 'utils/transform';
import { getAllAreasAPI } from 'apis/area';

const { Search } = Input;

const EditableCell = ({
	editing,
	dataIndex,
	inputType,
	record,
	companies,
	cars,
	areas,
	children,
	...restProps
}) => {
	const [inputNode, setInputNode] = useState(null);
	useEffect(() => {
		switch (inputType) {
			case 'car':
				setInputNode(
					<Select style={{ width: '50px !important' }}>
						{cars.map((car) => {
							return <Select.Option value={car.id} key={car.id}>{car.title}</Select.Option>;
						})}
					</Select>
				);
				break;
			case 'area':
				setInputNode(
					<Select>
						{areas.map((area) => {
							return <Select.Option value={area.id} key={area.id}>{area.province + area.county}</Select.Option>;
						})}
					</Select>
				);
				break;
			case 'company':
				setInputNode(
					<Select disabled defaultValue={record.company}>
						{companies.map((company) => {
							return <Select.Option value={company.id} key={company.id}>{company.title}</Select.Option>;
						})}
					</Select>
				);
				break;
			default:
				setInputNode(<Input />);
				break;
		}
	}, [cars, companies, inputType, areas, record]);

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} required>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const ManageDevicesScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [devices, setDevices] = useState([]);
	const [keyword, setKeyword] = useState(null);

	const [companies, setCompanies] = useState([]);
	const [cars, setCars] = useState([]);
	const [areas, setAreas] = useState([]);

	const onChangeSearch = (value) => {
		if (value !== keyword) {
			setTotal(null);
			setDevices(null);
			setPageSize(null);
			setCurrent(1);
			setLimit(10);
			setKeyword(value);
		}
	};

	const isEditing = (record) => record.key === editingKey;

	const edit = (record) => {
		if (record.row.status === 'RUNNING') {
			notification.error({
				message: t('Error'),
				description: t('ManageDevices.MessageDeviceRunning'),
			});
		} else {
			form.setFieldsValue({
				...record,
			});
			setEditingKey(record.key);
		}
	};

	const cancel = () => {
		setEditingKey('');
	};

	const save = async (record) => {
		try {
			const row = await form.validateFields();
			const newData = [...devices];
			const index = newData.findIndex((item) => record.key === item.key);

			if (index > -1) {
				const noUpdated = row.area === record.area && row.car === record.car;

				if (noUpdated) {
					notification.error({
						message: t('Error'),
						description: t('ManageDevices.MessageNoUpdated'),
					});
					return;
				}

				const args = {
					id: record.id,
					carId: row.car,
					areaId: row.area,
					companyId: record.company
				};

				editDeviceAPI(args).then((title) => {
					if (!title) {
						notification.error({
							message: t('Error'),
							description: t('ErrorDescription'),
						});
						setEditingKey('');
						return;
					}

					const item = newData[index];
					item.title = title;

					newData.splice(index, 1, { ...item, ...row });
					setDevices(newData);

					notification.success({
						message: t('Success'),
						description: t('ManageDevices.MessageSuccess'),
					});

					setEditingKey('');
					return;
				});
			}
		} catch (errInfo) {
			notification.error({
				message: t('Error'),
				description: t('ErrorDescription'),
			});
		}
	};

	const columns = [
		{
			title: t('No'),
			dataIndex: 'key',
			sorter: (a, b) => a.key - b.key,
		},
		{
			title: t('ManageDevices.DeviceIdField'),
			dataIndex: 'title',
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: t('ManageDevices.CompanyField'),
			dataIndex: 'company',
			width: '20%',
			sorter: (a, b) => a.company - b.company,
			render: (company) => {
				return transformCompany(companies, company);
			},
		},
		{
			title: t('ManageDevices.MacField'),
			dataIndex: 'mac',
			sorter: (a, b) => a.mac.localeCompare(b.mac),
		},
		{
			title: t('ManageDevices.CarField'),
			dataIndex: 'car',
			editable: true,
			sorter: (a, b) => a.car - b.car,
			render: (car) => {
				return transformCars(cars, car);
			},
		},
		{
			title: t('ManageDevices.ProvinceField'),
			dataIndex: 'area',
			editable: true,
			sorter: (a, b) => a.area - b.area,
			render: (area) => {
				return transformAreas(areas, area);
			},
		},
		{
			title: t('Status'),
			dataIndex: 'status',
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: (status) => {
				switch (status) {
					case 'RUNNING':
						status = t('SelectDevice.Running');
						break;
					case 'NONE':
						status = t('SelectDevice.Offline');
						break;
					case 'STOP_BY_CODE':
						status = t('SelectDevice.Offline');
						break;
					case 'STOP_BY_CLIENT':
						status = t('SelectDevice.Waiting');
						break;
					default:
						status = t('SelectDevice.Offline');
						break;
				}
				return status;
			},
		},
		{
			title: t('ManageAccounts.OptionField'),
			dataIndex: 'Actions',
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<a
							href="javascript:;"
							onClick={() => save(record)}
							style={{
								marginRight: 8,
							}}
						>
							{t('ManageAccounts.Save')}
						</a>
						<Popconfirm title={t('ConfirmCancel')} onConfirm={cancel} cancelText={'いいえ'} okText={'はい'}>
							<a>{t('Cancel')}</a>
						</Popconfirm>
					</span>
				) : (
					[
						<Typography.Link
							key={1}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
							mergedColumns
							style={{ marginRight: '20px' }}
						>
							{t('ManageAccounts.Edit')}
						</Typography.Link>,
					]
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.dataIndex,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				companies,
				areas,
				cars,
			}),
		};
	});

	const registerDevice = () => history.push('register-device');

	useEffect(() => {
		const { session } = getItems(['session']);
		setLoading(true);
		let offset = (current - 1) * limit;
		getDevicesManagementAPI(session.userInfo.companyId, offset, limit, keyword).then(({ result }) => {
			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;
				setTotal(count);
				setPageSize(limit);
				let devices = [];

				rows.forEach((row, index) => {
					devices.push({
						id: row.id,
						key: (current - 1) * limit + index + 1,
						title: row.title,
						mac: row.mac,
						company: row.companyId,
						area: row.areaId,
						car: row.carId,
						status: row.status,
						row: row,
					});
				});
				setDevices(devices);
			}
			setLoading(false);
		});
	}, [companies, current, keyword, limit]);

	useState(() => {
		getAllCarsAPI().then((cars) => {
			setCars(cars);
			getAllCompaniesAPI().then((companies) => {
				setCompanies(companies);
				getAllAreasAPI().then((areas) => {
					setAreas(areas);
				});
			});
		});
	}, []);

	return (
		<>
			<Title level={3} title={t('ManageDevices.Title')} />

			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={6}>
					<Row justify="left">
						<Button
							shape="round"
							type="primary"
							size="large"
							style={{ marginRight: '5px' }}
							onClick={() => registerDevice()}
						>
							{t('ManageDevices.Register')}
						</Button>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="center">
						<Search enterButton={t('Search')} size="large" onSearch={(e) => onChangeSearch(e)} />
					</Row>
				</Col>
				<Col span={6}></Col>
			</Row>
			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Form form={form} component={false}>
						<Table
							loading={loading}
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							locale={{
								triggerDesc: '降順で並べ替え',
								triggerAsc: '昇順で並べ替え',
								cancelSort: '並べ替え状態を解除',
								emptyText: 'データなし'
							}}
							bordered
							dataSource={devices}
							columns={mergedColumns}
							rowClassName="editable-row"
							pagination={false}
							// scroll={{ x: 1024 }}
							scroll={{ x: 'calc(100vw)' }}
						/>
						<Pagination
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '5px',
								marginBottom: '5px'
							}}
							onChange={(current) => setCurrent(current)}
							total={total}
							current={current}
							pageSize={pageSize}
							defaultCurrent={1}
							defaultPageSize={10}
						/>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default ManageDevicesScreen;
