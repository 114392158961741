export const saveItems = (array) => {
	array.forEach((element) => {
		localStorage.setItem(element.item, JSON.stringify(element.data));
	});
};

export const removeItems = (array) => {
	array.forEach((element) => {
		localStorage.removeItem(element);
	});
};

export const getItems = (array) => {
	const items = {};
	array.forEach((element) => {
		const item = JSON.parse(localStorage.getItem(element));
		items[element] = item;
	});
	return items;
};

export const removeAllItems = () => localStorage.clear();
