import { Col, Row, Form, Input, Button, Space } from 'antd';
import { useChangePassword } from 'hooks/useChangePassword';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Title from 'components/Title';
import { Fragment } from 'react';

const ChangePasswordScreen = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { loading, onFinish } = useChangePassword()

	return (
		<Fragment>
			<Title
				level={3}
				title={t('ChangePassword.Title')}
			/>

			<Row
				type="flex"
				justify="center"
				align="middle"
			>
				<Col
					sm={{
						span: 10,
						offset: 0
					}}
					lg={{
						span: 9,
						offset: 0
					}}
				>
					<Form
						name="basic"
						initialValues={{
							remember: true
						}}
						layout={'vertical'}
						size={'large'}
						onFinish={onFinish}
					>
						<Form.Item
							label={t('ChangePassword.CurrentPasswordLabel')}
							name="currentPassword"
							rules={[
								{
									required: true,
									message: t('EmptyField', {
										Field: t('ChangePassword.CurrentPasswordLabel'),
									}),
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label={t('ChangePassword.NewPasswordLabel')}
							name="newPassword"
							rules={[
								{
									required: true,
									message: t('EmptyField', {
										Field: t('ChangePassword.NewPasswordLabel'),
									}),
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label={t('ChangePassword.ReNewPasswordLabel')}
							name="reNewPassword"
							rules={[
								{
									required: true,
									message: t('EmptyField', {
										Field: t('ChangePassword.ReNewPasswordLabel'),
									}),
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							wrapperCol={{
								span: 16,
							}}
							style={{
								float: 'right',
								marginTop: '8px'
							}}
						>
							<Space>
								<Button
									shape="round"
									className="button-optional"
									type="danger"
									onClick={() => history.goBack()}
								>
									{t('ChangePassword.Back')}
								</Button>
								<Button
									shape="round"
									className="button-optional"
									type="primary"
									htmlType="submit"
									loading={loading}
								>
									{t('ChangePassword.Save')}
								</Button>
							</Space>
						</Form.Item>

					</Form>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ChangePasswordScreen;
