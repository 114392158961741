import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { transformFlag, transformTag, transformType } from 'utils/transform';

export const col = [
	{
		title: 'No.',
		dataIndex: 'key',
		key: 'key',
		ellipsis: {
			showTitle: false,
		},
		render: (key) => (
			<Tooltip placement="topLeft" title={key}>
				{key}
			</Tooltip>
		),
		sorter: (a, b) => a.key - b.key,
		sortDirections: ['descend', 'ascend'],
		width: 25,
	},
	{
		title: '被検体番号',
		dataIndex: 'title',
		key: 'title',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.title.localeCompare(b.title),
		render: (title) => (
			<Tooltip placement="topLeft" title={title}>
				{title}
			</Tooltip>
		),
		width: 55,
	},
	{
		title: '検査対象区分',
		dataIndex: 'type',
		key: 'type',
		ellipsis: {
			showTitle: false,
		},
		render: (type) => (
			<Tooltip placement="topLeft" title={transformType(type)}>
				{transformType(type)}
			</Tooltip>
		),
		sorter: (a, b) => a.type.localeCompare(b.type),
		width: 35,
	},
	{
		title: '制御フラグ',
		dataIndex: 'flag',
		key: 'flag',
		ellipsis: {
			showTitle: false,
		},
		render: (flag) => (
			<Tooltip placement="topLeft" title={transformFlag(flag)}>
				{transformFlag(flag)}
			</Tooltip>
		),
		width: 50,
		sorter: (a, b) => a.flag.localeCompare(b.flag),
	},
	{
		title: '所在地',
		dataIndex: 'area',
		key: 'area',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.area.localeCompare(b.area),
		render: (area) => (
			<Tooltip placement="topLeft" title={area}>
				{area}
			</Tooltip>
		),
		width: 30,
	},
	{
		title: '判定',
		dataIndex: 'status',
		key: 'status',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.status - b.status,
		render: (status) => (
			<Tooltip placement="topLeft" title={status ? '合格' : '不合格'}>
				{status ? '合格' : '不合格'}
			</Tooltip>
		),
		width: 35,
	},
	{
		title: '変色区分',
		dataIndex: 'tag',
		key: 'tag',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.tag.localeCompare(b.tag),
		render: (tag) => (
			<Tooltip placement="topLeft" title={transformTag(tag)}>
				{transformTag(tag)}
			</Tooltip>
		),
		width: 25,
	},
	{
		title: '所属会社',
		dataIndex: 'company',
		key: 'company',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.company.localeCompare(b.company),
		render: (company) => (
			<Tooltip placement="topLeft" title={company}>
				{company}
			</Tooltip>
		),
		width: 100,
	},
	{
		title: '車輪区分',
		dataIndex: 'car',
		key: 'car',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.car.localeCompare(b.car),
		render: (car) => (
			<Tooltip placement="topLeft" title={car}>
				{car}
			</Tooltip>
		),
		width: 30,
	},
	{
		title: '車載機器番号',
		dataIndex: 'device',
		key: 'device',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => a.device.localeCompare(b.device),
		render: (title) => (
			<Tooltip placement="topLeft" title={title}>
				{title}
			</Tooltip>
		),
		width: 60,
	},
	{
		title: '点検時刻',
		dataIndex: 'checkedAt',
		key: 'checkedAt',
		ellipsis: {
			showTitle: false,
		},
		sorter: (a, b) => new Date(a.checkedAt) - new Date(b.checkedAt),
		render: (checkedAt) => (
			<Tooltip placement="topLeft" title={checkedAt}>
				{checkedAt}
			</Tooltip>
		),
		width: 60,
	},
	{
		title: 'オプション',
		fixed: 'right',
		width: 20,
		render: (text, row) => <Link to={{ pathname: 'detail-item', state: row }}>詳細</Link>,
	},
];
