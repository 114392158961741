import React, { memo, useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Space, notification } from 'antd';
import { Typography } from 'antd';
import { ArrowRightOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { resetPasswordAPI } from 'apis/auth';
import { removeAllItems } from '../../utils/localStorage';

const { Title } = Typography;

const ResetPasswordScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [token, setToken] = useState(null);

	const onFinish = (values) => {
		setLoading(true);
		const { password, confirmPassword } = values;

		if (!token) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('ErrorDescription'),
			});
			return;
		}

		if (!isPasswordValidation(password)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: 'パスワードは英字大文字、英字小文字、数字を1文字以上使用し、8文字以上で設定してください',
			});
			return;
		}

		if (password !== confirmPassword) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: 'パスワードが正しくありません。',
			});
			return;
		}

		resetPasswordAPI(token, password).then(({ message }) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: 'エラーが発生しました。',
					});
					break;
				case 'ERROR_RESET_PASSWORD':
					notification.error({
						message: t('Error'),
						description: 'エラーが発生しました。',
					});
					break;
				case 'NOT_FOUND':
					notification.error({
						message: t('Error'),
						description: 'エラーが発生しました。',
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: 'パスワードがリセットされました。',
					});
					history.push('/sign-in');
					break;
			}
		});
	};

	const isPasswordValidation = (password) => {
		const res = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.exec(password);
		const valid = !!res;
		return valid;
	};

	useEffect(() => {
		removeAllItems();
		let token = new URLSearchParams(window.location.search).get('token');
		setToken(token);
	}, []);

	return (
		<>
			<Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
				<Col sm={{ span: 10, offset: 0 }} lg={{ span: 8, offset: 0 }}>
					<Row justify="center">
						<Typography>
							<Title level={2}>パスワード再発行</Title>
						</Typography>
					</Row>

					<Form
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						layout={'vertical'}
						size={'large'}
					>
						<Form.Item
							label={t('ChangePassword.NewPasswordLabel')}
							name="password"
							rules={[
								{
									required: true,
									message: '新しいパスワードを入力してください。',
								},
							]}
						>
							<Input.Password type="password" prefix={<LockOutlined className="site-form-item-icon" />} />
						</Form.Item>

						<Form.Item
							label={t('ChangePassword.ReNewPasswordLabel')}
							name="confirmPassword"
							rules={[
								{
									required: true,
									message: '確認パスワードを入力してください。',
								},
							]}
						>
							<Input.Password type="password" prefix={<LockOutlined className="site-form-item-icon" />} />
						</Form.Item>

						<Form.Item
							wrapperCol={{
								span: 16,
							}}
							style={{ float: 'right', marginTop: '8px' }}
						>
							<Space>
								<Button shape="round" className="button-optional" type="primary" htmlType="submit" loading={loading}>
									送信 <ArrowRightOutlined />
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default memo(ResetPasswordScreen);
