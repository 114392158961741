import { createContext } from 'react';
import { useState } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
	const [configMap, setConfigMap] = useState({
		center: {
			lat: 33.600490716074056,
			lng: 130.4021175234694,
		},
		zoom: 20,
	});

	return (
		<MapContext.Provider
			value={{ configMap, setConfigMap }}>
			{children}
		</MapContext.Provider>
	);
};

export default MapContext;
