import { LockOutlined } from '@ant-design/icons';
import { Button, Space, Modal, Input, Select, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getAllCompaniesAPI } from 'apis/company';
import { createAccountAPI } from 'apis/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const CreateAccountModal = (props) => {
	const { t } = useTranslation();
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		getAllCompaniesAPI().then((companies) => setCompanies(companies));
	}, []);

	const createAccount = (values) => {
		setLoading(true);
		const { username, email, password } = values;

		if (!isUserNameValidation(username)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('ManageAccounts.MessageErrorFormatUsername'),
			});
			return;
		}

		if (!isEmailValidation(email)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('ManageAccounts.MessageErrorFormatEmail'),
			});
			return;
		}

		if (!isPasswordValidation(password)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('ManageAccounts.MessageErrorFormatPassword'),
			});
			return;
		}

		const args = {
			username: values.username,
			email: values.email,
			password: values.password,
			companyId: values.company,
			role: values.role,
		};

		createAccountAPI(args).then((message) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'USERNAME_ALREADY_EXISTS':
					notification.error({
						message: t('Error'),
						description: t('ManageAccounts.MessageValidUsername'),
					});
					break;
				case 'EMAIL_ALREADY_EXISTS':
					notification.error({
						message: t('Error'),
						description: t('ManageAccounts.MessageValidEmail'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('ManageAccounts.MessageSuccess'),
					});
					props.setVisible(false);
					props.loadingData();
					form.resetFields();
					break;
			}
		});
	};

	const close = () => {
		form.resetFields();
		props.setVisible(false);
	};

	const isUserNameValidation = (username) => {
		const res = /^[a-zA-Z0-9]+$/.exec(username);
		const valid = !!res;
		return valid;
	};

	const isEmailValidation = (email) => {
		const res =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
				email
			);
		const valid = !!res;
		return valid;
	};

	const isPasswordValidation = (password) => {
		const res = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.exec(password);
		const valid = !!res;
		return valid;
	};

	return (
		<Modal
			title={t('ManageAccounts.TitlePopup')}
			width={650}
			visible={props.visible}
			closable={false}
			onCancel={() => close()}
			footer={
				<Space>
					<Button shape="round" type="danger" onClick={() => close()}>
						{t('ManageAccounts.Close')}
					</Button>
					<Button shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
						{t('ManageAccounts.Create')}
					</Button>
				</Space>
			}
		>
			<Form
				name="basic"
				initialValues={{
					remember: true,
				}}
				layout={'vertical'}
				size={'large'}
				style={{ marginTop: '-25px' }}
				form={form}
				onFinish={(e) => createAccount(e)}
			>
				<Form.Item
					label={t('ManageAccounts.UsernameLabel')}
					name="username"
					rules={[
						{
							required: true,
							message: t('EmptyField', {
								Field: t('ManageAccounts.UsernameLabel'),
							}),
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label={t('ManageAccounts.EmailLabel')}
					name="email"
					rules={[
						{
							required: true,
							message: t('EmptyField', {
								Field: t('ManageAccounts.EmailLabel'),
							}),
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label={t('ManageAccounts.PasswordLabel')}
					name="password"
					rules={[
						{
							required: true,
							message: t('EmptyField', {
								Field: t('ManageAccounts.PasswordLabel'),
							}),
						},
					]}
				>
					<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
				</Form.Item>

				<Form.Item
					label={t('ManageAccounts.CompanyLabel')}
					name="company"
					rules={[
						{
							required: true,
							message: t('EmptyField', {
								Field: t('ManageAccounts.CompanyLabel'),
							}),
						},
					]}
				>
					<Select>
						{companies.map((company) => {
							return <Select.Option value={company.id} key={company.id}>{company.title}</Select.Option>;
						})}
					</Select>
				</Form.Item>

				<Form.Item
					label={t('ManageAccounts.RoleLabel')}
					name="role"
					rules={[
						{
							required: true,
							message: t('EmptyField', {
								Field: t('ManageAccounts.RoleLabel'),
							}),
						},
					]}
				>
					<Select>
						<Option value="Operator">オペレーター</Option>
						<Option value="Admin">管理者</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreateAccountModal;
