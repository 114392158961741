import api from './api';
import { io } from 'socket.io-client';
import { REACT_APP_SOCKET_ENDPOINT, transports } from '../configs/socket';
import { getItems } from 'utils/localStorage';

export const getDevicesOnlineAPI = async ({ current, pageSize }) => {
	const { session } = getItems(['session']);

	const params = {
		companyId: session.userInfo.companyId,
		current: current,
		pageSize: pageSize,
	};

	return new Promise((res) => {
		api
			.get('device/online', { params })
			.then((response) => {
				let { count, rows } = response.data;

				const isRealOnline = (device) =>
					device.status === 'NONE' ||
					device.status === 'RUNNING' ||
					device.status === 'RUNNING_ERROR_GPS' ||
					device.status === 'RUNNING_ERROR_CAMERA' ||
					device.status === 'RUNNING_ERROR_ALL' ||
					device.status === 'STOP_BY_CLIENT' ||
					device.status === 'STOP_BY_CLIENT_ERROR_GPS' ||
					device.status === 'STOP_BY_CLIENT_ERROR_CAMERA' ||
					device.status === 'STOP_BY_CLIENT_ERROR_ALL';

				let devices = rows.filter((row) => isRealOnline(row)).map((row) => row);

				let sockets = devices.map(() =>
					io(REACT_APP_SOCKET_ENDPOINT, {
						query: {
							token: session.tokens.access.token,
						},
						transports,
					})
				);

				let flags = devices.map((row) => ({
					id: row.id,
					title: row.title,
					isOnline: false,
					status: row.status,
				}));

				sockets.forEach((socket, index) => {
					socket.emit('room', `mac-${rows[index].mac}`);

					socket.on('notify', (response) => {
						if (response) flags[index].isOnline = true;
					});
				});

				let result = rows;

				setTimeout(async () => {
					await Promise.all(
						flags.map((flag) => {
							return new Promise((resolve) => {
								if (!flag.isOnline) {
									stopByCodeAPI(flag.id).then(() => {
										result = rows.filter((row) => row.deviceId !== flag.deviceId);
										resolve();
									});
								} else {
									resolve();
								}
								// resolve();
							});
						})
					);
					sockets.forEach((socket) => {
						socket.disconnect();
					});
					res({
						total: count,
						data: result,
					});
				}, 5000);
			})
			.catch((response) => {
				console.log(response);
				res({
					total: 0,
					data: [],
				});
			});
	});
};

export const getDevicesOfflineAPI = async ({ current, pageSize }) => {
	const { session } = getItems(['session']);
	const params = {
		companyId: session.userInfo.companyId,
		current: current,
		pageSize: pageSize,
	};

	return api
		.get('device/offline', { params })
		.then((response) => {
			return {
				total: response.data.count,
				data: response.data.rows,
			};
		})
		.catch((response) => {
			console.log(response);
			return {
				total: 0,
				data: [],
			};
		});
};

export  const checkExistedDeviceAPI = async () => {
	return api
		.get('device/valid', )
		.then((response) => {
			return response.data.isValid;
		})
		.catch((response) => {
			console.log(response);
			return false;
		});
}

export const getDevicesAPI = ({ current, pageSize }) => {
	const { session } = getItems(['session']);

	const params = {
		companyId: session.userInfo.companyId,
		current: current,
		pageSize: pageSize,
	};

	return new Promise((res) => {
		api
			.get('device/all', { params })
			.then((response) => {
				let { count, rows } = response.data;

				const isRealOnline = (device) =>
					device.status === 'NONE' ||
					device.status === 'RUNNING' ||
					device.status === 'RUNNING_ERROR_GPS' ||
					device.status === 'RUNNING_ERROR_CAMERA' ||
					device.status === 'RUNNING_ERROR_ALL' ||
					device.status === 'STOP_BY_CLIENT' ||
					device.status === 'STOP_BY_CLIENT_ERROR_GPS' ||
					device.status === 'STOP_BY_CLIENT_ERROR_CAMERA' ||
					device.status === 'STOP_BY_CLIENT_ERROR_ALL';

				let devices = rows.filter((row) => isRealOnline(row)).map((row) => row);

				let sockets = devices.map(() =>
					io(REACT_APP_SOCKET_ENDPOINT, {
						query: {
							token: session.tokens.access.token,
						},
						transports,
					})
				);

				let flags = devices.map((row) => ({
					id: row.id,
					title: row.title,
					isOnline: false,
				}));

				sockets.forEach((socket, index) => {
					socket.emit('room', `mac-${rows[index].mac}`);

					socket.on('notify', (response) => {
						if (response) flags[index].isOnline = true;
					});
				});

				setTimeout(async () => {
					await Promise.all(
						flags.map((flag, index) => {
							return new Promise((resolve) => {
								if (!flag.isOnline) {
									stopByCodeAPI(flag.id).then((message) => {
										console.log(message);
										rows[index].status = 'STOP_BY_CODE';
										resolve();
									});
								} else {
									resolve();
								}
								// resolve();
							});
						})
					);
					sockets.forEach((socket) => {
						socket.disconnect();
					});
					res({
						total: count,
						data: rows,
					});
				}, 5000);
			})
			.catch((response) => {
				console.log(response);
				res({
					total: 0,
					data: [],
				});
			});
	});
};

export const getMyDevicesAPI = ({ current, pageSize }) => {
	const { session } = getItems(['session']);

	const params = {
		companyId: session.userInfo.companyId,
		current: current,
		pageSize: pageSize,
	};

	return new Promise((res) => {
		api
			.get('device/my-devices', { params })
			.then((response) => {
				let { count, rows } = response.data;

				const isRealOnline = (device) =>
					device.status === 'NONE' ||
					device.status === 'RUNNING' ||
					device.status === 'RUNNING_ERROR_GPS' ||
					device.status === 'RUNNING_ERROR_CAMERA' ||
					device.status === 'RUNNING_ERROR_ALL' ||
					device.status === 'STOP_BY_CLIENT' ||
					device.status === 'STOP_BY_CLIENT_ERROR_GPS' ||
					device.status === 'STOP_BY_CLIENT_ERROR_CAMERA' ||
					device.status === 'STOP_BY_CLIENT_ERROR_ALL';

				let devices = rows.filter((row) => isRealOnline(row)).map((row) => row);

				let sockets = devices.map(() =>
					io(REACT_APP_SOCKET_ENDPOINT, {
						query: {
							token: session.tokens.access.token,
						},
						transports,
					})
				);

				let flags = devices.map((row) => ({
					id: row.id,
					title: row.title,
					isOnline: false,
				}));

				sockets.forEach((socket, index) => {
					socket.emit('room', `mac-${rows[index].mac}`);

					socket.on('notify', (response) => {
						if (response) flags[index].isOnline = true;
					});
				});

				let result = rows;

				setTimeout(async () => {
					await Promise.all(
						flags.map((flag, index) => {
							return new Promise((resolve) => {
								if (!flag.isOnline) {
									stopByCodeAPI(flag.id).then((message) => {
										console.log(message);
										rows[index].status = 'STOP_BY_CODE';
										resolve();
									});
								} else {
									resolve();
								}
								// resolve();
							});
						})
					);
					sockets.forEach((socket) => {
						socket.disconnect();
					});
					res({
						total: count,
						data: result,
					});
				}, 5000);
			})
			.catch((response) => {
				console.log(response);
				res({
					total: 0,
					data: [],
				});
			});
	});
};

export const setupDeviceAPI = (args) => {
	return api
		.post('device/setup', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const registerDeviceAPI = (args) => {
	return api
		.post('device/register', args)
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const checkValidMacAddressAPI = (mac) => {
	const params = {
		mac,
	};

	return api
		.get('device/mac', {
			params,
		})
		.then((response) => {
			const message = response.data.message;
			return message === 'SUCCESS' || message === 'DEVICE_NOT_CONFIG' ? true : false;
		})
		.catch((response) => {
			console.log(response);
			return false;
		});
};

export const startDeviceAPI = (id) => {
	const { session } = getItems(['session']);

	const data = {
		id,
		userId: session.userInfo.userId,
	};
	return api
		.post('device/start', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const stopDeviceAPI = (id) => {
	const { session } = getItems(['session']);

	const data = {
		id,
		userId: session.userInfo.userId,
	};
	return api
		.post('device/stop', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const stopByCodeAPI = (id) => {
	const data = {
		id,
	};

	return api
		.post('device/stop-by-code', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const getLastDeviceAPI = () => {
	return api
		.get('device/last')
		.then((response) => {
			return response.data.device;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};

export const getDevicesManagementAPI = (companyId, offset, limit, keyword) => {
	const params = {
		companyId,
		offset,
		limit,
		keyword,
	};

	return api
		.get('device/manage', { params })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};

export const confirmRegisterDeviceAPI = (args) => {
	return api
		.post('device/confirm', args)
		.then((response) => {
			return {
				message: response.data.message,
				device: response.data.device,
			};
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const editDeviceAPI = (args) => {
	return api
		.put('device', args)
		.then((response) => {
			return response.data.title;
		})
		.catch((response) => {
			console.log(response);
			return null;
		});
};

export const removeDeviceAPI = (id) => {
	const data = {
		id,
	};

	return api
		.post('device/remove', data)
		.then((response) => {
			return response.data.title;
		})
		.catch((response) => {
			console.log(response);
			return null;
		});
};

export const getDeviceByIdAPI = (id) => {
	const params = {
		id,
	};

	return api
		.get('device', { params })
		.then((response) => {
			return response.data.device?.title;
		})
		.catch((response) => {
			console.log(response);
			return null;
		});
};

export const getAllDevicesAPI = () => {
	const { session } = getItems(['session']);
	const params = { companyId: session.userInfo.companyId };
	return api
		.get('device/all/v2', { params })
		.then((response) => {
			return response.data.devices;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};
