import { LoadingOutlined } from '@ant-design/icons';
import { Result, Row } from 'antd';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';

const ErrorNetwork = () => {
	const { t } = useTranslation();

	return (
		<div style={{ height: '92%' }}>
			<Title level={3} title={t('Monitor.Title')} />
			<Row type="flex" justify="center">
				<Result
					icon={<LoadingOutlined style={{ fontSize: 40 }} />}
					style={{ position: 'absolute', top: '36%' }}
					title="ネットワークが不安定です。"
					subTitle="ネットワーク信号が弱すぎますので、車載機器を監視できません。"
				/>
			</Row>
		</div>
	);
};

export default ErrorNetwork;
