import { notification } from 'antd';
import { changePasswordAPI } from 'apis/user';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const useChangePassword = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        const { currentPassword, newPassword, reNewPassword } = values;

        if (newPassword !== reNewPassword) {
            setLoading(false);
            notification.error({
                message: t('Error'),
                description: t('ChangePassword.MessangeNoMatchNewPassword'),
            });
            return;
        }

        changePasswordAPI(currentPassword, newPassword).then((message) => {
            setLoading(false);
            switch (message) {
                case 'ERROR':
                    notification.error({
                        message: t('Error'),
                        description: t('ChangePassword.MessageChangePasswordError'),
                    });
                    break;
                case 'INCORRECT_PASSWORD':
                    notification.error({
                        message: t('Error'),
                        description: t('ChangePassword.MessageErrorOldPassword'),
                    });
                    break;
                default:
                    notification.success({
                        message: t('Success'),
                        description: t('ChangePassword.MessageChangePasswordSuccess'),
                    });

                    history.push('/app/my-account');
                    break;
            }
        });
    };

    return {
        loading,
        onFinish
    }
}