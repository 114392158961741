import { Col, Row, Typography, Input, Table, Form, Popconfirm, Button, Pagination, notification, Upload } from 'antd';
import readXlsxFile from 'read-excel-file';
import { useCallback, useEffect, useState } from 'react';
import Title from 'components/Title';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
	checkValidCompanyAPI,
	createCompanyAPI,
	editCompanyAPI,
	getCompaniesManagementAPI,
	removeCompanyAPI,
} from 'apis/company';
import { REACT_APP_API_ENDPOINT } from 'configs/api';

const { Search } = Input;

const EditableCell = ({ editing, dataIndex, title, children, ...restProps }) => {
	const inputNode = <Input />;

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required: true,
							message: `${title} を入力ください!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const ManageCompaniesScreen = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [loadingUpload, setLoadingUpload] = useState(false);

	const onSearchKeyword = (value) => {
		if (value !== keyword) {
			setTotal(null);
			setCompanies(null);
			setPageSize(null);
			setCurrent(1);
			setLimit(10);
			setKeyword(value);
		}
	};

	const isEditing = (record) => record.key === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.key);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const save = async (record) => {
		try {
			const row = await form.validateFields();

			const newData = [...companies];
			const index = newData.findIndex((item) => record.key === item.key);

			if (index > -1) {
				const isSameFields = row.title === record.title;

				if (isSameFields) {
					notification.error({
						message: t('Error'),
						description: t('MessageNoUpdated'),
					});
					return;
				}

				const item = newData[index];

				editCompanyAPI(record.id, row.title).then((message) => {
					switch (message) {
						case 'ERROR':
							notification.error({
								message: t('Error'),
								description: t('ErrorDescription'),
							});
							break;
						case 'EXISTED_TITLE':
							notification.error({
								message: t('Error'),
								description: t('ManageCompanies.MessageValidTitleCompany'),
							});
							break;
						default:
							newData.splice(index, 1, { ...item, ...row });
							setCompanies(newData);
							setEditingKey('');
							notification.success({
								message: t('Success'),
								description: t('ManageCompanies.MessageSuccess'),
							});
							break;
					}
				});
			}
		} catch (errInfo) {
			notification.error({
				message: t('Error'),
				description: t('ErrorDescription'),
			});
		}
	};

	const columns = [
		{
			title: t('ManageCompanies.NoField'),
			dataIndex: 'key',
			width: '10%',
			editable: false,
			sorter: (a, b) => a.key - b.key,
		},
		{
			title: t('ManageCompanies.FullNameField'),
			dataIndex: 'title',
			width: '30%',
			editable: true,
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: t('ManageCompanies.KeyField'),
			dataIndex: 'keyCompany',
			width: '25%',
			sorter: (a, b) => a.keyCompany.localeCompare(b.keyCompany),
		},
		{
			title: t('ManageCompanies.OptionField'),
			dataIndex: 'Actions',
			width: '15%',
			render: (_, record) => {
				const editable = isEditing(record);

				const remove = () => {
					removeCompanyAPI(record.id).then((message) => {
						switch (message) {
							case 'ERROR':
								notification.error({
									message: t('Error'),
									description: t('ErrorDescription'),
								});
								break;
							case 'USED_COMPANY':
								notification.error({
									message: t('Error'),
									description: 'この車輛所有会社は車載機器等に紐付けされていますので、削除できません。',
								});
								break;
							default:
								notification.success({
									message: t('Success'),
									description: '設定が完了しました。',
								});
								break;
						}
						__setData();
					});
				};

				return editable ? (
					<span>
						<a
							href="javascript:;"
							onClick={() => save(record)}
							style={{
								marginRight: 8,
							}}
						>
							保存
						</a>
						<Popconfirm title="キャンセルしますか。" onConfirm={cancel} cancelText={'いいえ'} okText={'はい'}>
							<a>{t('Cancel')}</a>
						</Popconfirm>
					</span>
				) : (
					[
						<Typography.Link
							key={1}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
							style={{ marginRight: '20px' }}
						>
							{t('Edit')}
						</Typography.Link>,
						<Typography.Link
							key={2}
							disabled={editingKey !== ''}
							onClick={() => remove()}
							style={{ marginRight: '20px', color: 'red' }}
						>
							削除
						</Typography.Link>,
					]
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.dataIndex,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	const __setData = useCallback(() => {
		setLoading(true);
		let offset = (current - 1) * limit;
		getCompaniesManagementAPI(offset, limit, keyword).then(({ result }) => {
			setLoading(false);
			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;

				setTotal(count);
				setPageSize(limit);
				let companies = [];

				rows.forEach((row, index) => {
					let { id, title, key } = row;
					companies.push({
						id: id,
						key: (current - 1) * limit + index + 1,
						title: title,
						keyCompany: key,
					});
				});

				setCompanies(companies);
			}
		});
	}, [current, keyword, limit]);

	useEffect(() => {
		__setData();
	}, [__setData]);

	return (
		<>
			<Title level={3} title={t('ManageCompanies.Title')} />

			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={6}></Col>
				<Col span={12}>
					<Row justify="center">
						<Search enterButton={t('Search')} size="large" onSearch={(e) => onSearchKeyword(e)} />
					</Row>
				</Col>
				<Col span={6}>
					<Row justify="end">
						<Upload
							accept=".xlsx"
							showUploadList={false}
							beforeUpload={async (file) => {
								setLoadingUpload(true);
								await readXlsxFile(file).then(async (rows) => {
									setTimeout(async () => {
										const isValidated = await validateDataInSheet(rows, t);
										if (isValidated) await importDataInSheet(rows);
										__setData();
										setLoadingUpload(false);
									}, 1500);
								});
								return false;
							}}
						>
							<Button size="large" shape="round" type="primary" icon={<UploadOutlined />} loading={loadingUpload}>
								{t('ManageCompanies.Upload')}
							</Button>
						</Upload>
					</Row>
				</Col>
			</Row>

			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Row justify="end">
						<a href={REACT_APP_API_ENDPOINT + 'assets/excels/車輌所有会社インポートフォーマット.xlsx'}>
							車輌所有会社インポートフォーマット.xlsx
						</a>
					</Row>
				</Col>
			</Row>

			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Form form={form} component={false}>
						<Table
							loading={loading}
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							locale={{
								triggerDesc: '降順で並べ替え',
								triggerAsc: '昇順で並べ替え',
								cancelSort: '並べ替え状態を解除',
								emptyText: 'データなし'
							}}
							bordered
							dataSource={companies}
							columns={mergedColumns}
							rowClassName="editable-row"
							pagination={false}
							scroll={{ x: 1024 }}
						// scroll={{ x: 'calc(100vw)' }}
						/>
						<Pagination
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '5px',
							}}
							onChange={(current) => setCurrent(current)}
							total={total}
							current={current}
							pageSize={pageSize}
							defaultCurrent={1}
							defaultPageSize={10}
						/>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default ManageCompaniesScreen;

const validateDataInSheet = async (rows, t) => {
	rows.shift();

	let keys = [];
	let titles = [];

	for (let i = 0; i < rows.length; i++) {
		if (!rows[i][0] || !rows[i][1]) {
			notification.error({
				message: 'ERROR',
				description: t('ManageCompanies.MessageEmptyField', { Row: i + 2 }),
			});
			return false;
		}

		if (!isNaN(rows[i][0]) || !isNaN(rows[i][1])) {
			notification.error({
				message: 'ERROR',
				description: t('ManageCompanies.MessageErrorFormat', { Row: i + 2 }),
			});
			return false;
		}

		if (rows[i][1].length !== 2) {
			notification.error({
				message: 'ERROR',
				description: t('ManageCompanies.MessageFormatKeyCompany', {
					Row: i + 2,
				}),
			});
			return false;
		}

		const isExisted = await checkValidCompanyAPI(rows[i][0], rows[i][1]);

		if (isExisted) {
			notification.error({
				message: 'ERROR',
				description: t('ManageCompanies.MessageValidCompany', { Row: i + 2 }),
			});
			return false;
		}

		titles.push(rows[i][0]);
		keys.push(rows[i][1]);
	}

	function checkDuplicate(arr) {
		let map = {};
		let result = false;
		for (let i = 0; i < arr.length; i++) {
			if (map[arr[i]]) {
				result = true;
				break;
			}
			map[arr[i]] = true;
		}
		return result;
	}

	if (checkDuplicate(titles) || checkDuplicate(keys)) {
		notification.error({
			message: 'ERROR',
			description: t('ManageCompanies.MessageSameField'),
		});
		return false;
	}

	return true;
};

const importDataInSheet = async (rows) => {
	let promises = [];
	for (let i = 0; i < rows.length; i++) {
		promises.push(createCompanyAPI(rows[i][0], rows[i][1]));
	}

	await Promise.all(promises);
};
