import { Badge, Button, Card, Popover, List } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const Setup = ({ device, currentTab }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [visible, setVisible] = useState(false);

	const setup = (device) => {
		history.push({
			pathname: 'setup-device',
			state: { device, editable: true, currentTab },
		});
	};

	const handleClickChange = (visible) => {
		setVisible(visible);
	};

	return (
		<Popover
			content={
				<div>
					<p style={{ textAlign: 'justify' }}>{t('SelectDevice.SetupPopup')}</p>

					<div className="ant-popover-buttons">
						<Button size="small" type="danger" onClick={() => setVisible(!visible)}>
							{t('SelectDevice.Close')}
						</Button>
						<Button size="small" type="primary" onClick={() => setup(device)}>
							{t('SelectDevice.Setup')}
						</Button>
					</div>
				</div>
			}
			visible={visible}
			trigger="click"
			onVisibleChange={(e) => handleClickChange(e)}
		>
			<List.Item>
				<Card hoverable>
					<Meta avatar={<Badge status="error" />} title={device.title} description={t('SelectDevice.Offline')} />
				</Card>
			</List.Item>
		</Popover>
	);
};

export default Setup;
