import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Button, Space, notification, Modal } from 'antd';
import { checkValidMacAddressAPI, confirmRegisterDeviceAPI, removeDeviceAPI } from 'apis/device';
import GroupSelection from 'components/GroupSelection';
import Title from 'components/Title';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const ConfirmScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState(null);
	const [title, setTitle] = useState(null);
	const [mac, setMac] = useState(null);
	const [carId, setCarId] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [areaId, setAreaId] = useState(null);

	const onChangeMac = (mac) => setMac(mac);

	const onChangeAreaId = (areaId) => setAreaId(areaId);

	const onChangeCarId = (carId) => setCarId(carId);

	const edit = async () => {
		setLoading(true);

		if (!mac) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: 'MACアドレスを入力してください。',
			});
			return;
		}

		const expression = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
		const regex = new RegExp(expression);
		const isFormatMac = mac.match(regex);
		if (!isFormatMac) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('RegisterDevice.MessageFormatMac'),
			});
			return;
		}

		if (mac !== history.location.state.device.mac) {
			const isValidMac = await checkValidMacAddressAPI(mac);

			if (isValidMac) {
				setLoading(false);
				notification.error({
					message: t('Error'),
					description: t('RegisterDevice.MessageInvalidMac'),
				});
				return;
			}
		}

		const args = {
			id,
			mac,
			companyId: companyId,
			areaId: areaId,
			carId: carId,
		};

		confirmRegisterDeviceAPI(args).then(({ message }) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('RegisterDeviceConfirm.MessageSuccess'),
					});
					history.push('manage-devices');
					break;
			}
		});
	};

	const remove = async () => confirm();

	function confirm() {
		Modal.confirm({
			title: 'この車載機器の登録をキャンセルしますか。',
			icon: <ExclamationCircleOutlined />,
			content: '登録をキャンセルした場合、車載機器の情報が削除されます。',
			okText: 'はい',
			cancelText: 'いいえ',
			onOk() {
				removeDeviceAPI(id).then((message) => {
					switch (message) {
						case 'ERROR':
							notification.error({
								message: t('Error'),
								description: '車載機器登録のキャンセルが失敗しました。',
							});
							break;
						default:
							notification.success({
								message: t('Success'),
								description: '車載機器登録がキャンセルされました。',
							});
							history.goBack();
							break;
					}
				});
			},
		});
	}

	useEffect(() => {
		const { device } = history.location.state;
		setId(device.id);
		setTitle(device.title);
		setCarId(device.carId);
		setCompanyId(device.companyId);
		setAreaId(device.areaId);
		setMac(device.mac);
	}, [history.location.state]);

	return (
		<>
			<Title level={3} title={t('RegisterDeviceConfirm.Title')} />
			<Row type="flex" justify="center" align="middle">
				<Col sm={{ span: 10, offset: 0 }} lg={{ span: 9, offset: 0 }}>
					<div className="title --required">{t('RegisterDeviceConfirm.DeviceIdLabel')}:</div>
					<Input value={title} size="large" placeholder={'3D:F2:C9:A6:B3:4F'} required />

					<div className="title --required">{t('RegisterDeviceConfirm.MacLabel')}:</div>
					<Input
						value={mac}
						size="large"
						onChange={(e) => onChangeMac(e.target.value)}
						placeholder={'3D:F2:C9:A6:B3:4F'}
						required
					/>

					<div className="title --required">{t('RegisterDeviceConfirm.CompanyLabel')}:</div>
					<GroupSelection size="large" style={{ width: '100%' }} type={'company'} value={companyId} required disabled />

					<div className="title --required">{t('RegisterDeviceConfirm.ProvinceLabel')}:</div>
					<GroupSelection
						size="large"
						style={{ width: '100%' }}
						type={'area'}
						value={areaId}
						required
						onChange={(e) => onChangeAreaId(e)}
					/>

					<div className="title --required">{t('RegisterDeviceConfirm.CarLabel')}:</div>
					<GroupSelection
						size="large"
						style={{ width: '100%' }}
						type={'car'}
						mode={'id'}
						value={carId}
						onChange={(e) => onChangeCarId(e)}
						required
					/>

					<Space style={{ float: 'right', marginTop: '5px' }}>
						<Button shape="round" className="button-optional" type="danger" onClick={() => remove()}>
							{t('RegisterDeviceConfirm.Remove')}
						</Button>
						<Button loading={loading} shape="round" className="button-optional" type="primary" onClick={() => edit()}>
							{t('RegisterDeviceConfirm.Edit')}
						</Button>
					</Space>
				</Col>
			</Row>
		</>
	);
};

export default ConfirmScreen;
