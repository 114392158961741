/* eslint-disable no-unused-vars */
import {
	Col,
	Row,
	Input,
	Table,
	Button,
	Select,
	Image,
	Collapse,
	DatePicker,
	Space,
	ConfigProvider,
	Pagination,
	Empty,
} from 'antd';

import moment from 'moment';
import jaJP from 'antd/es/locale/ja_JP';
import { CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import Title from 'components/Title';
import { colors, tags, lights, types, tagModes, forbiddens, rejects } from 'constants/options';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isOkStatus, transformColor, transformLight } from 'utils/transform';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import { getHistoriesAPI } from 'apis/history';
import MapModule from 'components/MapModule';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const ViewItemScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [histories, setHistories] = useState([]);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [time, setTime] = useState([]);
	const [visible, setVisible] = useState(false);

	const [id, setId] = useState(null);
	const [title, setTitle] = useState(null);
	const [location, setLocation] = useState(null);
	const [type, setType] = useState(null);
	const [forbidden, setForbidden] = useState(null);
	const [reject, setReject] = useState(null);
	const [color, setColor] = useState(null);
	const [light, setLight] = useState(null);
	const [tag, setTag] = useState(null);
	const [tagMode, setTagMode] = useState(null);
	const [image, setImage] = useState(null);
	const [taggedAt, setTaggedAt] = useState(null);
	const [version, setVersion] = useState(null);
	const [checkedAt, setCheckedAt] = useState(null);
	const [editedAt, setEditedAt] = useState(null);
	const [note, setNote] = useState(null);

	const [tagged, setTagged] = useState(null);
	const [edited, setEdited] = useState(null);
	const [device, setDevice] = useState(null);

	const [item, setItem] = useState(null);

	useEffect(() => {
		__setData(history.location.state.row);
	}, [history.location.state.row]);

	const __setData = (item) => {
		setItem(item);

		setId(item.id);
		setTitle(item.title);
		setLocation(item.location);
		setType(item.type);

		switch (item.flag) {
			case 'AUTOMATIC':
				setForbidden(false);
				setReject(false);
				break;
			case 'FORBIDDEN':
				setForbidden(true);
				setReject(false);
				break;
			case 'REJECT':
				setForbidden(false);
				setReject(true);
				break;
			default:
				setForbidden(false);
				setReject(false);
				break;
		}

		setColor(item.color);
		setLight(item.light);
		setTagMode(item.tagMode);
		setTaggedAt(item.taggedAt);
		setVersion(item.version);
		setTag(item.tag);
		setImage(item.image);
		setVersion(item.version);
		setEditedAt(item.editedAt);
		setCheckedAt(item.checkedAt);
		setNote(item.note);

		setTagged(item.tagged);
		setEdited(item.edited);
		setDevice(item.device.title);
	};

	const getLocation = () => {
		setVisible(!visible);
	};

	const editItem = () => {
		history.push({
			pathname: 'edit-detail-item',
			state: {
				item: history.location.state.row,
			},
		});
	};

	useEffect(() => {
		const id = history.location.state.row.id;
		setLoading(true);
		getHistoriesAPI(id, (current - 1) * limit, limit, time).then(({ result }) => {
			setLoading(false);
			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;
				setTotal(count);
				setPageSize(limit);
				let histories = [];
				rows.forEach((row, index) => {
					let { changedFields, editedAt, edited, image, light, color, title } = row;

					changedFields = JSON.parse(changedFields);

					histories.push({
						id: (current - 1) * limit + index + 1,
						title: title,
						changedFields,
						status: light === 'No Error' && color === 'Discolor',
						editedAt,
						editedBy: edited,
						image,
						item: row,
					});
				});
				setHistories(histories);
			}
		});
	}, [current, limit, history.location.state.row.id, total, time]);

	const columns = [
		{
			title: t('No'),
			dataIndex: 'id',
		},
		{
			title: t('ItemID'),
			dataIndex: 'title',
		},
		{
			title: t('Fields'),
			dataIndex: 'changedFields',
			render: (changedFields) => {
				return (
					<div>
						{changedFields.map((changedField) => {
							return changedField.field === 'color' ? (
								<p style={{ textAlign: 'justify' }}>{t('Color')}</p>
							) : (
								<p style={{ textAlign: 'justify' }}>{t('Light')}</p>
							);
						})}
					</div>
				);
			},
		},
		{
			title: t('Info'),
			dataIndex: 'changedFields',
			render: (changedFields) => (
				<div>
					{changedFields.map((changedField) => {
						return changedField.field === 'color' ? (
							<p style={{ textAlign: 'justify' }}>{transformColor(changedField.before)}</p>
						) : (
							<p style={{ textAlign: 'justify' }}>{transformLight(changedField.before)}</p>
						);
					})}
				</div>
			),
		},
		{
			title: t('Status'),
			dataIndex: 'status',
			render: (status) => (status ? '合格' : '不合格'),
		},
		{
			title: t('EditedAt'),
			dataIndex: 'editedAt',
			render: (editedAt) => {
				return moment(editedAt).format('LLLL');
			},
		},
		{
			title: t('EditedBy'),
			dataIndex: 'editedBy',
			render: (editedBy) => editedBy,
		},
		{
			title: 'オプション',
			dataIndex: 'item',
			render: (item) => <Link to={{ pathname: 'history', state: { item: item } }}>詳細</Link>,
		},
	];

	const onChangeTime = (value) => {
		if (value) {
			setTime([moment(value[0], 'YYYY-MM-DD HH:mm:ss'), moment(value[1], 'YYYY-MM-DD HH:mm:ss')]);
		} else setTime([]);
	};

	return (
		<>
			<Title level={3} title={t('DetailItem.Title')} />
			<Row>
				<Col span={12} offset={0}>
					<div className="title">{t('DetailItem.ItemIdLable')}:</div>
					<Input
						readOnly={true}
						size="large"
						value={title}
						disabled
						addonAfter={<EnvironmentOutlined onClick={() => setVisible(!visible)} />}
					/>

					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={12}>
							<div className="title">{t('Longitude')}:</div>
							<Input readOnly={true} size="large" value={location?.coordinates[0]} disabled />
						</Col>
						<Col span={12}>
							<div className="title">{t('Latitude')}:</div>
							<Input disabled value={location?.coordinates[1]} readOnly={true} size="large" />
						</Col>
					</Row>

					<div className="title">{t('Status')}:</div>
					<Input size="large" value={isOkStatus(color, light) ? t('Normal') : t('No Normal')} disabled />

					<div className="title">{t('Type')}:</div>
					<Select
						disabled
						value={type}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
						defaultValue="Others"
					>
						{types.map((type) => (
							<Select.Option value={type.value} key={type.value}>{type.label}</Select.Option>
						))}
					</Select>

					<div className="title">検査禁止フラグ:</div>
					<Select
						value={forbidden}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
						disabled
					>
						{forbiddens.map((forbidden) => (
							<Select.Option value={forbidden.value} key={forbidden.value}>{forbidden.label}</Select.Option>
						))}
					</Select>

					<div className="title">認識結果不採用フラグ:</div>
					<Select
						value={reject}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
						disabled
					>
						{rejects.map((reject) => (
							<Select.Option value={reject.value} key={reject.value}>{reject.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('Color')}:</div>
					<Select
						disabled
						value={color}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{colors.map((color) => (
							<Select.Option value={color.value} key={color.value}>{color.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('Light')}:</div>
					<Select
						disabled
						value={light}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{lights.map((light) => (
							<Select.Option value={light.value} key={light.value}>{light.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('TagMode')}:</div>
					<Select
						disabled
						value={tagMode}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{tagModes.map((mode) => (
							<Select.Option value={mode.value} key={mode.value}>{mode.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('Tag')}:</div>
					<Select
						disabled
						value={tag}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{tags.map((tag) => (
							<Select.Option value={tag.value} key={tag.value}>{tag.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('TaggedAt')}:</div>
					<Input readOnly={true} size="large" value={taggedAt && moment(taggedAt).format('LLLL')} disabled />

					<div className="title">{t('TaggedBy')}:</div>
					<Input size="large" value={tagged} disabled />

					<div className="title">{t('AiVersion')}:</div>
					<Input size="large" value={version} disabled />

					<div className="title">{t('DeviceId')}:</div>
					<Input size="large" value={device} disabled />

					<div className="title">{t('CheckedAt')}:</div>
					<Input value={checkedAt && moment(checkedAt).format('LLLL')} disabled size="large" />

					<div className="title">{t('EditedBy')}:</div>
					<Input size="large" value={edited} disabled />

					<div className="title">{t('EditedAt')}:</div>
					<Input value={editedAt && moment(editedAt).format('LLLL')} disabled size="large" />
				</Col>

				<Col span={11} offset={1}>
					<div className="title">{t('Image')}:</div>
					{image ? (
						<Image src={REACT_APP_API_ENDPOINT + image} style={{ width: '100% !important' }} />
					) : (
						<Empty description={false} />
					)}
				</Col>

				<Col span={24}>
					<div className="title">{t('Note')}:</div>
					<Input.TextArea disabled className="ant-input ant-input-lg" rows={6} value={note} />
				</Col>

				<Col span={24}>
					<Row style={{ marginTop: '10px' }}>
						<Col span={24}>
							<Collapse
								bordered={false}
								expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
								className="site-collapse-custom-collapse"
							>
								<Panel header={t('History')} key="1" className="site-collapse-custom-panel">
									<Row type="flex" justify="end" align="middle">
										<Col>
											<span>{t('RangeTimeHistory')}: </span>
											<ConfigProvider locale={jaJP}>
												<RangePicker
													value={time}
													showTime={{
														hideDisabledOptions: true,
														defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
													}}
													format="YYYY-MM-DD HH:mm:ss"
													onChange={(event) => onChangeTime(event)}
												/>
											</ConfigProvider>
										</Col>
									</Row>

									<Table
										locale={{
											triggerDesc: '降順で並べ替え',
											triggerAsc: '昇順で並べ替え',
											cancelSort: '並べ替え状態を解除',
											emptyText: 'データなし'
										}}
										loading={loading}
										columns={columns}
										dataSource={histories}
										pagination={false}
										// scroll={{ x: 1024 }}
										scroll={{ x: 'calc(100vw)' }}
									/>

									<Pagination
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											marginTop: '5px',
											marginBottom: '5px',
										}}
										onChange={(current) => setCurrent(current)}
										total={total}
										current={current}
										pageSize={pageSize}
									/>
								</Panel>
							</Collapse>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row style={{ marginTop: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Row justify="center">
						<Space>
							<Button
								shape="round"
								className="button-optional"
								type="danger"
								size="large"
								onClick={() => history.goBack()}
							>
								{t('DetailItem.Cancel')}
							</Button>
							<Button shape="round" className="button-optional" type="primary" size="large" onClick={() => editItem()}>
								{t('DetailItem.Edit')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>

			<MapModule
				visible={visible}
				setVisible={setVisible}
				center={location}
				getLocation={getLocation}
				mode={'view'}
				item={item}
			/>
		</>
	);
};

export default ViewItemScreen;
