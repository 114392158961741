import React, { memo, useState } from 'react';
import { Form, Input, Button, Row, Col, Space, Typography, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import './index.css';
import { saveItems } from 'utils/localStorage';
import { useTranslation } from 'react-i18next';
import { signInAPI } from 'apis/auth';
import {checkExistedDeviceAPI} from "../../apis/device";

const { Title } = Typography;

const SigninScreen = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const onFinish = (values) => {
		const { username, password } = values;
		setLoading(true);
		signInAPI(username, password).then(async ({ message, user }) => {
			setLoading(false);
			switch (message) {
				case 'INCORRECT_USERNAME_OR_PASSWORD':
					notification.error({
						message: t('Error'),
						description: t('SignIn.ErrorPasswordOrUserName'),
					});
					break;
				case 'PENDING_ACCOUNT':
					notification.error({
						message: t('Error'),
						description: t('SignIn.MessagePendingAccount'),
					});
					break;
				case 'BLOCKED_ACCOUNT':
					notification.error({
						message: t('Error'),
						description: t('SignIn.MessageBlockedAccount'),
					});
					break;
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				default:
					saveItems([{ item: 'session', data: user }]);
					checkExistedDeviceAPI().then((isValid) => {
						redirect(isValid);
					});
					break;
			}
		});
	};

	const redirect = (isValidDeviceInSystem) => {
		if(isValidDeviceInSystem) {
			history.push('/app/select-device');
		} else{
			history.push('/app/manage-devices');
		}
	};

	return (
		<>
			<Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
				<Col sm={{ span: 14, offset: 0 }} lg={{ span: 8, offset: 0 }}>
					<Row justify="center">
						<Typography>
							<Title level={2}>{t('SignIn.Title')}</Title>
						</Typography>
					</Row>

					<Form
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						layout={'vertical'}
						size={'large'}
					>
						<Form.Item
							label={t('SignIn.UsernameLabel')}
							name="username"
							rules={[
								{
									required: true,
									message: t('SignIn.MessageEmptyUserName'),
								},
							]}
						>
							<Input prefix={<UserOutlined className="site-form-item-icon" />} />
						</Form.Item>

						<Form.Item
							label={t('SignIn.PasswordLabel')}
							name="password"
							rules={[
								{
									required: true,
									message: t('SignIn.MessageEmptyPassword'),
								},
							]}
						>
							<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
						</Form.Item>

						<Form.Item>
							<Link to="forgot-password" className="login-form-forgot">
								{t('SignIn.LabelForgotPassword')}
							</Link>
						</Form.Item>

						<Form.Item
							wrapperCol={{
								span: 16,
							}}
							style={{ float: 'right' }}
						>
							<Space>
								<Link to="sign-up">
									<Button className="button-optional" type="ghost" shape="round">
										{t('SignIn.SignUp')}
									</Button>
								</Link>

								<Button shape="round" className="button-optional" type="primary" htmlType="submit" loading={loading}>
									{t('SignIn.SignIn')}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default memo(SigninScreen);
