import api from './api';

export const getHistoriesAPI = (itemId, offset, limit, time) => {
	const params = {
		itemId,
		time: JSON.stringify(time),
		offset,
		limit,
	};
	return api
		.get('history', { params })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};
