import api from './api';
import { getItems } from 'utils/localStorage';

export const getItemsByBoundsAPI = (neCorner, swCorner) => {
	const params = {
		neCorner,
		swCorner,
	};

	return api
		.get('item/bounds', { params })
		.then((response) => {
			return response.data.items;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};

export const getItemsNearCenterAPI = (center) => {
	const params = {
		center,
	};

	return api
		.get('item/center', { params })
		.then((response) => {
			return response.data.items;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};

export const editItemAPI = (args) => {
	return api
		.post('item/edit', args)
		.then((response) => {
			return {
				message: response.data.message,
				item: response.data?.item,
			};
		})
		.catch((response) => {
			console.log(response);
			return {
				message: 'ERROR',
			};
		});
};

export const createItemAPI = (args) => {
	return api
		.post('item/create', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const getLastItemAPI = () => {
	return api
		.get('item/last')
		.then((response) => {
			return response.data.item;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};

export const getItemByKeywordAPI = (keyword) => {
	const { session } = getItems(['session']);
	const params = {
		keyword,
		companyId: session.userInfo.companyId,
	};

	return api
		.get('item/search', { params })
		.then((response) => {
			return response.data.item;
		})
		.catch((response) => {
			console.log(response);
			return null;
		});
};

export const getItemsManagementAPI = (args) => {
	return api
		.post('item/manage', args)
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};

export const editDetailItemAPI = (args) => {
	return api
		.post('item/edit/detail', args)
		.then((response) => {
			return {
				message: response.data.message,
				item: response.data?.item,
			};
		})
		.catch((response) => {
			console.log(response);
			return {
				message: 'ERROR',
			};
		});
};
