import {
	BankOutlined,
	CarOutlined,
	ExclamationCircleOutlined,
	FormOutlined,
	HddOutlined,
	LogoutOutlined,
	SearchOutlined,
	TeamOutlined,
	UnorderedListOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons';
import { Menu, Layout, Modal } from 'antd';
import './index.css';
import 'antd/dist/antd.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { removeItems } from 'utils/localStorage';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { signOutAPI } from 'apis/auth';

const { Sider } = Layout;

const SideBar = ({ collapsed, role }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const [key, setKey] = useState(null);

	const signOut = () => {
		Modal.confirm({
			title: "ログアウトしますか",
			icon: <ExclamationCircleOutlined />,
			content: "セッションが終了します",
			cancelText: "キャンセル",
			okText: "ログアウト",
			onOk() {
				signOutAPI().then(() => {
					console.log("OK")
					removeItems(['session', 'device', 'image', 'width', 'height', 'items', 'checkedAt', 'version']);
					history.push('/sign-in');
				});
			},
			onCancel() {
				console.log("Cancel")
			},
		})
	}

	useEffect(() => {
		const { pathname } = location;
		setKey(pathname);
	}, [location]);

	const isAdmin = () => {
		return role === 'admin';
	};

	return (
		<Sider width={250} trigger={null} collapsible collapsed={collapsed}>
			<div className="logo" />
			<Menu theme="dark" mode="inline" selectedKeys={[key]}>
				<Menu.Item key="/app/select-device" icon={<UnorderedListOutlined />}>
					<Link to="/app/select-device">{t('SiteBar.SelectDevice')} </Link>
				</Menu.Item>

				<Menu.Item key="/app/monitor" icon={<VideoCameraOutlined />}>
					<Link to="/app/monitor">{t('SiteBar.Monitor')} </Link>
				</Menu.Item>

				<Menu.Item key="/app/manage-devices" icon={<FormOutlined />}>
					<Link to="/app/manage-devices">{t('SiteBar.ManageDevices')} </Link>
				</Menu.Item>

				<Menu.Item key="/app/view-item-in-map" icon={<SearchOutlined />}>
					<Link to="/app/view-item-in-map">{t('SiteBar.ViewInMap')} </Link>
				</Menu.Item>

				{isAdmin() && (
					<>
						<Menu.Item key="/app/manage-items" icon={<HddOutlined />}>
							<Link to="/app/manage-items">{t('SiteBar.ManageItems')}</Link>
						</Menu.Item>
						<Menu.Item key="/app/manage-accounts" icon={<TeamOutlined />}>
							<Link to="/app/manage-accounts">{t('SiteBar.ManageAccounts')}</Link>
						</Menu.Item>
						<Menu.Item key="/app/manage-companies" icon={<BankOutlined />}>
							<Link to="/app/manage-companies">{t('SiteBar.ManageCompanies')}</Link>
						</Menu.Item>
						<Menu.Item key="/app/manage-cars" icon={<CarOutlined />}>
							<Link to="/app/manage-cars">{t('SiteBar.ManageCars')}</Link>
						</Menu.Item>
					</>
				)}

				<Menu.Item key="/app/my-account" icon={<UserOutlined />}>
					<Link to="/app/my-account"> {t('SiteBar.MyAccount')} </Link>
				</Menu.Item>
				<Menu.Item icon={<LogoutOutlined />} onClick={() => signOut()}>
					{t('SiteBar.Logout')}
				</Menu.Item>
			</Menu>
		</Sider>
	);
};

export default SideBar;
