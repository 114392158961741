import { Col, Input, Row, Form, Button, Select, Space, notification, Collapse, Slider } from 'antd';
import { setupDeviceAPI } from 'apis/device';
import Title from 'components/Title';
import { fpses, modes, resolutions } from 'constants/options';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './index.css';

const { Panel } = Collapse;

const SetupDeviceScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [editable, setEditable] = useState(true);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState(null);
	const [id, setId] = useState(null);
	const [mode, setMode] = useState(null);
	const [resolution, setResolution] = useState(null);
	const [fps, setFps] = useState(null);
	const [brightness, setBrightness] = useState(null);
	const [contrast, setContrast] = useState(null);
	const [hue, setHue] = useState(null);
	const [saturation, setSaturation] = useState(null);
	const [gamma, setGamma] = useState(null);
	const [sharpness, setSharpness] = useState(null);
	const [whiteBalance, setWhiteBalance] = useState(null);
	const [exposure, setExposure] = useState(null);
	const [gain, setGain] = useState(null);
	const [previousTab, setPreviousTab] = useState(null);

	const onChangeMode = (mode) => setMode(mode);

	const onChangeResolution = (resolution) => setResolution(resolution);

	const onChangeFps = (fps) => setFps(fps);

	const onChangeBrightness = (brightness) => setBrightness(brightness);

	const onChangeContrast = (contrast) => setContrast(contrast);

	const onChangeHue = (hue) => setHue(hue);

	const onChangeSaturation = (saturation) => setSaturation(saturation);

	const onChangeGamma = (gamma) => setGamma(gamma);

	const onChangeSharpness = (sharpness) => setSharpness(sharpness);

	const onChangeWhiteBalance = (whiteBalance) => setWhiteBalance(whiteBalance);

	const onChangeExposure = (exposure) => setExposure(exposure);

	const onChangeGain = (gain) => setGain(gain);

	useEffect(() => {
		const { editable, device, currentTab } = history.location.state;
		setPreviousTab(currentTab);
		setId(device.id);
		setTitle(device.title);
		setMode(device.mode);
		setResolution(device.resolution ? device.resolution : 'HD1080');
		setFps(device.fps ? device.fps : '30');
		setBrightness(device.brightness);
		setContrast(device.contrast);
		setHue(device.hue);
		setSaturation(device.saturation);
		setGamma(device.gamma);
		setSharpness(device.sharpness);
		setWhiteBalance(device.whiteBalance);
		setExposure(device.exposure);
		setGain(device.gain);
		setEditable(editable);
	}, [history.location.state]);

	const setup = () => {
		setLoading(true);

		if (!resolution) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: '解像度を選択してください。',
			});
			return;
		}

		if (!fps) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: 'FPSを選択してください。',
			});
			return;
		}

		const args = {
			id,
			mode,
			resolution,
			fps,
			brightness,
			contrast,
			hue,
			saturation,
			gamma,
			sharpness,
			whiteBalance,
			exposure,
			gain,
		};

		setupDeviceAPI(args).then((message) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'NOT_FOUND_DEVICE':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'NOT_CHANGE_DEVICE_ONLINE':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('SetupDevice.MessageSuccess'),
					});
					history.push({
						pathname: 'select-device',
					});
			}
		});
	};

	function formatter(value) {
		return `${value}%`;
	}

	return (
		<>
			<Title level={3} title={t('SetupDevice.Title')} />
			<Row type="flex" justify="center" align="middle">
				<Col sm={{ span: 10, offset: 0 }} lg={{ span: 9, offset: 0 }}>
					<Form
						name="basic"
						initialValues={{
							remember: true,
						}}
						layout={'vertical'}
						size={'large'}
					>
						<div className="title" style={{ marginTop: '0' }}>
							{t('SetupDevice.IdLabel')}
						</div>
						<Input readOnly={true} value={title} size="large" disabled />

						<div className="title">{t('SetupDevice.ModeLabel')}</div>
						<Select
							value={mode}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeMode(e)}
						>
							{modes.map((mode) => (
								<Select.Option value={mode.value} key={mode.value}>{mode.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('SetupDevice.ResolutionLabel')}</div>
						<Select
							value={resolution}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeResolution(e)}
							placeholder={resolutions[1].value}
						>
							{resolutions.map((resolution) => (
								<Select.Option value={resolution.value} key={resolution.value}>{resolution.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('SetupDevice.FPSLabel')}</div>
						<Select
							value={fps}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeFps(e)}
							placeholder={fpses[1].value}
						>
							{fpses.map((fps) => (
								<Select.Option value={fps.value} key={fps.value}>{fps.label}</Select.Option>
							))}
						</Select>

						<div className="title"></div>
						<Collapse ghost style={{ paddingLeft: '0px' }}>
							<Panel
								header={
									<span
										style={{
											fontWeight: 'bold',
											color: '#40A9FF',
											padding: '0px !important',
										}}
									>
										ADVANCE
									</span>
								}
								key="1"
								style={{ padding: '0px' }}
							>
								<div>{t('SetupDevice.BrightnessLabel')}</div>
								<Slider
									min={0}
									max={8}
									value={brightness}
									style={{ margin: '5px' }}
									onChange={(e) => onChangeBrightness(e)}
									marks={marks}
								/>

								<div className="title">{t('SetupDevice.ContrastLabel')}</div>
								<Slider
									min={0}
									max={8}
									value={contrast}
									marks={marks}
									style={{ margin: '5px' }}
									onChange={(e) => onChangeContrast(e)}
								/>

								<div className="title">{t('SetupDevice.HueLabel')}</div>
								<Slider
									min={0}
									max={11}
									value={hue}
									style={{ margin: '5px' }}
									marks={marksHue}
									onChange={(e) => onChangeHue(e)}
								/>

								<div className="title">{t('SetupDevice.SaturationLabel')}</div>
								<Slider
									min={0}
									max={8}
									value={saturation}
									style={{ margin: '5px' }}
									marks={marks}
									onChange={(e) => onChangeSaturation(e)}
								/>

								<div className="title">{t('SetupDevice.GammaLabel')}</div>
								<Slider
									min={0}
									max={8}
									value={gamma}
									style={{ margin: '5px' }}
									marks={marks}
									onChange={(e) => onChangeGamma(e)}
								/>

								<div className="title">{t('SetupDevice.SharpnessLabel')}</div>
								<Slider
									min={0}
									max={8}
									value={sharpness}
									style={{ margin: '5px' }}
									marks={marks}
									onChange={(e) => onChangeSharpness(e)}
								/>

								<div className="title">{t('SetupDevice.WhiteBalanceLabel')}</div>
								<Slider
									min={2800}
									max={6500}
									value={whiteBalance}
									style={{ margin: '5px' }}
									onChange={(e) => onChangeWhiteBalance(e)}
								/>

								<div className="title">{t('SetupDevice.ExposureLabel')}</div>
								<Slider
									style={{ margin: '5px' }}
									tipFormatter={formatter}
									value={exposure}
									onChange={(e) => onChangeExposure(e)}
								/>

								<div className="title">{t('SetupDevice.GainLabel')}</div>
								<Slider min={0} max={100} value={gain} style={{ margin: '5px' }} onChange={(e) => onChangeGain(e)} />
							</Panel>
						</Collapse>

						<Space style={{ float: 'right' }}>
							<Button
								shape="round"
								className="button-optional"
								type="danger"
								style={{ marginTop: '5px' }}
								onClick={() => {
									history.push({
										pathname: 'select-device',
										state: { currentTab: previousTab },
									});
								}}
							>
								{t('SetupDevice.Back')}
							</Button>
							{editable && (
								<Button
									onClick={() => setup()}
									loading={loading}
									shape="round"
									className="button-optional"
									type="primary"
									htmlType="submit"
									style={{ marginTop: '5px' }}
								>
									{t('SetupDevice.Save')}
								</Button>
							)}
						</Space>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default SetupDeviceScreen;

const marks = {
	0: '0',
	1: '1',
	2: '2',
	3: '3',
	4: '4',
	5: '5',
	6: '6',
	7: '7',
	8: '8',
};

const marksHue = {
	0: '0',
	1: '1',
	2: '2',
	3: '3',
	4: '4',
	5: '5',
	6: '6',
	7: '7',
	8: '8',
	9: '9',
	10: '10',
	11: '11',
};
