export const types = [
	{ label: '照明', value: 'Lights' },
	{ label: '小標識支柱', value: 'Small Signs' },
	{ label: '大標識支柱', value: 'Signs' },
	{ label: 'その他', value: 'Other' },
];

export const tags = [
	{ label: '腐食', value: 'Rusty' },
	{ label: '落書', value: 'Graffiti' },
	{ label: '公告', value: 'Poster' },
	{ label: 'その他', value: 'Other' },
	{ label: '無', value: 'Unset' },
];

export const colors = [
	{ label: '無', value: 'Discolor' },
	{ label: '有', value: 'Color' },
];

export const lights = [
	{ label: '無', value: 'No Error' },
	{ label: '有', value: 'Error' },
];

export const lightsV2 = [
	{ label: '無', value: 'Unset' },
	{ label: '無', value: 'No Error' },
	{ label: '有', value: 'Error' },
];

export const colorsV2 = [
	{ label: '無', value: 'Unset' },
	{ label: '無', value: 'Discolor' },
	{ label: '有', value: 'Color' },
	{ label: '有', value: 'Rusty' },
	{ label: '有', value: 'Graffiti' },
	{ label: '有', value: 'Poster' },
];

export const tagsV2 = [
	{ label: '腐食', value: 'Rusty' },
	{ label: '落書', value: 'Graffiti' },
	{ label: '公告', value: 'Poster' },
	{ label: 'その他', value: 'Other' },
	{ label: '無', value: 'Unset' },
];

export const flags = [
	{ label: '検査禁止フラグ', value: 'FORBIDDEN' },
	{ label: '変色区分タグ自動設定', value: 'AUTOMATIC' },
	{ label: '認識結果不採用フラグ', value: 'REJECT' },
];

export const forbiddens = [
	{ label: '設定', value: true },
	{ label: '解除', value: false },
];

export const rejects = [
	{ label: '設定', value: true },
	{ label: '解除', value: false },
];

export const modes = [
	{ label: 'モニター', value: 'MONITOR' },
	{ label: '全て', value: 'AUTOMATIC' },
	{ label: '変色', value: 'COLOR' },
	{ label: '照明の消灯', value: 'LIGHT' },
];

export const stereos = [
	{ label: 'Standard', value: 'Standard' },
	{ label: 'Fill', value: 'Fill' },
];

export const resolutions = [
	{ label: 'VGA', value: 'VGA' },
	{ label: 'HD720', value: 'HD720' },
	{ label: 'HD1080', value: 'HD1080' },
	{ label: 'HD2K', value: 'HD2K' },
];

export const fpses = [
	{ label: '15', value: '15' },
	{ label: '30', value: '30' },
	{ label: '60', value: '60' },
	{ label: '100', value: '100' },
];

export const statuses = [
	{ label: '不合格', value: 'Error' },
	{ label: '合格', value: 'No Error' },
];

export const tagModes = [
	{ label: 'AI判断', value: 'AUTOMATIC' },
	{ label: '人判断', value: 'NORMAL' },
];

export const roles = [
	{ label: 'オペレーター', value: 'Operator' },
	{ label: '管理者', value: 'Admin' },
];

export const statusesUser = [
	{ label: '無効', value: 'BLOCKED' },
	{ label: '有効', value: 'NONE' },
];

export const statusesPendingUser = [
	{ label: '有効', value: 'NONE' },
	{ label: 'Pending', value: 'PENDING' },
];
