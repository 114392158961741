/* eslint-disable no-script-url */
import {
	Col,
	Row,
	Typography,
	Input,
	Table,
	Form,
	Popconfirm,
	Select,
	Button,
	Pagination,
	notification,
	Tooltip,
} from 'antd';

import { useCallback, useState } from 'react';

import Title from 'components/Title';
import CreateAccountModal from './components/CreateAccount';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { editAccountAPI, getAccountsManagementAPI } from 'apis/user';
import { getAllCompaniesAPI } from 'apis/company';
import { roles, statusesUser } from 'constants/options';
import { transformCompany, transformRole, transformStatusUser } from 'utils/transform';
import { useHistory } from 'react-router';

const { Search } = Input;

const EditableCell = ({ editing, dataIndex, inputType, children, companies, ...restProps }) => {
	const [inputNode, setInputNode] = useState(null);

	useEffect(() => {
		switch (inputType) {
			case 'company':
				setInputNode(
					<Select>
						{companies.map((company) => {
							return <Select.Option value={company.id} key={company.id}>{company.title}</Select.Option>;
						})}
					</Select>
				);
				break;
			case 'role':
				setInputNode(
					<Select>
						{roles.map((role) => {
							return <Select.Option value={role.value} key={role.value}>{role.label}</Select.Option>;
						})}
					</Select>
				);
				break;
			case 'status':
				setInputNode(
					<Select>
						{statusesUser.map((status) => {
							return <Select.Option value={status.value} key={status.value}>{status.label}</Select.Option>;
						})}
					</Select>
				);
				break;
			default:
				setInputNode(<Input />);
				break;
		}
	}, [companies, inputType]);

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} required>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const ManageAccountsScreen = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [companies, setCompanies] = useState([]);
	const history = useHistory();

	const onSearchKeyword = (value) => {
		if (value !== keyword) {
			setTotal(null);
			setAccounts(null);
			setPageSize(null);
			setCurrent(1);
			setLimit(10);
			setKeyword(value);
		}
	};

	const closeModal = () => setVisible(false);

	const isEditing = (record) => record.key === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.key);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const save = async (record) => {
		try {
			const row = await form.validateFields();
			const newData = [...accounts];
			const index = newData.findIndex((item) => record.key === item.key);

			if (index > -1) {
				const noUpdated =
					row.email === record.email &&
					row.status === record.status &&
					row.role === record.role &&
					row.company === record.company;

				if (noUpdated) {
					notification.error({
						message: t('Error'),
						description: t('MessageNoUpdated'),
					});
					return;
				}

				const args = {
					id: record.id,
					companyId: row.company,
					role: row.role,
					status: row.status,
				};

				const item = newData[index];

				editAccountAPI(args).then((message) => {
					switch (message) {
						case 'ERROR':
							notification.error({
								message: t('Error'),
								description: t('ErrorDescription'),
							});
							break;
						case 'EXISTED_EMAIL':
							notification.error({
								message: t('Error'),
								description: t('Da ton tai dia chi email'),
							});
							break;
						default:
							newData.splice(index, 1, { ...item, ...row });
							setAccounts(newData);
							notification.success({
								message: t('Success'),
								description: t('ManageAccounts.MessageEditAccountSuccess'),
							});
							setEditingKey('');
							__setData();
							break;
					}
				});
			}
		} catch (errInfo) {
			notification.error({
				message: t('Error'),
				description: t('ErrorDescription'),
			});
		}
	};

	const columns = [
		{
			title: t('No'),
			dataIndex: 'key',
			sorter: (a, b) => a.key - b.key,
		},
		{
			title: t('ManageAccounts.UsernameField'),
			dataIndex: 'username',
			sorter: (a, b) => a.username.localeCompare(b.username),
		},
		{
			title: t('ManageAccounts.EmailField'),
			dataIndex: 'email',
			sorter: (a, b) => a.email.localeCompare(b.email),
		},
		{
			title: t('ManageAccounts.CompanyField'),
			dataIndex: 'company',
			editable: true,
			sorter: (a, b) => a.company - b.company,
			render: (company) => {
				return transformCompany(companies, company);
			},
		},
		{
			title: t('ManageAccounts.RoleField'),
			dataIndex: 'role',
			editable: true,
			sorter: (a, b) => a.role.localeCompare(b.role),
			render: (role) => transformRole(role),
		},
		{
			title: t('ManageAccounts.StatusField'),
			dataIndex: 'status',
			editable: true,
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: (status) => transformStatusUser(status),
		},
		{
			title: t('ManageAccounts.OptionField'),
			dataIndex: 'Actions',
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<a
							href="javascript:;"
							onClick={() => save(record)}
							style={{
								marginRight: 8,
							}}
						>
							{t('ManageAccounts.Save')}
						</a>
						<Popconfirm title={t('ConfirmCancel')} onConfirm={cancel} cancelText={'いいえ'} okText={'はい'}>
							<a>{t('Cancel')}</a>
						</Popconfirm>
					</span>
				) : (
					[
						<Typography.Link
							key={"0x"}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
							style={{ marginRight: '20px' }}
						>
							{t('Edit')}
						</Typography.Link>,
					]
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.dataIndex,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				companies,
			}),
		};
	});

	const __setData = useCallback(() => {
		setLoading(true);
		let offset = (current - 1) * limit;
		getAccountsManagementAPI(offset, limit, keyword).then(({ result }) => {
			setLoading(false);
			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;

				setTotal(count);
				setPageSize(limit);
				let accounts = [];

				rows.forEach((row, index) => {
					let { id, username, email, companyId, role, status } = row;
					accounts.push({
						id: id,
						key: (current - 1) * limit + index + 1,
						email,
						username,
						company: companyId,
						role,
						status,
					});
				});

				setAccounts(accounts);
			}
		});
	}, [current, keyword, limit]);

	useEffect(() => {
		__setData();
	}, [__setData]);

	useState(() => {
		getAllCompaniesAPI().then((companies) => setCompanies(companies));
	}, []);

	const viewPendingAccount = () => {
		history.push('pending-accounts');
	};

	return (
		<>
			<Title level={3} title={t('ManageAccounts.Title')} />

			<Row
				style={{ marginBottom: '20px' }}
				type="flex"
				justify="center"
				align="middle"
				gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
			>
				<Col span={6}>
					<Row justify="left">
						<Button
							shape="round"
							type="primary"
							size="large"
							style={{ marginRight: '5px' }}
							onClick={() => setVisible(!visible)}
						>
							{t('ManageAccounts.CreateAccountButton')} <PlusOutlined />
						</Button>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="center">
						<Search enterButton={t('Search')} size="large" onSearch={(e) => onSearchKeyword(e)} />
					</Row>
				</Col>
				<Col span={6}>
					<Row justify="end">
						<Tooltip title="Account pending">
							<Button
								shape="round"
								type="primary"
								size="large"
								style={{ marginRight: '5px' }}
								onClick={() => viewPendingAccount()}
							>
								<UnorderedListOutlined /> 承認待ちアカウント
							</Button>
						</Tooltip>
					</Row>
				</Col>
			</Row>
			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Form form={form} component={false}>
						<Table
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							locale={{
								triggerDesc: '降順で並べ替え',
								triggerAsc: '昇順で並べ替え',
								cancelSort: '並べ替え状態を解除',
								emptyText: 'データなし'
							}}
							bordered
							dataSource={accounts}
							columns={mergedColumns}
							rowClassName="editable-row"
							pagination={false}
							loading={loading}
							scroll={{ x: 1024 }}
						// scroll={{ x: 'calc(100vw)' }}
						/>
						<Pagination
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '5px',
								marginBottom: '5px',
							}}
							onChange={(current) => setCurrent(current)}
							total={total}
							current={current}
							pageSize={pageSize}
							defaultCurrent={1}
							defaultPageSize={10}
						/>
					</Form>
				</Col>
			</Row>
			<CreateAccountModal visible={visible} setVisible={closeModal} loadingData={__setData} />
		</>
	);
};

export default ManageAccountsScreen;
