import React, { memo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { getRole } from 'utils/role';
import { Redirect } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';

const Auth = () => getRole() ? <Redirect to={'app/select-device'} /> : <PublicRoutes />;

const Routes = () => {
	return (
		<Router>
			<Switch>
				<Route path="/app">
					<PrivateRoutes />
				</Route>
				<Route path="">
					<Auth />
				</Route>
			</Switch>
		</Router>
	);
};

const App = () => <Routes />

export default memo(App);
