import { usePagination } from '@umijs/hooks';
import { List } from 'antd';
import { getDevicesAPI } from 'apis/device';
import InitialSetup from '../../Popup/InitialSetup';
import RunningErrorAll from '../../Popup/RunningErrorAll';
import RunningErrorCamera from '../../Popup/RunningErrorCamera';
import RunningErrorGPS from '../../Popup/RunningErrorGPS';
import Setup from '../../Popup/Setup';
import Start from '../../Popup/Start';
import StopErrorAll from '../../Popup/StopErrorAll';
import StopErrorCamera from '../../Popup/StopErrorCamera';
import StopErrorGPS from '../../Popup/StopErrorGPS';
import View from '../../Popup/View';

const AllTabPane = ({ currentTab }) => {
	const { data, loading, pagination, refresh } = usePagination(getDevicesAPI);

	return (
		<>
			<List
				loading={loading}
				grid={{
					gutter: 16,
					xs: 1,
					sm: 1,
					md: 2,
					lg: 3,
					xl: 4,
					xxl: 5,
				}}
				locale={{
					emptyText: 'データなし'
				}}
				dataSource={data}
				pagination={pagination}
				renderItem={(device) => {
					if (!device.status && !device.fps && !device.resolution)
						return <InitialSetup device={device} currentTab={currentTab} />;

					switch (device.status) {
						case 'NONE':
							return <Start device={device} refresh={refresh} currentTab={currentTab} />;
							case 'RUNNING':
								return <View device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
							case 'RUNNING_ERROR_GPS':
								return <RunningErrorGPS device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
							case 'RUNNING_ERROR_CAMERA':
								return <RunningErrorCamera device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
							case 'RUNNING_ERROR_ALL':
								return <RunningErrorAll device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
							case 'STOP_BY_CLIENT':
								return <Start device={device} refresh={refresh} currentTab={currentTab} />;
							case 'STOP_BY_CLIENT_ERROR_GPS':
								return <StopErrorGPS device={device} currentTab={currentTab} />;
							case 'STOP_BY_CLIENT_ERROR_CAMERA':
								return <StopErrorCamera device={device} currentTab={currentTab} />;
							case 'STOP_BY_CLIENT_ERROR_ALL':
								return <StopErrorAll device={device} currentTab={currentTab} />;
						default:
							return <Setup device={device} currentTab={currentTab} />;
					}
				}}
			/>
		</>
	);
};

export default AllTabPane;
