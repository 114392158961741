/* eslint-disable no-unused-vars */
import { CaretRightOutlined } from '@ant-design/icons';
import {
	Button,
	Descriptions,
	Image,
	Space,
	Modal,
	Row,
	Col,
	Collapse,
	Table,
	DatePicker,
	ConfigProvider,
	Pagination,
	Empty,
} from 'antd';
import moment from 'moment';
import jaJP from 'antd/es/locale/ja_JP';
import { useHistory } from 'react-router';
import './index.css';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import {
	isOkStatus,
	transformColor,
	transformFlag,
	transformLight,
	transformLocation,
	transformType,
} from 'utils/transform';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getHistoriesAPI } from 'apis/history';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const Item = (props) => {
	const history = useHistory();
	const { t } = useTranslation();
	const [histories, setHistories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [time, setTime] = useState([]);

	const editItem = () => {
		history.push({
			pathname: `edit-item-map`,
			state: {
				item: props.item,
			},
		});
	};

	const createItem = () => {
		history.push({
			pathname: `create-item-map`,
			state: {
				item: props.item,
			},
		});
	};

	useEffect(() => {
		const id = props.item.id;
		setLoading(true);
		getHistoriesAPI(id, (current - 1) * limit, limit, time).then(({ result }) => {
			setLoading(false);
			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;
				setTotal(count);
				setPageSize(limit);
				let histories = [];
				rows.forEach((row, index) => {
					let { changedFields, editedAt, edited, image, light, color, title } = row;

					changedFields = JSON.parse(changedFields);

					histories.push({
						id: (current - 1) * limit + index + 1,
						title: title,
						changedFields,
						status: light === 'No Error' && color === 'Discolor',
						editedAt,
						edited,
						image,
						item: row,
					});
				});
				setHistories(histories);
			}
		});
	}, [current, limit, props.item.id, time, total]);

	const onChangeTime = (value) => {
		if (value) {
			setTime([moment(value[0], 'YYYY-MM-DD HH:mm:ss'), moment(value[1], 'YYYY-MM-DD HH:mm:ss')]);
		} else setTime([]);
	};

	const columns = [
		{
			title: t('No'),
			dataIndex: 'id',
		},
		{
			title: t('Id'),
			dataIndex: 'title',
		},
		{
			title: t('Fields'),
			dataIndex: 'changedFields',
			render: (changedFields) => (
				<div>
					{changedFields.map((changedField) => {
						return changedField.field === 'color' ? (
							<p style={{ textAlign: 'justify' }}>{t('Color')}</p>
						) : (
							<p style={{ textAlign: 'justify' }}>{t('Light')}</p>
						);
					})}
				</div>
			),
		},
		{
			title: t('Info'),
			dataIndex: 'changedFields',
			render: (changedFields) => (
				<div>
					{changedFields.map((changedField) => {
						return changedField.field === 'color' ? (
							<p style={{ textAlign: 'justify' }}>{transformColor(changedField.before)}</p>
						) : (
							<p style={{ textAlign: 'justify' }}>{transformLight(changedField.before)}</p>
						);
					})}
				</div>
			),
		},
		{
			title: t('Status'),
			dataIndex: 'status',
			render: (status) => (status ? '合格' : '不合格'),
			width: '5%',
		},
		{
			title: t('EditedAt'),
			dataIndex: 'editedAt',
			render: (editedAt) => {
				return moment(editedAt).format('LLLL');
			},
			width: '15%',
		},
		{
			title: t('EditedBy'),
			dataIndex: 'edited',
			render: (edited) => edited,
			width: '15%',
		},
		{
			title: 'オプション',
			dataIndex: 'item',
			render: (item) => <Link to={{ pathname: 'history', state: { item: item } }}>詳細</Link>,
		},
	];

	return (
		<Modal
			width={900}
			visible={props.visible}
			closable={false}
			onCancel={() => props.setVisible()}
			footer={
				<Space>
					<Button shape="round" type="danger" onClick={() => props.setVisible()}>
						キャンセル
					</Button>
					<Button shape="round" type="primary" onClick={() => editItem()}>
						検査結果修正
					</Button>
				</Space>
			}
		>
			<Descriptions
				title={'検査結果'}
				bordered
				column={1}
				extra={
					<Space>
						<Button type="primary" shape="round" onClick={() => createItem()}>
							{t('Monitor.CreateItem')}
						</Button>
					</Space>
				}
			>
				<Descriptions.Item label={t('Id')} span={1}>
					{props.item.title}
				</Descriptions.Item>

				<Descriptions.Item label={t('Type')} span={1}>
					{transformType(props.item.type)}
				</Descriptions.Item>

				<Descriptions.Item label={t('Status')} span={1}>
					{isOkStatus(props.item.color, props.item.light) ? t('Normal') : t('No Normal')}
				</Descriptions.Item>

				<Descriptions.Item label={'位置'} span={4}>
					{transformLocation(props.item.location)}
				</Descriptions.Item>

				<Descriptions.Item label={t('Flag')} span={4}>
					{transformFlag(props.item.flag)}
				</Descriptions.Item>

				<Descriptions.Item label={t('Color')} span={1}>
					{transformColor(props.item.color)}
				</Descriptions.Item>

				<Descriptions.Item label={t('Light')} span={1}>
					{transformLight(props.item.light)}
				</Descriptions.Item>

				<Descriptions.Item label={t('Image')} span={1}>
					{props.item.image ? (
						<Image src={REACT_APP_API_ENDPOINT + props.item.image} style={{ width: '100% !important' }} />
					) : (
						<Empty description={false} />
					)}
				</Descriptions.Item>

				<Descriptions.Item label="AIバージョン" span={1}>
					{props.item.version}
				</Descriptions.Item>

				<Descriptions.Item label={t('DeviceId')} span={1}>
					{props.item.device.title}
				</Descriptions.Item>

				<Descriptions.Item label={t('CheckedAt')} span={1}>
					{moment(props.item.checkedAt).format('LLLL')}
				</Descriptions.Item>

				<Descriptions.Item label={t('EditedBy')} span={1}>
					{props.item.edited}
				</Descriptions.Item>

				<Descriptions.Item label={t('EditedAt')} span={1}>
					{moment(props.item.editedAt).format('LLLL')}
				</Descriptions.Item>
			</Descriptions>

			<Row style={{ marginTop: '10px' }}>
				<Col span={24}>
					<Collapse
						bordered={false}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						className="site-collapse-custom-collapse"
					>
						<Panel header="検査履歴" key="1" className="site-collapse-custom-panel">
							<Row type="flex" justify="end" align="middle">
								<Col>
									<span>{t('RangeTimeHistory')}: </span>
									<ConfigProvider locale={jaJP}>
										<RangePicker
											value={time}
											showTime={{
												hideDisabledOptions: true,
												defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
											}}
											format="YYYY-MM-DD HH:mm:ss"
											onChange={(event) => onChangeTime(event)}
										/>
									</ConfigProvider>
								</Col>
							</Row>

							<Table
								locale={{
									triggerDesc: '降順で並べ替え',
									triggerAsc: '昇順で並べ替え',
									cancelSort: '並べ替え状態を解除',
									emptyText: 'データなし'
								}}
								loading={loading}
								columns={columns}
								dataSource={histories}
								pagination={false}
							/>

							<Pagination
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: '5px',
									marginBottom: '5px',
								}}
								onChange={(current) => setCurrent(current)}
								total={total}
								current={current}
								pageSize={pageSize}
								defaultCurrent={1}
								defaultPageSize={10}
							/>
						</Panel>
					</Collapse>
				</Col>
			</Row>
		</Modal>
	);
};

export default Item;
