import { Badge, Button, Card, Popover, List } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const StopErrorCamera = ({ device, currentTab }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [visible, setVisible] = useState(false);

	const handleClickChange = (visible) => {
		setVisible(visible);
	};

	const setup = (device) => {
		history.push({
			pathname: 'setup-device',
			state: { device, editable: false, currentTab },
		});
	};

	return (
		<Popover
			content={
				<div style={{ width: '300px' }}>
					<p style={{ textAlign: 'justify' }}>
						カメラのエラーが発生しました。
						<br />
						<a onClick={() => setup(device)}>{t('SelectDevice.ViewInfoDevice')}</a>
					</p>

					<div className="ant-popover-buttons">
						<Button type="danger" size="small" onClick={() => setVisible(!visible)}>
							{t('SelectDevice.Close')}
						</Button>
					</div>
				</div>
			}
			visible={visible}
			trigger="click"
			onVisibleChange={(e) => handleClickChange(e)}
		>
			<List.Item>
				<Card hoverable>
					<Meta avatar={<Badge status="warning" />} title={device.title} description={'カメラのエラー'} />
				</Card>
			</List.Item>
		</Popover>
	);
};

export default StopErrorCamera;
