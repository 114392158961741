import React, { Fragment, memo } from 'react';
import { Form, Input, Button, Row, Col, Space, Alert } from 'antd';
import { Typography } from 'antd';
import { useHistory } from 'react-router';
import { useForgotPassword } from 'hooks/useForgotPassword';

const ForgotPasswordScreen = () => {
	const history = useHistory();
	const { email, loading, onFinish } = useForgotPassword()

	return (
		<Fragment>
			<Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
				<Col sm={{ span: 10, offset: 0 }} lg={{ span: 8, offset: 0 }}>
					<Row justify="center">
						<Typography>
							<Typography.Title level={2}>パスワード再発行</Typography.Title>
						</Typography>
					</Row>

					{email && (
						<Alert
							message="成功しました"
							description="パスワードリセットのメールを送信しました。受信ボックスで[パスワードリセット] という件名のメールを確認してください"
							type="success"
						/>
					)}

					<Form
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						layout={'vertical'}
						size={'large'}
					>
						<Form.Item
							label="メールアドレス"
							name="email"
							rules={[
								{
									required: true,
									message: 'メールアドレスを入力してください。',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							wrapperCol={{
								span: 16,
							}}
							style={{ float: 'right', marginTop: '8px' }}
						>
							<Space>
								<Button className="button-optional" type="danger" shape="round" onClick={() => history.goBack()}>
									キャンセル
								</Button>
								<Button shape="round" className="button-optional" type="primary" htmlType="submit" loading={loading}>
									送信
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Fragment>
	);
};

export default memo(ForgotPasswordScreen);
