import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import ForgotPassword from 'screens/ForgotPasswordScreen';
import ResetPassword from 'screens/ResetPasswordScreen';
import Signin from 'screens/SigninScreen';
import Signup from 'screens/SignupScreen';

const PublicRoutes = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/sign-in">
					<Signin />
				</Route>
				<Route path="/sign-up">
					<Signup />
				</Route>
				<Route path="/forgot-password">
					<ForgotPassword />
				</Route>
				<Route path="/reset-password">
					<ResetPassword />
				</Route>
				<Route path="">
					<Signin />
				</Route>
			</Switch>
		</Fragment>
	);
};

export default PublicRoutes;
