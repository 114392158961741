import React, { memo, useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Space, Select, notification } from 'antd';
import { Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { roles } from 'constants/options';
import { getAllCompaniesAPI } from 'apis/company';
import { useTranslation } from 'react-i18next';
import { signUpAPI } from 'apis/auth';
const { Title } = Typography;

const SignupScreen = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		getAllCompaniesAPI().then((companies) => setCompanies(companies));
	}, []);

	const onFinish = (values) => {
		setLoading(true);
		const { username, email, password } = values;

		if (!isUserNameValidation(username)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('Signup.MessageErrorFormatUsername'),
			});
			return;
		}

		if (!isEmailValidation(email)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('Signup.MessageErrorFormatEmail'),
			});
			return;
		}

		if (!isPasswordValidation(password)) {
			setLoading(false);
			notification.error({
				message: t('Error'),
				description: t('Signup.MessageErrorFormatPassword'),
			});
			return;
		}

		const args = {
			username: values.username,
			email: values.email,
			password: values.password,
			companyId: values.company,
			role: values.role,
		};

		signUpAPI(args).then((message) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: 'エラーが発生しました。',
					});
					break;
				case 'USERNAME_ALREADY_EXISTS':
					notification.error({
						message: t('Error'),
						description: 'ユーザー名が既に存在します。',
					});
					break;
				case 'EMAIL_ALREADY_EXISTS':
					notification.error({
						message: t('Error'),
						description: 'メールアドレスが既に存在します。',
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: 'アカウントを作成しました。管理者の承認待ちです。',
					});
					history.push('/sign-in');
					break;
			}
		});
	};

	const isUserNameValidation = (username) => {
		const res = /^[a-zA-Z0-9]+$/.exec(username);
		const valid = !!res;
		return valid;
	};

	const isEmailValidation = (email) => {
		const res =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
				email
			);
		const valid = !!res;
		return valid;
	};

	const isPasswordValidation = (password) => {
		const res = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.exec(password);
		const valid = !!res;
		return valid;
	};

	return (
		<>
			<Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
				<Col sm={{ span: 10, offset: 0 }} lg={{ span: 8, offset: 0 }}>
					<Row justify="center">
						<Typography>
							<Title level={2}>{t('Signup.Title')}</Title>
						</Typography>
					</Row>

					<Form
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						layout={'vertical'}
						size={'large'}
					>
						<Form.Item
							label={t('Signup.UsernameLabel')}
							name="username"
							rules={[
								{
									required: true,
									message: 'アカウント作成を入力してください。',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label={t('Signup.EmailLabel')}
							name="email"
							rules={[
								{
									required: true,
									message: 'メールアドレスを入力してください。',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label={t('Signup.CompanyLabel')}
							name="company"
							rules={[
								{
									required: true,
									message: 'を入力ください所有会社',
								},
							]}
						>
							<Select>
								{companies.map((company) => {
									return <Select.Option value={company.id} key={company.id}>{company.title}</Select.Option>;
								})}
							</Select>
						</Form.Item>

						<Form.Item
							label={t('Signup.RoleLabel')}
							name="role"
							rules={[
								{
									required: true,
									message: '権限を入力してください。',
								},
							]}
						>
							<Select
								className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
								style={{ width: '100%' }}
							>
								{roles.map((role) => (
									<Select.Option value={role.value} key={role.value}>{role.label}</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							label={t('Signup.PasswordLabel')}
							name="password"
							rules={[
								{
									required: true,
									message: 'パスワードを入力してください。',
								},
							]}
						>
							<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
						</Form.Item>

						<Form.Item
							wrapperCol={{
								span: 16,
							}}
							style={{ float: 'right', marginTop: '8px' }}
						>
							<Space>
								<Button className="button-optional" type="danger" shape="round" onClick={() => history.goBack()}>
									{t('Signup.Back')}
								</Button>
								<Button shape="round" className="button-optional" type="primary" htmlType="submit" loading={loading}>
									{t('Signup.Signup')}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default memo(SignupScreen);
