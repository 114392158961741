import { Col, Row, Form, Input, Spin } from 'antd';
import { getInfoMyAccountAPI } from 'apis/user';
import Title from 'components/Title';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { transformRole } from 'utils/transform';

const MyAccountScreen = () => {
	const { t } = useTranslation();
	const [username, setUsername] = useState(null);
	const [email, setEmail] = useState(null);
	const [role, setRole] = useState(null);
	const [company, setCompany] = useState(null);
	const [spinning, setSpinning] = useState(false);

	useEffect(() => {
		setSpinning(true);
		getInfoMyAccountAPI().then((user) => {
			setSpinning(false);
			if (user) {
				setUsername(user.username);
				setEmail(user.email);
				setRole(user.role);
				setCompany(user.company);
			}
		});
	}, []);

	return (
		<>
			<Title level={3} title={t('MyAccount.Title')} />
			<Spin spinning={spinning}>
				{' '}
				<Row type="flex" justify="center" align="middle">
					<Col sm={{ span: 10, offset: 0 }} lg={{ span: 9, offset: 0 }}>
						<Form
							name="basic"
							initialValues={{
								remember: true,
							}}
							layout={'vertical'}
							size={'large'}
						>
							<Form.Item label={t('MyAccount.UsernameLabel')} name="username">
								<Input disabled placeholder={username} />
							</Form.Item>

							<Form.Item label={t('MyAccount.EmailLabel')} name="email">
								<Input disabled placeholder={email} />
							</Form.Item>

							<Form.Item label={t('MyAccount.CompanyLabel')} name="company">
								<Input disabled placeholder={company} />
							</Form.Item>

							<Form.Item label={t('MyAccount.RoleLabel')} name="role">
								<Input disabled placeholder={transformRole(role)} />
							</Form.Item>

							<Form.Item>
								<Link to="/app/change-password" className="login-form-forgot">
									{t('MyAccount.RepasswordLink')}
								</Link>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</>
	);
};

export default MyAccountScreen;
