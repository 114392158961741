import { colors, colorsV2, flags, lights, lightsV2, modes, stereos, tags, tagsV2, types } from 'constants/options';

export const isOkStatus = (color, light) => {
	return color === 'Discolor' && light === 'No Error';
};

export const transformRole = (role) => {
	return role === 'Admin' ? '管理者' : 'オペレーター';
};

export const transformStatusUser = (status) => {
	if (status === 'NONE') return '有効';
	if (status === 'PENDING') return 'PENDING';
	if (status === 'BLOCKED') return '無効';
	return 'PENDING';
};

export const transformCompany = (companies, company) => {
	for (let index = 0; index < companies.length; index++) {
		if (companies[index].id === company) return companies[index].title;
	}
	return null;
};

export const transformCars = (cars, car) => {
	for (let index = 0; index < cars.length; index++) {
		if (cars[index].id === car) return cars[index].title;
	}
	return null;
};

export const transformAreas = (areas, area) => {
	for (let index = 0; index < areas.length; index++) {
		if (areas[index].id === area) return areas[index].province + areas[index].county;
	}
	return null;
};

export const transformStatusDevice = (status) => {
	if (status === 'NONE' && status === 'STOP_BY_CLIENT') return 'オンライン';
	return 'オフライン';
};

export const transformType = (type) => {
	const result = types.find((element) => element.value === type);
	return result?.label;
};

export const transformMode = (mode) => {
	const result = modes.find((element) => element.value === mode);
	return result?.label;
};

export const transformStereoMode = (mode) => {
	const result = stereos.find((element) => element.value === mode);
	return result?.label;
};

export const transformColor = (color) => {
	const result = colors.find((element) => element.value === color);
	return result?.label;
};

export const transformLight = (light) => {
	const result = lights.find((element) => element.value === light);
	return result?.label;
};

export const transformFlag = (flag) => {
	const result = flags.find((element) => element.value === flag);
	return result?.label;
};

export const transformTag = (tag) => {
	const result = tags.find((element) => element.value === tag);
	return result?.label;
};

export const transformColorV2 = (color) => {
	const result = colorsV2.find((element) => element.value === color);
	return result?.label;
};

export const transformLightV2 = (light) => {
	const result = lightsV2.find((element) => element.value === light);
	return result?.label;
};

export const transformTagV2 = (tag) => {
	const result = tagsV2.find((element) => element.value === tag);
	return result?.label;
};

export const isOkStatusV2 = (color, light) => {
	return (
		(color === 'Discolor' && light === 'No Error') ||
		(color === 'Discolor' && light === 'Unset') ||
		(color === 'Unset' && light === 'No Error')
	);
};

export const transformLocation = (location) => {
	return convertDMS(location.coordinates[0], location.coordinates[1]);
};

export const transformLocationV2 = (longitude, latitude) => {
	return convertDMS(longitude, latitude);
};

function toDegreesMinutesAndSeconds(coordinate) {
	var absolute = Math.abs(coordinate);
	var degrees = Math.floor(absolute);
	var minutesNotTruncated = (absolute - degrees) * 60;
	var minutes = Math.floor(minutesNotTruncated);
	var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

	return degrees + '°' + minutes + "'" + seconds + '"';
}

function convertDMS(lng, lat) {
	var latitude = toDegreesMinutesAndSeconds(lat);
	var latitudeCardinal = lat >= 0 ? 'N' : 'S';

	var longitude = toDegreesMinutesAndSeconds(lng);
	var longitudeCardinal = lng >= 0 ? 'E' : 'W';

	return latitude + ' ' + latitudeCardinal + '\n' + longitude + ' ' + longitudeCardinal;
}
