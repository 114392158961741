import { List } from 'antd';
import { getDevicesOnlineAPI } from 'apis/device';
import View from '../../Popup/View';
import Start from '../../Popup/Start';
import { usePagination } from '@umijs/hooks';
import RunningErrorGPS from '../../Popup/RunningErrorGPS';
import RunningErrorCamera from '../../Popup/RunningErrorCamera';
import RunningErrorAll from '../../Popup/RunningErrorAll';
import StopErrorGPS from '../../Popup/StopErrorGPS';
import StopErrorCamera from '../../Popup/StopErrorCamera';
import StopErrorAll from '../../Popup/StopErrorAll';

const OnlineTabPane = ({ currentTab }) => {
	const { data, loading, pagination, refresh } = usePagination(getDevicesOnlineAPI);

	return (
		<>
			<List
				grid={{
					gutter: 16,
					xs: 1,
					sm: 1,
					md: 2,
					lg: 3,
					xl: 4,
					xxl: 5,
				}}
				locale={{
					emptyText: 'データなし'
				}}
				loading={loading}
				pagination={pagination}
				dataSource={data}
				renderItem={(device) => {
					switch (device.status) {
						case 'NONE':
							return <Start device={device} refresh={refresh} currentTab={currentTab} />;
						case 'RUNNING':
							return <View device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'RUNNING_ERROR_GPS':
							return <RunningErrorGPS device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'RUNNING_ERROR_CAMERA':
							return <RunningErrorCamera device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'RUNNING_ERROR_ALL':
							return <RunningErrorAll device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'STOP_BY_CLIENT':
							return <Start device={device} refresh={refresh} currentTab={currentTab} />;
						case 'STOP_BY_CLIENT_ERROR_GPS':
							return <StopErrorGPS device={device} currentTab={currentTab} />;
						case 'STOP_BY_CLIENT_ERROR_CAMERA':
							return <StopErrorCamera device={device} currentTab={currentTab} />;
						case 'STOP_BY_CLIENT_ERROR_ALL':
							return <StopErrorAll device={device} currentTab={currentTab} />;
						default:
							break;
					}
				}}
			/>
		</>
	);
};

export default OnlineTabPane;
