import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ja from '../assets/locales/ja.json';
import en from '../assets/locales/en.json';

const resources = {
	ja: {
		translation: ja,
	},
	en: {
		translation: en,
	},
};

i18n
	.use(I18NextHttpBackend)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'ja',
		debug: true,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
