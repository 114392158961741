import { Col, Input, notification, Row, Spin } from 'antd';
import { getItemByKeywordAPI, getItemsByBoundsAPI } from 'apis/item';
import Title from 'components/Title';
import { MAP_KEY } from 'configs/map';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapContext, { MapProvider } from 'utils/mapContext';
import Item from './Item';
import Map from './map';

const { Search } = Input;

const ItemMap = () => {
	const { t } = useTranslation();
	const mapContext = useContext(MapContext);
	const [item, setItem] = useState(null);
	const [items, setItems] = useState([]);
	const [visible, setVisible] = useState(false);
	const [keyword, setKeyword] = useState(null);
	const [spinning, setSpinning] = useState(false);

	const getItemsByBounds = useCallback(() => {
		const hasBound =
			mapContext.configMap?.center &&
			mapContext.configMap?.neCorner &&
			mapContext.configMap?.swCorner &&
			mapContext.configMap?.zoom >= 15;

		if (hasBound)
			getItemsByBoundsAPI(mapContext.configMap.neCorner, mapContext.configMap.swCorner).then((items) =>
				setItems(items)
			);
	}, [mapContext]);

	useEffect(() => {
		getItemsByBounds();
	}, [getItemsByBounds]);

	const onChangeItem = (item) => {
		setItem(item);
		setVisible(!visible);
	};

	const onChangeSearch = (value) => {
		if (value && value !== keyword) {
			setKeyword(value);
			setSpinning(true);

			getItemByKeywordAPI(value).then((item) => {
				if (!item) {
					notification.error({
						message: t('Error'),
						description: t('ViewInMap.MessageNotFoundItem'),
					});
				} else {
					let config = mapContext.configMap;

					config.center = {
						lat: parseFloat(item.location.coordinates[1]),
						lng: parseFloat(item.location.coordinates[0]),
					};

					mapContext.setConfigMap(config);

					setItems([item]);
				}
				setSpinning(false);
			});
		}
	};

	return (
		<>
			<Title level={3} title={t('ViewInMap.Title')} />
			<Row style={{ marginBottom: '15px' }} type="flex" justify="center" align="middle">
				<Col span={12}>
					<Row justify="center">
						<Search enterButton={t('Search')} size="large" onSearch={(e) => onChangeSearch(e)} />
					</Row>
				</Col>
			</Row>
			<Spin spinning={spinning}>
				<Map
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}`}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `80vh` }} />}
					mapElement={<div style={{ height: `100%` }} />}
					items={items}
					onChangeItem={onChangeItem}
				/>
				{item && <Item visible={visible} setVisible={setVisible} item={item} />}
			</Spin>
		</>
	);
};

const MapViewScreen = () => {
	return (
		<MapProvider>
			<ItemMap />
		</MapProvider>
	);
};

export default MapViewScreen;
