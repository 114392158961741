import { Row, Tabs, Col } from 'antd';
import Title from 'components/Title';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import AllTabPane from './components/Tabs/All';
import { useTranslation } from 'react-i18next';
import OnlineTabPane from './components/Tabs/Online';
import OfflineTabPane from './components/Tabs/Offline';
import MyDevicesTabPane from './components/Tabs/MyDevices';

const { TabPane } = Tabs;

const SelectDeviceScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(
		history.location.state?.currentTab ? history.location.state?.currentTab : 'online'
	);

	const handleTabChange = useCallback(
		(key) => {
			setCurrentTab(key);
		},
		[setCurrentTab]
	);

	return (
		<>
			<Title level={3} title={t('SelectDevice.Title')} />
			<Row type="flex" justify="center" align="middle">
				<Col span={24}>
					<Tabs type="line" onTabClick={handleTabChange} defaultActiveKey={currentTab}>
						<TabPane tab={t('SelectDevice.Online')} key="online" />
						<TabPane tab={t('SelectDevice.Offline')} key="offline" />
						<TabPane tab={t('SelectDevice.All')} key="all" />
						<TabPane tab={t('SelectDevice.MyDevices')} key="my-devices" />
					</Tabs>
					{currentTab === 'online' && <OnlineTabPane currentTab={currentTab} />}
					{currentTab === 'offline' && <OfflineTabPane currentTab={currentTab} />}
					{currentTab === 'all' && <AllTabPane currentTab={currentTab} />}
					{currentTab === 'my-devices' && <MyDevicesTabPane currentTab={currentTab} />}
				</Col>
			</Row>
		</>
	);
};

export default SelectDeviceScreen;
