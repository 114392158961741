import api from './api';
import { getItems } from 'utils/localStorage';

export const signUpAPI = (args) => {
	return api
		.post('auth/sign-up', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const signInAPI = (username, password) => {
	const data = {
		username,
		password,
	};
	return api
		.post('auth/sign-in', data)
		.then((response) => {
			return {
				message: response.data.message,
				user: response.data?.user,
			};
		})
		.catch((response) => {
			console.log(response);
			return { message: 'ERROR' };
		});
};

export const forgotPasswordAPI = (email) => {
	const data = {
		email,
	};
	return api
		.post('auth/forgot-password', data)
		.then((response) => response.data.message)
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const resetPasswordAPI = (token, password) => {
	const data = {
		token,
		password,
	};
	return api
		.post('auth/reset-password', data)
		.then((response) => {
			return {
				message: response.data.message,
			};
		})
		.catch((response) => {
			console.log(response);
			return { message: 'ERROR' };
		});
};

export const signOutAPI = () => {
	const { session } = getItems(['session']);

	const data = {
		refreshToken: session.tokens.refresh.token,
	};

	return api
		.post('auth/sign-out', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};


