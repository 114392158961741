import { REACT_APP_API_ENDPOINT } from 'configs/api';
import React, { useContext, useRef } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps';
import MapContext from 'utils/mapContext';
import { isOkStatus } from 'utils/transform';

import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
const labelSize = { width: 100 };
const labelPadding = 8;

const Markers = (props) => {
	const refMap = useRef();

	const initMap = useRef();

	const mapContext = useContext(MapContext);

	const getBounds = () => {
		const bounds = refMap.current.getBounds();
		const NECorner = bounds.getNorthEast();
		const SWCorner = bounds.getSouthWest();

		mapContext.setConfigMap({
			center: {
				lat: refMap.current.getCenter().lat(),
				lng: refMap.current.getCenter().lng(),
			},
			neCorner: {
				latitude: NECorner.lat(),
				longitude: NECorner.lng(),
			},
			swCorner: {
				latitude: SWCorner.lat(),
				longitude: SWCorner.lng(),
			},
			zoom: refMap.current.getZoom(),
		});
	};

	const icon = (item) =>
		isOkStatus(item.color, item.light)
			? REACT_APP_API_ENDPOINT + 'assets/flags/circle-success.png'
			: REACT_APP_API_ENDPOINT + 'assets/flags/circle-error.png';

	return (
		<GoogleMap
			ref={refMap}
			defaultZoom={mapContext.configMap.zoom}
			center={mapContext.configMap.center}
			defaultCenter={mapContext.configMap.center}
			onDragEnd={() => getBounds()}
			onZoomChanged={() => getBounds()}
			onIdle={() => {
				if (!initMap.current) {
					initMap.current = true;
					getBounds();
				}
			}}
		>
			{props.items.map((item) => {
				return (
					<MarkerWithLabel
						labelStyle={{
							textAlign: 'center',
							width: labelSize.width + 'px',
							backgroundColor: '#5e85a8',
							fontSize: '12px',
							color: 'white',
							fontWeight: 'bold',
							padding: labelPadding + 'px',
						}}
						labelVisible={mapContext.configMap.zoom >= 20}
						labelClass="map-label"
						labelAnchor={{ x: labelSize.width / 2 + labelPadding, y: 80 }}
						key={item.title}
						title={item.title}
						icon={icon(item)}
						position={{
							lat: item.location.coordinates[1],
							lng: item.location.coordinates[0],
						}}
						onClick={() => props.onChangeItem(item)}
					>
						<span>{item.title}</span>
					</MarkerWithLabel>
				);
			})}
		</GoogleMap>
	);
};

export default withScriptjs(withGoogleMap(Markers));
