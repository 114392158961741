import { List } from 'antd';
import { getMyDevicesAPI } from 'apis/device';
import Setup from '../../Popup/Setup';
import { usePagination } from '@umijs/hooks';
import View from '../../Popup/View';
import RunningErrorGPS from '../../Popup/RunningErrorGPS';
import RunningErrorCamera from '../../Popup/RunningErrorCamera';
import RunningErrorAll from '../../Popup/RunningErrorAll';

const MyDevicesTabPane = ({ currentTab }) => {
	const { data, loading, pagination, refresh } = usePagination(getMyDevicesAPI);

	return (
		<>
			<List
				loading={loading}
				grid={{
					gutter: 16,
					xs: 1,
					sm: 1,
					md: 2,
					lg: 3,
					xl: 4,
					xxl: 5,
				}}
				locale={{
					emptyText: 'データなし'
				}}
				dataSource={data}
				pagination={pagination}
				renderItem={(device) => {
					switch (device.status) {
						case 'RUNNING':
							return <View device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'RUNNING_ERROR_GPS':
							return <RunningErrorGPS device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'RUNNING_ERROR_CAMERA':
							return <RunningErrorCamera device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'RUNNING_ERROR_ALL':
							return <RunningErrorAll device={device} refresh={refresh} currentTab={currentTab} status={device.status} />;
						case 'STOP_BY_CODE':
							return <Setup device={device} refresh={refresh} currentTab={currentTab} />;
						default:
							break;
					}
				}}
			/>
		</>
	);
};

export default MyDevicesTabPane;
