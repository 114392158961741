import api from './api';

export const createCompanyAPI = (title, key) => {
	const data = {
		title,
		key,
	};
	return api
		.post('company', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const getCompanyAPI = (id) => {
	const params = { id };
	return api
		.get('company', {
			params,
		})
		.then((response) => {
			return response.data.company;
		})
		.catch((response) => {
			console.log(response);
			return null;
		});
};

export const removeCompanyAPI = (id) => {
	const data = {
		id,
	};
	return api
		.delete('company', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const editCompanyAPI = (id, title, key) => {
	const data = {
		id,
		title,
		key,
	};
	return api
		.put('company', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const getAllCompaniesAPI = () => {
	return api
		.get('company/all')
		.then((response) => {
			return response.data.companies;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};


export const getCompaniesManagementAPI = (offset, limit, keyword) => {
	const params = {
		offset,
		limit,
		keyword,
	};
	return api
		.get('company/manage', { params })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};

export const checkValidCompanyAPI = (title, key) => {
	const params = {
		title,
		key,
	};
	return api
		.get('company/existed', { params })
		.then((response) => {
			return response.data.isExisted;
		})
		.catch((response) => {
			console.log(response);
			return false;
		});
};
