import { Col, Row, Input, Table, Button, Select, DatePicker, Space, ConfigProvider, Pagination } from 'antd';
import 'antd/dist/antd.css';
import './index.css';
import jaJP from 'antd/es/locale/ja_JP';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import Title from 'components/Title';
import { tags, statuses, types, flags } from 'constants/admin';
import { col } from './col';
import { useCallback, useEffect, useState } from 'react';
import { getAllCompaniesAPI } from 'apis/company';
import { getAllCarsAPI } from 'apis/car';
import { getItemsManagementAPI } from 'apis/item';
import { getAllDevicesAPI } from 'apis/device';
import { isOkStatus } from 'utils/transform';
import { useTranslation } from 'react-i18next';
import { getAllAreasAPI } from 'apis/area';
import { checkingDownloadExcelFileAPI, downloadFileAPI, removeFileAPI, requestDownloadFileAPI } from 'apis/download';

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ManageItemsScreen = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [keyword, setKeyword] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [columns, setColumns] = useState(col);

	const [items, setItems] = useState([]);

	const [companies, setCompanies] = useState([]);
	const [cars, setCars] = useState([]);
	const [devices, setDevices] = useState([]);
	const [areas, setAreas] = useState([]);

	const [carIds, setCarIds] = useState([]);
	const [deviceIds, setDeviceIds] = useState([]);
	const [companyIds, setCompanyIds] = useState([]);
	const [areaId, setAreaId] = useState(null);
	const [type, setType] = useState(null);
	const [status, setStatus] = useState();
	const [tag, setTag] = useState(null);
	const [time, setTime] = useState([]);
	const [flag, setFlag] = useState(null);

	const [exportLoading, setExportLoading] = useState(false);
	const [statusExport, setStatusExport] = useState(null);
	const [checking, setChecking] = useState(false);

	const onChangeAreaId = (value) => {
		setAreaId(value);
	};

	const onChangeType = (value) => {
		setType(value);
	};

	const onChangeFlag = (value) => {
		setFlag(value);
	};

	const onChangeStatus = (value) => {
		setStatus(value);
	};

	const onChangeTag = (value) => {
		setTag(value);
	};

	const onChangeCompanyIds = (value) => {
		setCompanyIds(value);
	};

	const onChangeCarIds = (value) => {
		setCarIds(value);
	};

	const onChangeDeviceIds = (value) => {
		setDeviceIds(value);
	};

	const onChangeTime = (value) => {
		if (value) {
			setTime([moment(value[0], 'YYYY-MM-DD HH:mm:ss'), moment(value[1], 'YYYY-MM-DD HH:mm:ss')]);
		} else setTime([]);
	};

	useEffect(() => {
		__setFilter();
	}, []);

	const __setFilter = async () => {
		const companies = await getAllCompaniesAPI();
		const cars = await getAllCarsAPI();
		const devices = await getAllDevicesAPI();
		const areas = await getAllAreasAPI();

		setCompanies(companies);
		setCars(cars);
		setDevices(devices);
		setAreas(areas);
	};

	const onChangeSearch = (value) => {
		if (value !== keyword) {
			setTotal(null);
			setDevices([]);
			setPageSize(null);
			setCurrent(1);
			setLimit(10);
			setKeyword(value);
			// __setData();
		}
	};

	const resetData = () => {
		setCurrent(1);
		setLimit(10);
		setTotal(null);
		setPageSize(null);
		setKeyword();
		setDeviceIds([]);
		setCarIds([]);
		setCompanyIds([]);
		setAreaId();
		setType();
		setStatus();
		setTag();
		setTime([]);
		setFlag()
		// __setData();
	};

	const __setData = useCallback(() => {
		console.log('xxxxxxxxxxxxxxxxx', flag)
		setLoading(true);
		let offset = (current - 1) * limit;
		const args = {
			offset,
			limit,
			keyword,
			areaId,
			type,
			flag,
			status,
			tag,
			time,
			companyIds,
			carIds,
			deviceIds,
		};
		getItemsManagementAPI(args).then(({ result }) => {
			setLoading(false);

			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;

				setTotal(count);

				setPageSize(limit);

				let items = [];

				rows.forEach((row, index) => {
					items.push({
						key: (current - 1) * limit + index + 1,
						title: row.title,
						type: row.type,
						flag: row.flag,
						area: row.area?.province + row.area?.county,
						status: isOkStatus(row.color, row.light),
						tag: row.tag,
						company: row.company.title,
						car: row.car,
						device: row.device.title,
						checkedAt: moment(row.checkedAt).format('LLLL'),
						row: row,
					});
				});

				setItems(items);
			}
		});
	}, [areaId, carIds, companyIds, current, deviceIds, flag, keyword, limit, status, tag, time, type]);

	useEffect(() => {
		__setData();
	}, [__setData]);

	const checkingDownloadExcelFile = () => {
		setChecking(true);
		checkingDownloadExcelFileAPI().then((status) => {
			switch (status) {
				case 'PROCESSING':
					setStatusExport('PROCESSING');
					break;
				case 'ERROR':
					setStatusExport('ERROR');
					break;
				case 'DONE':
					setStatusExport('DONE');
					break;
				default:
					break;
			}
			setChecking(false);
		});
	};

	useEffect(() => {
		checkingDownloadExcelFile();
	}, []);

	const requestDownloadExcel = () => {
		const args = {
			areaId,
			flag,
			type,
			status,
			tag,
			time,
			keyword,
			companyIds,
			carIds,
			deviceIds,
		};

		requestDownloadFileAPI(args).then(() => {
			setExportLoading(true);
			setStatusExport('PROCESSING');
		});
	};

	const downloadExcel = async () => {
		setExportLoading(true);

		const res = await downloadFileAPI();

		let blob = new Blob([res.data], { type: res.headers['content-type'] });

		let link = document.createElement('a');

		link.href = window.URL.createObjectURL(blob);

		link.download = 'item.xlsx';

		link.click();

		await removeFileAPI();

		setExportLoading(false);

		setStatusExport(null);
	};

	const showButtonDownLoad = () => {
		switch (checking) {
			case true:
				return (
					<Button type="danger" shape="round" size="large" loading>
						ダウンロードの状態を確認中です。
					</Button>
				);
			case false:
				switch (statusExport) {
					case 'PROCESSING':
						return (
							<Button type="danger" shape="round" size="large" loading>
								処理中
							</Button>
						);
					case 'DONE':
						return (
							<Button
								type="danger"
								shape="round"
								icon={<DownloadOutlined />}
								size="large"
								loading={exportLoading}
								onClick={() => downloadExcel()}
							>
								処理済
							</Button>
						);
					case 'ERROR':
						return (
							<Button type="danger" shape="round" size="large">
								エラーが発生しました。
							</Button>
						);
					default:
						return (
							<Button type="danger" shape="round" size="large" onClick={() => requestDownloadExcel()}>
								{t('ManageItems.Download')}
							</Button>
						);
				}
			default:
				break;
		}
	};

	return (
		<>
			<Title level={3} title={t('ManageItems.Title')} />

			<Row style={{ marginBottom: '5px' }} type="flex" justify="center" align="middle">
				<Col span={6}>
					<Row justify="left">
						<Button
							shape="round"
							type="primary"
							size="large"
							style={{ marginRight: '5px' }}
							onClick={() => resetData()}
						>
							{t('ManageItems.AllItems')}
						</Button>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="center">
						<Search enterButton={t('ManageItems.Search')} size="large" onSearch={(e) => onChangeSearch(e)} />
					</Row>
				</Col>
				<Col span={6}>
					<Row justify="end">{showButtonDownLoad()}</Row>
				</Col>
			</Row>

			<Row style={{ marginBottom: '10px', marginTop: '15px' }} type="flex" justify="space-between" align="top">

				<div>
					<Space style={{ marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{/* <div style={{ marginBottom: '-8px' }}>
							<span style={{ marginBottom: '0px', display: 'block' }}>{t('ManageItems.ProvinceFilter')}:</span>
						</div> */}
						<Select
							value={areaId}
							style={{ minWidth: '12vw' }}
							onChange={(e) => onChangeAreaId(e)}
							showSearch
							optionFilterProp="children"
							placeholder={t('ManageItems.ProvinceFilter')}
						>
							{areas.map((area) => (
								<Option value={area.id} key={area.id}>{area.province + area.county}</Option>
							))}
						</Select>
					</Space>

					<Space style={{ marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{/* <div style={{ marginBottom: '-8px' }}>
							<span>{t('ManageItems.DeviceFilter')}:</span>
						</div> */}
						<Select
							value={deviceIds}
							mode="multiple"
							style={{ width: '12vw' }}
							onChange={(e) => onChangeDeviceIds(e)}
							maxTagCount={1}
							placeholder={t('ManageItems.DeviceFilter')}
						>
							{devices.map((device) => (
								<Option value={device.id} key={device.id}>
									{device.title}
								</Option>
							))}
						</Select>
					</Space>

				</div>


				<div>
					<Space style={{marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{/* <div style={{ marginBottom: '-8px' }}>
							<span>{t('ManageItems.TypeFilter')}:</span>
						</div> */}

						<Select value={type} style={{ minWidth: '12vw' }} onChange={(e) => onChangeType(e)} placeholder={t('ManageItems.TypeFilter')}>
							{types.map((type) => (
								<Option value={type.value} key={type.value}>{type.label}</Option>
							))}
						</Select>
					</Space>

					<Space style={{ marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{/* <div style={{ marginBottom: '-8px' }}>
							<span>{t('ManageItems.CompanyFilter')}:</span>
						</div> */}
						<Select
							value={companyIds}
							mode="multiple"
							style={{ width: '12vw' }}
							onChange={(e) => onChangeCompanyIds(e)}
							maxTagCount={1}
							placeholder={t('ManageItems.CompanyFilter')}
						>
							{companies.map((company) => (
								<Option value={company.id} key={company.title}>
									{company.title}
								</Option>
							))}
						</Select>
					</Space>
				</div>

				<div>
					<Space style={{ marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{/* <div style={{ marginBottom: '-8px' }}>
							<span>制御フラグ:</span>
						</div> */}

						<Select value={flag} style={{ minWidth: '12vw' }} onChange={(e) => onChangeFlag(e)} placeholder="制御フラグ:">
							{flags.map((flag) => (
								<Option value={flag.value} key={flag.value}>{flag.label}</Option>
							))}
						</Select>
					</Space>

					<Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{/* <div style={{ marginBottom: '-8px' }}>
							<span>{t('ManageItems.RangeTimeFilter')}:</span>
						</div> */}
						<ConfigProvider locale={jaJP}>
							<RangePicker
								style={{ width: '12vw' }}
								value={time}
								showTime={{
									hideDisabledOptions: true,
									defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
								}}
								format="YYYY-MM-DD HH:mm:ss"
								onChange={(e) => onChangeTime(e)}
								placeholder={t('ManageItems.RangeTimeFilter')}
							/>
						</ConfigProvider>
					</Space>
				</div>

				<Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					{/* <div style={{ marginBottom: '-8px' }}>
						<span>{t('ManageItems.StatusFilter')}:</span>
					</div> */}
					<Select value={status} style={{ minWidth: '12vw' }} onChange={(e) => onChangeStatus(e)} placeholder={t('ManageItems.StatusFilter')}>
						{statuses.map((status) => (
							<Option value={status.value} key={status.value}>{status.label}</Option>
						))}
					</Select>
				</Space>

				<Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					{/* <div style={{ marginBottom: '-8px' }}>
						<span>{t('ManageItems.TagFilter')}:</span>
					</div> */}

					<Select value={tag} style={{ minWidth: '12vw' }} onChange={(e) => onChangeTag(e)} placeholder={t('ManageItems.TagFilter')}>
						{tags.map((tag) => (
							<Option value={tag.value} key={tag.value}>{tag.label}</Option>
						))}
					</Select>
				</Space>

				<Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					{/* <div style={{ marginBottom: '-8px' }} >
						<span>{t('ManageItems.CarFilter')}:</span>
					</div> */}

					<Select
						value={carIds}
						mode="multiple"
						style={{ width: '12vw' }}
						onChange={(e) => onChangeCarIds(e)}
						maxTagCount={1}
						placeholder={t('ManageItems.CarFilter')}
					>
						{cars.map((car) => (
							<Option value={car.id} key={car.title}>
								{car.title}
							</Option>
						))}
					</Select>
				</Space>
			</Row>

			<Row type="flex" justify="center" align="middle" style={{marginTop: '5px'}}>
				<Col span={24}>
					<Row justify="center">
						<Table
							locale={{
								triggerDesc: '降順で並べ替え',
								triggerAsc: '昇順で並べ替え',
								cancelSort: '並べ替え状態を解除',
								emptyText: 'データなし'
							}}
							columns={columns}
							dataSource={items}
							bordered={true}
							// scroll={{ x: 2200 }}
							scroll={{ x: 'calc(120vw)' }}
							pagination={false}
							loading={loading}
						/>
					</Row>
				</Col>
			</Row>

			<Pagination
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					marginTop: '5px',
					marginBottom: '5px',
				}}
				onChange={(current) => setCurrent(current)}
				total={total}
				current={current}
				pageSize={pageSize ? pageSize : 10}
				defaultCurrent={1}
				defaultPageSize={10}
			/>
		</>
	);
};

export default ManageItemsScreen;
