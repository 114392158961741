import { REACT_APP_API_ENDPOINT } from 'configs/api';
import { useState } from 'react';
import Item from '../Item';
import { useTranslation } from 'react-i18next';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

const Main = (props) => {
	const { t } = useTranslation();
	const [image, setImage] = useState(null);
	const [item, setItem] = useState(null);
	const [visibleShowItem, setVisibleShowItem] = useState(false);
	const { width, ref } = useResizeObserver();

	const showItem = (item, image) => {
		setImage(image);
		setItem(item);
		setVisibleShowItem(true);
	};

	const closeShowItem = () => setVisibleShowItem(!visibleShowItem);

	const calculateCoords = (boundingBox, ratio = width / props.width) => {
		let box = [];
		box.push(boundingBox[0] * ratio);
		box.push(boundingBox[1] * ratio);
		box.push((props.width - boundingBox[2]) * ratio);
		box.push((props.height - boundingBox[3]) * ratio);
		return box.join(',');
	};

	const onChangeLoad = () => {
		props.loadedImageRef.current = true;
	};

	return (
		<Row type="flex" justify="center">
			{props.image ? (
				<>
					<Col style={{ width: '100%' }}>
						<Row justify="center" style={{ width: '100%' }}>
							<img
								ref={ref}
								useMap="#workmap"
								src={REACT_APP_API_ENDPOINT + props.image}
								onLoad={() => onChangeLoad()}
								style={{ width: '100%', height: '100%' }}
							/>
							<map name="workmap">
								{props.items?.map((item, index) => {
									return (
										<area
											key={index}
											shape="rect"
											coords={calculateCoords(item.boundingBox)}
											onClick={() => showItem(item, props.image)}
										/>
									);
								})}
							</map>
						</Row>
					</Col>
					{item && (
						<Item
							visible={visibleShowItem}
							setVisible={closeShowItem}
							item={item}
							image={image}
							version={props.version}
							checkedAt={props.checkedAt}
							device={props.device}
						/>
					)}
				</>
			) : (
				<div
					style={{
						position: 'absolute',
						top: '50%',
					}}
				>
					<LoadingOutlined tip={t('Monitor.Loading')} style={{ fontSize: 40, color: '#1890ff' }} spin />
				</div>
			)}
		</Row>
	);
};

export default Main;
