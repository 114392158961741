import { Select, Input, Button, Col, Row, Image, Space, Empty } from 'antd';
import Title from 'components/Title';
import { colors, tags, lights, types, tagModes, forbiddens, rejects } from 'constants/options';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { isOkStatus } from 'utils/transform';

const HistoryItemScreen = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const [title, setTitle] = useState(null);
	const [location, setLocation] = useState(null);
	const [type, setType] = useState(null);
	const [forbidden, setForbidden] = useState(null);
	const [reject, setReject] = useState(null);
	const [color, setColor] = useState(null);
	const [light, setLight] = useState(null);
	const [tag, setTag] = useState(null);
	const [tagMode, setTagMode] = useState(null);
	const [image, setImage] = useState(null);
	const [taggedAt, setTaggedAt] = useState(null);
	const [version, setVersion] = useState(null);
	const [checkedAt, setCheckedAt] = useState(null);
	const [editedAt, setEditedAt] = useState(null);
	const [note, setNote] = useState(null);

	const [tagged, setTagged] = useState(null);
	const [edited, setEdited] = useState(null);
	const [device, setDevice] = useState(null);

	useEffect(() => {
		__setData(history.location.state.item);
	}, [history.location.state.item]);

	const __setData = (item) => {
		setTitle(item.title);
		setLocation(item.location);
		setType(item.type);

		switch (item.flag) {
			case 'AUTOMATIC':
				setForbidden(false);
				setReject(false);
				break;
			case 'FORBIDDEN':
				setForbidden(true);
				setReject(false);
				break;
			default:
				setForbidden(false);
				setReject(false);
				break;
		}

		setColor(item.color);
		setLight(item.light);
		setTagMode(item.tagMode);
		setTaggedAt(item.taggedAt);
		setVersion(item.version);
		setTag(item.tag);
		setImage(item.image);
		setVersion(item.version);
		setEditedAt(item.editedAt);
		setCheckedAt(item.checkedAt);
		setNote(item.note);

		setTagged(item.tagged);
		setEdited(item.edited);
		setDevice(item.device.title);
	};

	return (
		<>
			<Title level={3} title={t('HistoryItem.Title')} />
			<Row>
				<Col span={12} offset={0}>
					<div className="title">{t('DetailItem.ItemIdLable')}:</div>
					<Input readOnly={true} size="large" value={title} disabled />

					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={12}>
							<div className="title">{t('Longitude')}:</div>
							<Input readOnly={true} size="large" value={location?.coordinates[0]} disabled />
						</Col>
						<Col span={12}>
							<div className="title">{t('Latitude')}:</div>
							<Input disabled value={location?.coordinates[1]} readOnly={true} size="large" />
						</Col>
					</Row>

					<div className="title">{t('Status')}:</div>
					<Input size="large" value={isOkStatus(color, light) ? t('Normal') : t('No Normal')} disabled />

					<div className="title">{t('Type')}:</div>
					<Select
						disabled
						value={type}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
						defaultValue="Others"
					>
						{types.map((type) => (
							<Select.Option value={type.value} key={type.value}>{type.label}</Select.Option>
						))}
					</Select>

					<div className="title">検査禁止フラグ:</div>
					<Select
						value={forbidden}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
						disabled
					>
						{forbiddens.map((forbidden) => (
							<Select.Option value={forbidden.value} key={forbidden.value}>{forbidden.label}</Select.Option>
						))}
					</Select>

					<div className="title">認識結果不採用フラグ:</div>
					<Select
						value={reject}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
						disabled
					>
						{rejects.map((reject) => (
							<Select.Option value={reject.value} key={reject.value}>{reject.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('Color')}:</div>
					<Select
						disabled
						value={color}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{colors.map((color) => (
							<Select.Option value={color.value} key={color.value}>{color.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('Light')}:</div>
					<Select
						disabled
						value={light}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{lights.map((light) => (
							<Select.Option value={light.value} key={light.value}>{light.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('TagMode')}:</div>
					<Select
						disabled
						value={tagMode}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{tagModes.map((mode) => (
							<Select.Option value={mode.value} key={mode.value}>{mode.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('Tag')}:</div>
					<Select
						disabled
						value={tag}
						className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
						style={{ width: '100%' }}
					>
						{tags.map((tag) => (
							<Select.Option value={tag.value} key={tag.value}>{tag.label}</Select.Option>
						))}
					</Select>

					<div className="title">{t('TaggedAt')}:</div>
					<Input readOnly={true} size="large" value={taggedAt && moment(taggedAt).format('LLLL')} disabled />

					<div className="title">{t('TaggedBy')}:</div>
					<Input size="large" value={tagged} disabled />

					<div className="title">{t('AiVersion')}:</div>
					<Input size="large" value={version} disabled />

					<div className="title">{t('DeviceId')}:</div>
					<Input size="large" value={device} disabled />

					<div className="title">{t('CheckedAt')}:</div>
					<Input value={checkedAt && moment(checkedAt).format('LLLL')} disabled size="large" />

					<div className="title">{t('EditedBy')}:</div>
					<Input size="large" value={edited} disabled />

					<div className="title">{t('EditedAt')}:</div>
					<Input value={editedAt && moment(editedAt).format('LLLL')} disabled size="large" />
				</Col>

				<Col span={11} offset={1}>
					<div className="title">{t('Image')}:</div>
					{image ? <Image src={REACT_APP_API_ENDPOINT + image} style={{ width: '100% !important' }} /> : <Empty description={false} />}
				</Col>

				<Col span={24}>
					<div className="title">{t('Note')}:</div>
					<Input.TextArea disabled className="ant-input ant-input-lg" rows={6} value={note} />
				</Col>
			</Row>

			<Row style={{ marginTop: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Row justify="center">
						<Space>
							<Button
								shape="round"
								className="button-optional"
								type="danger"
								size="large"
								onClick={() => history.goBack()}
							>
								{t('HistoryItem.Back')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default HistoryItemScreen;
