import { Badge, Button, Card, Popover, List, notification } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { stopDeviceAPI } from 'apis/device';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { saveItems } from 'utils/localStorage';

const View = ({ device, refresh, currentTab }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleClickChange = (visible) => {
		setVisible(visible);
	};

	const view = (device) => {
		saveItems([{ item: 'device', data: device }]);
		history.push('monitor');
	};

	const setup = (device) => {
		history.push({
			pathname: 'setup-device',
			state: { device, editable: false, currentTab },
		});
	};

	const stop = (device) => {
		setLoading(true);
		stopDeviceAPI(device.id).then((message) => {
			setVisible(!visible);
			setLoading(false);
			refresh();
			if (message === 'ERROR') {
				notification.error({
					message: t('Error'),
					description: t('ErrorDescription'),
				});
				return;
			}
			notification.success({
				message: t('Success'),
				description: '検査が終了しました',
			});
		});
	};

	return (
		<Popover
			content={
				<div style={{ width: '300px' }}>
					<p style={{ textAlign: 'justify' }}>
						{t('SelectDevice.ViewPopup')}
						<br />
						<a onClick={() => setup(device)}>{t('SelectDevice.ViewInfoDevice')}</a>
					</p>

					<div className="ant-popover-buttons">
						<Button size="small" onClick={() => setVisible(!visible)}>
							{t('SelectDevice.Close')}
						</Button>
						<Button size="small" type="danger" onClick={() => stop(device)} loading={loading}>
							{t('SelectDevice.End')}
						</Button>
						<Button size="small" type="primary" onClick={() => view(device)}>
							{t('SelectDevice.View')}
						</Button>
					</div>
				</div>
			}
			visible={visible}
			trigger="click"
			onVisibleChange={(e) => handleClickChange(e)}
		>
			<List.Item>
				<Card hoverable>
					<Meta avatar={<Badge status="success" />} title={device.title} description={t('SelectDevice.Running')} />
				</Card>
			</List.Item>
		</Popover>
	);
};

export default View;
