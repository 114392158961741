import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { REACT_APP_SOCKET_ENDPOINT } from 'configs/socket';
import { getItems } from 'utils/localStorage';
import { transports } from 'configs/socket';
import Title from 'components/Title';
import Main from './components/Main';
import { useTranslation } from 'react-i18next';
import EmptyMonitor from './components/Empty';
import ErrorNetwork from './components/Error';

const MonitorScreen = () => {
	const { t } = useTranslation();
	const loadedImage = useRef();
	const lastTimeResponse = useRef();
	const [errorNetwork, setErrorNetwork] = useState(null);
	const [device, setDevice] = useState(null);
	const [items, setItems] = useState(null);
	const [image, setImage] = useState(null);
	const [socket, setSocket] = useState(null);
	const [width, setWidth] = useState(null);
	const [height, setHeight] = useState(null);
	const [version, setVersion] = useState(null);
	const [checkedAt, setCheckedAt] = useState(null);

	useEffect(() => {
		const { device, session } = getItems(['device', 'session']);
		const socket = io(REACT_APP_SOCKET_ENDPOINT, {
			query: {
				token: session.tokens.access.token,
			},
			withCredentials: true,
			transports,
		});

		if (device) {
			setDevice(device);
			socket.emit('room', `mac-${device.mac}`);
		}

		setSocket(socket);
		return () => socket.close();
	}, []);

	useEffect(() => {
		loadedImage.current = true;
		const notifyListener = async (response) => {
			lastTimeResponse.current = Date.now();

			setErrorNetwork(false);

			const { items, image, width, height, version, checkedAt } = JSON.parse(response);

			if (loadedImage.current && image) {
				setImage(image);
				setItems(items);
				setHeight(height);
				setWidth(width);
				setVersion(version);
				setCheckedAt(checkedAt);
				loadedImage.current = false;
			}
		};

		socket?.on('notify', notifyListener);
	}, [socket]);

	useEffect(() => {
		const checkTimeResponse = setInterval(() => {
			const currentTime = Date.now();
			const isErrorNetwork = !lastTimeResponse.current || currentTime - lastTimeResponse.current >= 5000;
			setErrorNetwork(isErrorNetwork);
		}, 5000);

		return () => {
			clearInterval(checkTimeResponse);
		};
	}, []);

	if (!device) return <EmptyMonitor />;

	if (errorNetwork) return <ErrorNetwork />;

	return (
		<Fragment>
			<Title level={3} title={t('Monitor.Title')} />
			<Main
				connected={true}
				items={items}
				image={image}
				width={width}
				height={height}
				version={version}
				checkedAt={checkedAt}
				device={device}
				loadedImageRef={loadedImage}
			/>
		</Fragment>
	);
};

export default memo(MonitorScreen);
