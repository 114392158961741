import api from './api';

export const requestDownloadFileAPI = (args) => {
	return api
		.post('item/export', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const checkingDownloadExcelFileAPI = () => {
	return api
		.get('download/checking')
		.then((response) => {
			return response.data.status;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const downloadFileAPI = () => {
	return api
		.get('download', { responseType: 'blob' })
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const removeFileAPI = () => {
	return api
		.delete('download')
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};
