import api from './api';
import { getItems } from 'utils/localStorage';

export const getAllUsersAPI = () => {
	return api
		.get('user/all')
		.then((response) => {
			return response.data.users;
		})
		.catch((response) => {
			console.log(response);
			return [];
		});
};

export const getInfoMyAccountAPI = () => {
	return api
		.get('user')
		.then((response) => {
			return response.data.user;
		})
		.catch((response) => {
			console.log(response);
			return null;
		});
};

export const changePasswordAPI = (oldPassword, newPassword) => {
	const { session } = getItems(['session']);

	const data = {
		oldPassword: oldPassword,
		newPassword: newPassword,
		userId: session.userInfo.userId,
	};
	return api
		.post('user/change-password', data)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const getAccountsManagementAPI = (offset, limit, keyword) => {
	const params = {
		offset,
		limit,
		keyword,
	};

	return api
		.get('user/manage', { params })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};

export const getPendingAccountsManagementAPI = (offset, limit, keyword) => {
	const params = {
		offset,
		limit,
		keyword,
	};

	return api
		.get('user/manage/pending', { params })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			console.log(response);
			const result = {
				rows: [],
				count: 0,
			};
			return { result };
		});
};

export const editAccountAPI = (args) => {
	return api
		.put('user', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const acceptPendingAccountAPI = (args) => {
	return api
		.post('user/accept', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const rejectPendingAccountAPI = (args) => {
	return api
		.post('user/reject', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};

export const createAccountAPI = (args) => {
	return api
		.post('user/create', args)
		.then((response) => {
			return response.data.message;
		})
		.catch((response) => {
			console.log(response);
			return 'ERROR';
		});
};
