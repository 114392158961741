import React from 'react';
import { Select } from 'antd';
import { useRequest } from '@umijs/hooks';
import { getAllCarsAPI } from 'apis/car';
import { getAllCompaniesAPI } from 'apis/company';
import { getAllAreasAPI } from 'apis/area';
import { getAllUsersAPI } from 'apis/user';
import { getAllDevicesAPI } from 'apis/device';

async function getAreas() {
	return await getAllAreasAPI();
}

async function getCompanies() {
	return await getAllCompaniesAPI();
}

async function getCars() {
	return await getAllCarsAPI();
}

async function getUsers() {
	return await getAllUsersAPI();
}

async function getDevices() {
	return await getAllDevicesAPI();
}

export default function GroupSelection({ value, onChange, type, mode, ...rest }) {
	const switchType = () => {
		switch (type) {
			case 'area':
				return getAreas;
			case 'company':
				return getCompanies;
			case 'user':
				return getUsers;
			case 'car':
				return getCars;
			case 'device':
				return getDevices;
			default:
				return getDevices;
		}
	};

	const { loading, data } = useRequest(switchType());

	const content = () => {
		if (Array.isArray(data)) {
			if (type === 'area') {
				switch (mode) {
					case 'province-county-id':
						return data.map((element) => {
							const value = JSON.stringify({
								province: element.province,
								county: element.county,
								id: element.id,
							});
							return (
								<Select.Option key={element.id} value={value}>
									{element.province + element.county}
								</Select.Option>
							);
						});
					default:
						return data.map((element) => (
							<Select.Option key={element.id} value={element.id}>
								{element.province + element.county}
							</Select.Option>
						));
				}
			} else if (type === 'company') {
				switch (mode) {
					case 'two-characters':
						return data.map((element) => (
							<Select.Option key={element.id} value={element.key}>
								{element.title}
							</Select.Option>
						));
					case 'two-characters-and-id':
						return data.map((element) => {
							const value = JSON.stringify({
								key: element.key,
								id: element.id,
							});
							return (
								<Select.Option key={element.id} value={value}>
									{element.title}
								</Select.Option>
							);
						});
					default:
						return data.map((element) => (
							<Select.Option key={element.id} value={element.id}>
								{element.title}
							</Select.Option>
						));
				}
			} else if (type === 'car') {
				switch (mode) {
					case 'id':
						return data.map((element) => {
							return (
								<Select.Option key={element.id} value={element.id}>
									{element.title}
								</Select.Option>
							);
						});
					default:
						return data.map((element) => {
							const value = JSON.stringify({
								key: element.key,
								id: element.id,
							});
							return (
								<Select.Option key={element.key} value={value}>
									{element.title}
								</Select.Option>
							);
						});
				}
			} else if (type === 'user') {
				return data.map((element) => (
					<Select.Option key={element.id} value={element.id}>
						{element.username}
					</Select.Option>
				));
			} else if (type === 'device') {
				return data.map((element) => (
					<Select.Option key={element.id} value={element.id}>
						{element.title}
					</Select.Option>
				));
			}
			return null;
		}
		return null;
	};

	return (
		<Select
			{...rest}
			loading={loading}
			listHeight={250}
			showSearch
			optionFilterProp="children"
			value={value}
			onChange={onChange}
		>
			{content()}
		</Select>
	);
}
