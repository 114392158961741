import { Col, Row, Input, Table, Form, Select, Pagination, notification, Button, Space } from 'antd';
import { useCallback, useState } from 'react';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { acceptPendingAccountAPI, getPendingAccountsManagementAPI, rejectPendingAccountAPI } from 'apis/user';
import { getAllCompaniesAPI } from 'apis/company';
import { statusesPendingUser } from 'constants/options';
import { transformCompany, transformRole } from 'utils/transform';
import { RollbackOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

const { Search } = Input;

const EditableCell = ({ editing, dataIndex, inputType, children, companies, ...restProps }) => {
	const [inputNode, setInputNode] = useState(null);

	useEffect(() => {
		switch (inputType) {
			case 'status':
				setInputNode(
					<Select>
						{statusesPendingUser.map((status) => {
							return <Select.Option value={status.value} key={status.value}>{status.label}</Select.Option>;
						})}
					</Select>
				);
				break;
			default:
				setInputNode(<Input />);
				break;
		}
	}, [companies, inputType]);

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} required>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const PendingAccountsScreen = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pageSize, setPageSize] = useState(null);
	const [total, setTotal] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [companies, setCompanies] = useState([]);

	const onSearchKeyword = (value) => {
		if (value !== keyword) {
			setTotal(null);
			setAccounts(null);
			setPageSize(null);
			setCurrent(1);
			setLimit(10);
			setKeyword(value);
		}
	};

	const columns = [
		{
			title: t('No'),
			dataIndex: 'key',
		},
		{
			title: t('ManageAccounts.UsernameField'),
			dataIndex: 'username',
		},
		{
			title: t('ManageAccounts.EmailField'),
			dataIndex: 'email',
		},
		{
			title: t('ManageAccounts.CompanyField'),
			dataIndex: 'company',
			render: (company) => {
				return transformCompany(companies, company);
			},
		},
		{
			title: t('ManageAccounts.RoleField'),
			dataIndex: 'role',
			render: (role) => transformRole(role),
		},
		{
			title: t('ManageAccounts.OptionField'),
			dataIndex: 'Actions',
			width: '100px',
			render: (_, record) => {
				const args = {
					id: record.id,
				};

				const accept = () => {
					setLoading(true);
					acceptPendingAccountAPI(args).then((message) => {
						switch (message) {
							case 'ERROR':
								notification.error({
									message: t('Error'),
									description: t('ErrorDescription'),
								});
								break;
							default:
								notification.success({
									message: t('Success'),
									description: t('ManageAccounts.MessageActiveAccount'),
								});
								__setData();
								break;
						}
					});
				};

				const reject = () => {
					rejectPendingAccountAPI(args).then((message) => {
						switch (message) {
							case 'ERROR':
								notification.error({
									message: t('Error'),
									description: t('ErrorDescription'),
								});
								break;
							default:
								notification.success({
									message: t('Success'),
									description: t('ManageAccounts.MessageRejectSuccess'),
								});
								__setData();
								break;
						}
					});
				};
				return (
					<Space>
						<Button type="link" onClick={() => accept()} loading={loading}>
							承認
						</Button>
						<Button type="link" onClick={() => reject()} style={{ color: 'red', marginLeft: '-20px' }}>
							拒否
						</Button>
					</Space>
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.dataIndex,
				dataIndex: col.dataIndex,
				title: col.title,
				companies,
			}),
		};
	});

	const __setData = useCallback(() => {
		setLoading(true);
		let offset = (current - 1) * limit;
		getPendingAccountsManagementAPI(offset, limit, keyword).then(({ result }) => {
			setLoading(false);
			if (result && !(Object.keys(result).length === 0)) {
				const { count, rows } = result;

				setTotal(count);
				setPageSize(limit);
				let accounts = [];

				rows.forEach((row, index) => {
					let { id, username, email, companyId, role, status } = row;
					accounts.push({
						id: id,
						key: index + 1,
						email,
						username,
						company: companyId,
						role,
						status,
					});
				});

				setAccounts(accounts);
			}
		});
	}, [current, keyword, limit]);

	useEffect(() => {
		__setData();
	}, [__setData]);

	useState(() => {
		getAllCompaniesAPI().then((companies) => setCompanies(companies));
	}, []);

	return (
		<>
			<Title level={3} title={'承認待ちアカウント一覧'} />

			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={6}>
					<Row justify="left">
						<Button shape="round" type="danger" size="large" onClick={() => history.goBack()}>
							<RollbackOutlined /> キャンセル
						</Button>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="center">
						<Search enterButton={t('Search')} size="large" onSearch={(e) => onSearchKeyword(e)} />
					</Row>
				</Col>
				<Col span={6}></Col>
			</Row>
			<Row style={{ marginBottom: '20px' }} type="flex" justify="center" align="middle">
				<Col span={24}>
					<Form form={form} component={false}>
						<Table
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							locale={{
								triggerDesc: '降順で並べ替え',
								triggerAsc: '昇順で並べ替え',
								cancelSort: '並べ替え状態を解除',
								emptyText: 'データなし'
							}}
							bordered
							dataSource={accounts}
							columns={mergedColumns}
							rowClassName="editable-row"
							pagination={false}
							loading={loading}
							scroll={{ x: 1024 }}
							// scroll={{ x: 'calc(100vw)' }}
						/>
						<Pagination
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '5px',
								marginBottom: '5px',
							}}
							onChange={(current) => setCurrent(current)}
							total={total}
							current={current}
							pageSize={pageSize}
							defaultCurrent={1}
							defaultPageSize={10}
						/>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default PendingAccountsScreen;
