export const types = [
	{ label: '照明', value: 'Lights' },
	{ label: '小標識支柱', value: 'Small Signs' },
	{ label: '大標識支柱', value: 'Signs' },
	{ label: 'その他', value: 'Other' },
	{ label: '全て', value: 'All' },
];

export const tags = [
	{ label: '腐食', value: 'Rusty' },
	{ label: '落書', value: 'Graffiti' },
	{ label: '公告', value: 'Poster' },
	{ label: 'その他', value: 'Other' },
	{ label: '無', value: 'Unset' },
	{ label: '全て', value: 'All' },
];

export const colors = [
	{ label: '有', value: 'Discolor' },
	{ label: '無', value: 'Color' },
	{ label: '全て', value: 'All' },
];

export const lights = [
	{ label: '無', value: 'No Error' },
	{ label: '有', value: 'Error' },
	{ label: '無', value: 'Unset' },
	{ label: '全て', value: 'All' },
];

export const flags = [
	{ label: '変色区分タグ自動設定', value: 'AUTOMATIC' },
	{ label: '検査禁止フラグ', value: 'FORBIDDEN' },
	{ label: '認識結果不採用フラグ', value: 'REJECT' },
	{ label: '全て', value: 'All' },
];

export const statuses = [
	{ label: '不合格', value: false },
	{ label: '合格', value: true },
	{ label: '全て', value: null },
];

