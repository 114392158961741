/* eslint-disable no-unused-vars */
import { Modal, Button, Space, Descriptions, Image } from 'antd';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isOkStatusV2, transformColorV2, transformLightV2, transformLocationV2, transformTagV2 } from 'utils/transform';
import './index.css';

const Item = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Modal
				width={900}
				visible={props.visible}
				closable={false}
				onCancel={() => props.setVisible()}
				footer={
					<Space>
						<Button shape="round" type="danger" onClick={() => props.setVisible()}>
							{t('Monitor.Close')}
						</Button>
					</Space>
				}
			>
				<Descriptions title={t('Monitor.Live')} bordered column={1}>
					<Descriptions.Item label={t('Type')} span={1}>
						{props.item.type}
					</Descriptions.Item>

					<Descriptions.Item label={t('Status')} span={1}>
						{isOkStatusV2(props.item.color, props.item.light) ? t('Normal') : t('No Normal')}
					</Descriptions.Item>

					<Descriptions.Item label={t('Location')} span={4}>
						{transformLocationV2(props.item.location.longitude, props.item.location.latitude)}
					</Descriptions.Item>

					<Descriptions.Item label={t('Light')} span={1}>
						{transformLightV2(props.item.light)}
					</Descriptions.Item>

					<Descriptions.Item label={t('Color')} span={1}>
						{transformColorV2(props.item.color)}
					</Descriptions.Item>

					<Descriptions.Item label={t('Tag')} span={1}>
						{transformTagV2(props.item.color)}
					</Descriptions.Item>

					<Descriptions.Item label={t('Image')} span={1}>
						<Image src={REACT_APP_API_ENDPOINT + props.image} style={{ width: '100% !important' }} />
					</Descriptions.Item>

					<Descriptions.Item label={t('AiVersion')} span={1}>
						{props.version}
					</Descriptions.Item>

					<Descriptions.Item label={t('DeviceId')} span={1}>
						{props.device.title}
					</Descriptions.Item>

					<Descriptions.Item label={t('CheckedAt')} span={1}>
						{moment(props.item.checkedAt).format('LLLL')}
					</Descriptions.Item>
				</Descriptions>
			</Modal>
		</>
	);
};

export default Item;
