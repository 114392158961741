/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Select, Input, Button, Col, Row, Image, notification, Spin, Empty } from 'antd';
import 'antd/dist/antd.css';
import 'moment/locale/ja';
import Title from 'components/Title';
import { colors, tags, lights, types, tagModes, forbiddens, rejects } from 'constants/options';
import { useHistory } from 'react-router-dom';
import { AimOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import { REACT_APP_API_ENDPOINT } from 'configs/api';
import { useTranslation } from 'react-i18next';
import { isOkStatus } from 'utils/transform';
import { createItemAPI } from 'apis/item';
import { getItems } from 'utils/localStorage';
import GroupSelection from 'components/GroupSelection';
import MapModule from 'components/MapModule';

const CreateItem = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [oldId, setOldId] = useState(null);
	const [title, setTitle] = useState(null);
	const [areaId, setAreaId] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [flag, setFlag] = useState('AUTOMATIC');
	const [tagMode, setTagMode] = useState('AUTOMATIC');
	const [tag, setTag] = useState(null);
	const [type, setType] = useState(null);
	const [location, setLocation] = useState(null);
	const [color, setColor] = useState(null);
	const [light, setLight] = useState(null);
	const [image, setImage] = useState(null);
	const [version, setVersion] = useState(null);
	const [deviceId, setDeviceId] = useState(null);
	const [checkedAt, setCheckedAt] = useState(null);
	const [note, setNote] = useState(null);

	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const { item } = history.location.state;
		__setData(item);
	}, [history.location.state]);

	const __setData = (item) => {
		setOldId(item.id);
		setTitle(item.title);
		setType(item.type);
		setCompanyId(item.companyId);
		setAreaId(item.areaId);
		setColor(item.color);
		setLight(item.light);
		setTag(item.tag);
		setCheckedAt(item.checkedAt);
		setDeviceId(item.deviceId);
		setLocation(item.location);
		setVersion(item.version);
		setImage(item.image);
	};

	const createItem = () => {
		setLoading(true);
		const { session } = getItems(['session']);

		const args = {
			oldId,
			title,
			companyId,
			areaId,
			location,
			type,
			color,
			light,
			flag,
			image,
			tagMode,
			tag,
			version,
			deviceId,
			checkedAt,
			note,
			taggedAt: Date(),
			taggedBy: session.userInfo.userId,
			editedAt: Date(),
			editedBy: session.userInfo.userId,
		};

		createItemAPI(args).then((message) => {
			setLoading(false);
			switch (message) {
				case 'ERROR':
					notification.error({
						message: t('Error'),
						description: t('ErrorDescription'),
					});
					break;
				case 'SAME_LOCATION_OTHER_ITEM':
					notification.error({
						message: t('Error'),
						description: t('CreateItem.MessageSameLocation'),
					});
					break;
				default:
					notification.success({
						message: t('Success'),
						description: t('CreateItem.MessageCreatedItemSuccess'),
					});
					break;
			}
		});
	};

	const back = () => history.goBack();

	const onChangeType = (type) => setType(type);

	const onChangeColor = (color) => {
		switch (color) {
			case 'Color':
				setColor('Color');
				setTag('Rusty');
				break;
			case 'Discolor':
				setColor('Discolor');
				setTag('Unset');
				break;
			default:
				break;
		}
	};

	const onChangeTag = (tag) => {
		switch (tag) {
			case 'Dirty':
				setColor('Color');
				setTag('Dirty');
				break;
			case 'Rusty':
				setColor('Color');
				setTag('Rusty');
				break;
			case 'Unset':
				setColor('Discolor');
				setTag('Unset');
				break;
			case 'Graffiti':
				setColor('Color');
				setTag('Graffiti');
				break;
			default:
				break;
		}
	};

	const onChangeLight = (light) => setLight(light);

	const onChangeTagMode = (tagMode) => setTagMode(tagMode);

	const onChangeNote = (note) => setNote(note);

	const getLocation = (location) => {
		location && setLocation(location);
		setVisible(!visible);
	};

	return (
		<>
			<Title level={3} title={t('CreateItem.Title')} />
			<Spin spinning={loading}>
				<Row>
					<Col span={12} offset={0}>
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col span={12}>
								<div className="title">{t('Longitude')}:</div>
								<Input
									size="large"
									value={location?.coordinates[0]}
									addonAfter={<AimOutlined onClick={() => setVisible(!visible)} />}
								/>
							</Col>
							<Col span={12}>
								<div className="title">{t('Latitude')}:</div>
								<Input
									size="large"
									value={location?.coordinates[1]}
									addonAfter={<AimOutlined onClick={() => setVisible(!visible)} />}
								/>
							</Col>
						</Row>

						<div className="title">{t('Status')}:</div>
						<Input size="large" value={isOkStatus(color, light) ? t('Normal') : t('No Normal')} readOnly={true} />

						<div className="title">{t('Type')}:</div>
						<Select
							value={type}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeType(e)}
						>
							{types.map((type) => (
								<Select.Option value={type.value} key={type.value}>{type.label}</Select.Option>
							))}
						</Select>

						<div className="title">検査禁止フラグ:</div>
						<Select
							value={false}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							disabled={true}
						>
							{forbiddens.map((forbidden) => (
								<Select.Option value={forbidden.value} key={forbidden.value}>{forbidden.label}</Select.Option>
							))}
						</Select>

						<div className="title">認識結果不採用フラグ:</div>
						<Select
							value={false}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							disabled={true}
						>
							{rejects.map((reject) => (
								<Select.Option value={reject.value} key={reject.value}>{reject.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Light')}:</div>
						<Select
							value={light}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeLight(e)}
						>
							{lights.map((light) => (
								<Select.Option value={light.value} key={light.value}>{light.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Color')}:</div>
						<Select
							value={color}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeColor(e)}
						>
							{colors.map((color) => (
								<Select.Option value={color.value} key={color.value}>{color.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('TagMode')}:</div>
						<Select
							value={tagMode}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeTagMode(e)}
						>
							{tagModes.map((mode) => (
								<Select.Option value={mode.value} key={mode.value}>{mode.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('Tag')}:</div>
						<Select
							value={tag}
							className="ant-select ant-select-lg ant-select-single ant-select-show-arrow"
							style={{ width: '100%' }}
							onChange={(e) => onChangeTag(e)}
						>
							{tags.map((tag) => (
								<Select.Option value={tag.value} key={tag.value}>{tag.label}</Select.Option>
							))}
						</Select>

						<div className="title">{t('AiVersion')}:</div>
						<Input size="large" value={version} disabled />

						<div className="title">{t('DeviceId')}:</div>
						<GroupSelection style={{ width: '100%' }} size="large" type={'device'} value={deviceId} disabled />

						<div className="title">{t('CheckedAt')}:</div>
						<Input size="large" value={moment(checkedAt).format('LLLL')} disabled />

						<div className="title">{t('Note')}:</div>
						<Input.TextArea
							className="ant-input ant-input-lg"
							rows={6}
							value={note}
							onChange={(e) => onChangeNote(e.target.value)}
						/>
					</Col>

					<Col span={11} offset={1}>
						<div style={{ marginTop: '24px' }}>{t('Image')}:</div>
						{image ? (
							<Image src={REACT_APP_API_ENDPOINT + image} style={{ width: '100% !important' }} />
						) : (
							<Empty description={false} />
						)}
					</Col>
				</Row>

				<MapModule
					visible={visible}
					setVisible={setVisible}
					center={location}
					getLocation={getLocation}
					mode={'location'}
				/>

				<Row type="flex" justify="center" align="middle" style={{ marginTop: '40px' }}>
					<Col span={24}>
						<Row justify="center">
							<Button
								className="button-optional"
								type="danger"
								size="large"
								style={{ marginRight: '5px' }}
								onClick={() => back()}
							>
								{t('CreateItem.Cancel')}
							</Button>
							<Button
								loading={loading}
								className="button-optional"
								type="primary"
								size="large"
								onClick={() => createItem()}
							>
								{t('CreateItem.Create')}
							</Button>
						</Row>
					</Col>
				</Row>
			</Spin>
		</>
	);
};

export default CreateItem;
