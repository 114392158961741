import ChangePasswordScreen from 'screens/ChangePasswordScreen';
import EditDetailItemScreen from 'screens/DetailItemScreen/EditItemScreen';
import HistoryScreen from 'screens/HistoryItemScreen';
import ManageAccountsScreen from 'screens/ManageAccountsScreen';
import PendingAccountsScreen from 'screens/ManageAccountsScreen/PendingAccountsScreen';
import ManageCompaniesScreen from 'screens/ManageCompaniesScreen';
import ManageDevicesScreen from 'screens/ManageDevicesScreen';
import ManageItemsScreen from 'screens/ManageItemsScreen';
import ManageCarsScreen from 'screens/MangeCarsScreen';
import MonitorScreen from 'screens/MonitorScreen';
import MyAccountScreen from 'screens/MyAccountScreen';
import RegisterDeviceScreen from 'screens/RegisterDeviceScreen';
import RegisterDeviceConfirmScreen from 'screens/RegisterDeviceScreen/ConfirmScreen';
import SelectDeviceScreen from 'screens/SelectDeviceScreen';
import SetupDeviceScreen from 'screens/SetupDeviceScreen';
import ViewDetailItemScreen from 'screens/DetailItemScreen/ViewItemScreen';
import ViewInMapScreen from 'screens/MapViewScreen';
import CreateItemMapScreen from 'screens/MapViewScreen/CreateItem';
import EditItemMapScreen from 'screens/MapViewScreen/EditItem';
import { roles } from '../constants/roles';

export const routes = [
	{
		component: ManageAccountsScreen,
		path: '/manage-accounts',
		permission: [roles.ADMIN],
	},
	{
		component: ManageItemsScreen,
		path: '/manage-items',
		permission: [roles.ADMIN],
	},
	{
		component: ViewDetailItemScreen,
		path: '/detail-item',
		permission: [roles.ADMIN],
	},
	{
		component: MyAccountScreen,
		path: '/my-account',
		permission: [roles.ADMIN, roles.OPERATOR],
	},
	{
		component: MonitorScreen,
		path: '/monitor',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: ChangePasswordScreen,
		path: '/change-password',
		permission: [roles.ADMIN, roles.OPERATOR],
	},
	{
		component: SelectDeviceScreen,
		path: '/select-device',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: RegisterDeviceScreen,
		path: '/register-device',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: SetupDeviceScreen,
		path: '/setup-device',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: EditDetailItemScreen,
		path: '/edit-detail-item',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: HistoryScreen,
		path: '/history',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: ManageCompaniesScreen,
		path: '/manage-companies',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: ManageCarsScreen,
		path: '/manage-cars',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: ViewInMapScreen,
		path: '/view-item-in-map',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: EditItemMapScreen,
		path: '/edit-item-map',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: CreateItemMapScreen,
		path: '/create-item-map',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: ManageDevicesScreen,
		path: '/manage-devices',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: RegisterDeviceConfirmScreen,
		path: '/register-device-confirm',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
	{
		component: PendingAccountsScreen,
		path: '/pending-accounts',
		permission: [roles.OPERATOR, roles.ADMIN],
	},
];
