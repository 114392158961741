import { Button, Modal, Space } from 'antd';
import { getItemsByBoundsAPI, getItemsNearCenterAPI } from 'apis/item';
import { MAP_KEY } from 'configs/map';
import { useCallback, useContext, useEffect, useState } from 'react';
import MapContext, { MapProvider } from 'utils/mapContext';

import Map from './map';

const Module = (props) => {
	const [item, setItem] = useState(null);
	const [items, setItems] = useState([]);
	const [location, setLocation] = useState(null);

	const mapContext = useContext(MapContext);

	const getItemsByBounds = useCallback(() => {
		const hasBound =
			mapContext.configMap?.center &&
			mapContext.configMap?.neCorner &&
			mapContext.configMap?.swCorner &&
			mapContext.configMap?.zoom >= 15;

		if (hasBound)
			getItemsByBoundsAPI(mapContext.configMap.neCorner, mapContext.configMap.swCorner).then((items) =>
				setItems(items)
			);
	}, [mapContext]);

	useEffect(() => {
		getItemsByBounds();
	}, [getItemsByBounds]);

	useEffect(() => {
		if (props.center) {
			let config = mapContext.configMap;

			config.center = {
				lat: parseFloat(props.center.coordinates[1]),
				lng: parseFloat(props.center.coordinates[0]),
			};

			mapContext.setConfigMap(config);

			getItemsNearCenterAPI(config.center).then((items) => {
				setItems(items);
			});
		}
	}, [props.center]);

	useEffect(() => {
		setItem(props.item);
	}, props.item);

	const onChangeItem = (item) => props.mode === 'item' && setItem(item);

	const onChangeLocation = (coordinates) => {
		props.mode === 'location' &&
			setLocation({
				type: 'Point',
				coordinates: [coordinates.latLng.lng(), coordinates.latLng.lat()],
			});
	};

	const ok = () => {
		props.getLocation(location, item);
		setLocation(null);
	};

	const close = () => {
		props.setVisible();
		setLocation(null);
		setItem(props.item);
	};

	return (
		<>
			<Modal
				width={1500}
				visible={props.visible}
				closable={false}
				onCancel={() => props.setVisible()}
				footer={
					<Space>
						<Button shape="round" type="danger" onClick={() => close()}>
							いいえ
						</Button>

						<Button shape="round" type="primary" onClick={() => ok()}>
							はい
						</Button>
					</Space>
				}
			>
				<Map
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}`}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `80vh` }} />}
					mapElement={<div style={{ height: `100%` }} />}
					items={items}
					item={item}
					onChangeItem={onChangeItem}
					location={location}
					onChangeLocation={onChangeLocation}
				/>
			</Modal>
		</>
	);
};

const MapModule = ({ visible, setVisible, center, getLocation, mode, item }) => {
	return (
		<MapProvider>
			<Module
				visible={visible}
				setVisible={setVisible}
				center={center}
				getLocation={getLocation}
				mode={mode}
				item={item}
			/>
		</MapProvider>
	);
};

export default MapModule;
