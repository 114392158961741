/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Layout } from 'antd';
import './index.css';
import 'antd/dist/antd.css';
import { routes } from 'configs/routes';
import { Redirect } from 'react-router-dom';
import SideBar from 'components/SideBar';
import { getAllowedRoutes, getRole } from 'utils/role';
import { Header } from 'antd/lib/layout/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from 'components/NotFound';

function MapAllowedRoutes({ routes, basePath, isAddNotFound }) {
	const match = useRouteMatch(basePath);
	return (
		<Switch>
			{routes.map((route) => {
				const { path, component: Component, children, ...rest } = route;

				return (
					<Route {...rest} key={path} path={`${match.path}${path}`}>
						<Component children={children} />
					</Route>
				);
			})}
			{isAddNotFound && (
				<Route>
					<NotFound />
				</Route>
			)}
		</Switch>
	);
}

function PrivateRoutes() {
	const [collapsed, setCollapsed] = useState(false);
	const role = getRole();

	let allowedRoutes = [];
	if (role) allowedRoutes = getAllowedRoutes(routes);
	else return <Redirect to="/" />;

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	return (
		<Layout className="layout-app">
			<SideBar collapsed={collapsed} role={role} />
			<Layout className="site-layout">
				<Header className="site-layout-background header-layout">
					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: toggle,
					})}
				</Header>
				<Layout.Content className="site-layout-background optional">
					<MapAllowedRoutes routes={allowedRoutes} basePath="/app" isAddNotFound={true} />
				</Layout.Content>
			</Layout>
		</Layout>
	);
}

export default PrivateRoutes;
